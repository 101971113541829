import React, { useEffect } from "react";

import { useSelector } from "react-redux";

import GenericCard from "components/UI/GenericCard/GenericCard";
import TVChartContainer from "components/TVChartContainer/TVChartContainer";
import OnlineUsers from "./OnlineUsers";
import NasdaqStatus from "./NasdaqStatus";
import SymbolCheckup from "./SymbolCheckup";

const Home = () => {
  const fullName = useSelector(
    (state) => `${state.main.user.firstName} ${state.main.user.lastName}`
  );

  useEffect(() => {
    document.title = "Home | Dindicator Dashboard";
  }, []);

  return (
    <>
      <div className="cards-wrapper">
        <GenericCard>
          <h1 className="text-2xl">$ Welcome, rich to be, {fullName} $</h1>
          <SymbolCheckup />
        </GenericCard>
        <NasdaqStatus />
        <OnlineUsers />
      </div>
      <div className="cards-wrapper">
        <GenericCard title="Trading View" className="table-card">
          <TVChartContainer />
        </GenericCard>
      </div>
    </>
  );
};

export default Home;
