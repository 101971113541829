import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { paths } from "utils/constants";
import { Card, Input, Button, Form } from "antd";
import TrendLogo from "assets/trending.png";
import { apiGetUserInvitationByToken } from "api/apiUser";
import { onCompleteRegister } from "store/asyncThunk";

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.main.user);
  const [errorMessage, setErrorMessage] = useState("");

  const [form] = Form.useForm();

  const getToken = () => {
    // Get the registration token from the URL
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get("token");
  };

  const onRegister = async (values) => {
    try {
      const token = getToken();
      await dispatch(
        onCompleteRegister({
          ...values,
          registrationToken: token,
        })
      ).unwrap();
      navigate(paths.home);
    } catch (err) {}
  };

  const redirectToLogin = () => {
    setErrorMessage("Invalid invitation token!");
    setTimeout(() => {
      navigate(paths.home);
    }, 3000);
  };

  useEffect(() => {
    const token = getToken();

    // Send a request to the server to get user details
    const fetchUserDetails = async () => {
      try {
        const response = await apiGetUserInvitationByToken(token);
        form.setFieldsValue({
          email: response.email,
        });
        // Do something with the user details
      } catch (error) {
        redirectToLogin();
      }
    };

    if (token) {
      fetchUserDetails();
    } else {
      redirectToLogin();
    }
  }, []);

  useEffect(() => {
    document.title = "Register | Dindicator Dashboard";
  }, []);

  if (user) {
    return <Navigate to={paths.home} replace />;
  }

  if (errorMessage) {
    return (
      <div className="flex items-center justify-center h-full">
        <Card className="rounded-lg p-4 max-w-[500px] w-full">
          <h2 className="text-3xl font-bold mb-4 text-center">
            {errorMessage}
          </h2>
          <p className="text-center mb-4">
            Redirecting to the login page in a few seconds...
          </p>
        </Card>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center h-full">
      <Card className="rounded-lg p-4 max-w-[500px] w-full">
        <div className="flex flex-col items-center">
          <div className="flex items-center pe-5 pb-5">
            <img src={TrendLogo} alt="" className="h-10" />
            <h1 className="font-[Kalam] text-3xl pt-2">Dindicator</h1>
          </div>
          <h2 className="text-3xl font-bold mb-4 text-center">
            Welcome to Dindicator!
          </h2>
          <p className="text-center mb-4">
            Register to start using our platform.
          </p>
        </div>

        <Form
          form={form}
          layout="vertical"
          name="register"
          onFinish={onRegister}
        >
          <Form.Item
            name="firstName"
            label="First Name"
            // noStyle
            required={true}
            rules={[
              {
                required: true,
                message: "Enter first name",
              },
            ]}
          >
            <Input type="text" placeholder="First Name" />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Last Name"
            // noStyle
            required={true}
            rules={[
              {
                required: true,
                message: "Enter last name",
              },
            ]}
          >
            <Input type="text" placeholder="Last Name" />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email Address"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input type="email" placeholder="Email Address" disabled />
          </Form.Item>

          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
              {
                min: 6,
                message: "Password must be at least 6 characters long!",
              },
              {
                pattern: /^(?=.*[A-Z]).+$/,
                message: "Password must contain at least 1 capital letter!",
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="confirm"
            label="Confirm Password"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The new password that you entered do not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item shouldUpdate>
            {() => (
              <Button
                type="primary"
                htmlType="submit"
                className="w-full"
                disabled={
                  !form.isFieldsTouched(true) ||
                  !!form.getFieldsError().filter(({ errors }) => errors.length)
                    .length
                }
              >
                Register
              </Button>
            )}
          </Form.Item>
        </Form>
        <p className="mt-4 text-center">
          Already have an account? <Link to={paths.login}>Login</Link>
        </p>
      </Card>
    </div>
  );
};

export default Register;
