import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";
import GenericCard from "components/UI/GenericCard/GenericCard";
import { Line } from "@ant-design/plots";
import dayjs from "dayjs";
import PredictionsChart from "./Charts/PredictionsChart";

const ModelPerformance = (props) => {
  const { predictions } = props;
  const [performance, setPerformance] = useState({
    tp: [],
    tpMean: 0,
    tn: [],
    tnMean: 0,
  });

  useEffect(() => {
    let totalBuy = 0;
    let totalBuySuccess = 0;
    let tpResults = [];
    let totalNoBuy = 0;
    let totalNoBuySuccess = 0;
    let tnResults = [];
    predictions.forEach((currentPredict) => {
      if (currentPredict.buy_sell !== "not enough data") {
        if (currentPredict.prediction === "buy") {
          totalBuy++;
          if (currentPredict.prediction === currentPredict.buy_sell) {
            totalBuySuccess++;
          }
          tpResults.push({
            date: dayjs(currentPredict.date).format("DD/MM/YY"),
            value: +((totalBuySuccess / totalBuy) * 100).toFixed(2),
          });
        } else if (currentPredict.prediction === "no buy") {
          totalNoBuy++;
          if (currentPredict.prediction === currentPredict.buy_sell) {
            totalNoBuySuccess++;
          }
          tnResults.push({
            date: dayjs(currentPredict.date).format("DD/MM/YY"),
            value: +((totalNoBuySuccess / totalNoBuy) * 100).toFixed(2),
          });
        }
      }
    });
    const tpMean = d3.mean(tpResults, (d) => d.value) || 0;
    const tnMean = d3.mean(tnResults, (d) => d.value) || 0;

    setPerformance({ tp: tpResults, tn: tnResults, tpMean, tnMean });
  }, [predictions]);

  const tpConfig = {
    data: performance.tp,
    padding: "auto",
    xField: "date",
    yField: "value",
    xAxis: {
      tickCount: 5,
    },
    annotations: [
      {
        type: "regionFilter",
        start: ["min", "mean"],
        end: ["max", "0"],
        color: "#F4664A",
      },
      {
        type: "text",
        position: ["min", "mean"],
        content: `Average: ${performance.tpMean.toFixed(2)}%`,
        offsetY: -6,
        style: {
          textBaseline: "bottom",
          fill: "#fff",
        },
        background: {
          padding: 4,
          style: {
            radius: 4,
            fill: "rgba(0,0,0,0.6)",
          },
        },
      },
      {
        type: "line",
        start: ["min", "mean"],
        end: ["max", "mean"],
        style: {
          stroke: "red",
          lineDash: [2, 2],
        },
      },
    ],
  };

  const tnConfig = {
    data: performance.tn,
    padding: "auto",
    xField: "date",
    yField: "value",
    xAxis: {
      tickCount: 5,
    },
    annotations: [
      {
        type: "text",
        position: ["min", "mean"],
        content: `Average: ${performance.tnMean.toFixed(2)}%`,
        offsetY: -6,
        style: {
          textBaseline: "bottom",
          fill: "#fff",
        },
        background: {
          padding: 4,
          style: {
            radius: 4,
            fill: "rgba(0,0,0,0.6)",
          },
        },
      },
      {
        type: "regionFilter",
        start: ["min", "mean"],
        end: ["max", "0"],
        color: "#F4664A",
      },
      {
        type: "line",
        start: ["min", "mean"],
        end: ["max", "mean"],
        style: {
          stroke: "red",
          lineDash: [2, 2],
        },
      },
    ],
  };

  return (
    <div className="cards-wrapper">
      <GenericCard
        title={`T/P over time, AVG: ${performance.tpMean.toFixed(2)}%`}
        maxHeight={650}
      >
        <Line {...tpConfig} height={500} autoFit={false} />
      </GenericCard>
      <GenericCard
        title={`T/N over time, AVG: ${performance.tnMean.toFixed(2)}%`}
        maxHeight={650}
      >
        <Line {...tnConfig} height={500} autoFit={false} />
      </GenericCard>
      <PredictionsChart predictions={predictions} />
    </div>
  );
};

ModelPerformance.propTypes = { predictions: PropTypes.array };

export default ModelPerformance;
