import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Divider, Empty } from "antd";
import NewNoteForm from "./NewNoteForm";
import StrategyNoteCard from "./StrategyNoteCard";
import { apiCreateNewStrategyNote } from "api/apiStrategies";

const StrategyNotes = (props) => {
  const { strategy, getUserStrategies } = props;
  const [isNewNoteFormShown, setIsNewNoteFormShown] = useState(false);

  const onToggleShowNewNoteForm = () => {
    setIsNewNoteFormShown((prevState) => !prevState);
  };

  const createNewStrategyNote = async (values) => {
    try {
      await apiCreateNewStrategyNote(strategy._id, values);
      await getUserStrategies();
      onToggleShowNewNoteForm();
    } catch (err) {}
  };

  return (
    <div style={{ minHeight: 400 }}>
      <Divider orientation="left">Strategy Notes</Divider>
      {strategy.notes.length > 0 && (
        <Button
          type={isNewNoteFormShown ? "default" : "primary"}
          onClick={onToggleShowNewNoteForm}
        >
          {isNewNoteFormShown ? "Cancel" : "New Note"}
        </Button>
      )}
      <div className="notes-wrapper">
        {isNewNoteFormShown && (
          <NewNoteForm
            createNewStrategyNote={createNewStrategyNote}
            onToggleShowNewNoteForm={onToggleShowNewNoteForm}
          />
        )}
        {strategy.notes.map((note) => (
          <StrategyNoteCard
            key={note._id}
            note={note}
            strategyId={strategy._id}
            getUserStrategies={getUserStrategies}
          />
        ))}
      </div>

      {strategy.notes.length === 0 && !isNewNoteFormShown && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={<span>No notes yet.</span>}
        >
          <Button type="primary" onClick={onToggleShowNewNoteForm}>
            Create Now
          </Button>
        </Empty>
      )}
    </div>
  );
};

StrategyNotes.propTypes = {
  strategy: PropTypes.object,
  getUserStrategies: PropTypes.func,
};

export default StrategyNotes;
