import { configureStore, combineReducers } from "@reduxjs/toolkit";
import main from "store/slices/main";

const combinedReducer = combineReducers({
  main,
});

const rootReducer = (state, action) => {
  if (action.type === "main/onUserLogout/fulfilled") {
    state = undefined;
  }

  return combinedReducer(state, action);
};

export default configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
