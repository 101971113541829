import React from "react";
import PropTypes from "prop-types";
import GenericCard from "../UI/GenericCard/GenericCard";

const WinningMonthsCard = (props) => {
  const { backtestResults } = props;

  return (
    <GenericCard title="Winning months">
      <h2>{backtestResults.metrics.winningMonths}</h2>
      <h5>out of {backtestResults.metrics.totalMonths} months</h5>
      <div className="flex pt-2">
        <div className="pe-10">
          <h5>Success rate</h5>
          <h2>{backtestResults.metrics.monthlySuccessRate}%</h2>
        </div>
        <div>
          <h5>Win-Loss Ratio</h5>
          <h2>{backtestResults.metrics.winLossRatio}</h2>
        </div>
      </div>
    </GenericCard>
  );
};
WinningMonthsCard.propTypes = {
  backtestResults: PropTypes.object,
};

export default WinningMonthsCard;
