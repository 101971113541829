import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { apiGetUserStrategies } from "api/apiStrategies";
import { apiGetStrategyTabs } from "api/apiStrategyTabs";
import { useSelector } from "react-redux";

import StrategiesTable from "./StrategiesTable";
import StrategyTabsBar from "./StrategyTabsBar";
import StrategiesOverview from "./StrategiesOverview";
import LoadedStrategy from "./LoadedStrategy";
import { Button, Tabs } from "antd";

const defaultTab = {
  label: `All Strategies`,
  key: "all",
};

const Strategies = () => {
  const accountType = useSelector((state) => state.main.accountType);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const strategyFromUrl = queryParams.get("strategyId");

  const [strategyTabs, setStrategyTabs] = useState([defaultTab]);
  const [selectedStrategyTab, setSelectedStrategyTab] = useState("all");
  const [strategies, setStrategies] = useState([]);
  const [showEnabledStrategies, setShowEnabledStrategies] = useState(true);
  const [loadedStrategies, setLoadedStrategies] = useState([]);
  const [loadedStrategySelectedTab, setLoadedStrategySelectedTab] =
    useState("");

  const selectedIds = useMemo(
    () =>
      loadedStrategies.reduce((acc, item) => {
        acc[item._id] = 1;
        return acc;
      }, {}),
    [loadedStrategies]
  );

  const getUserStrategyTabs = async (selectTab) => {
    try {
      const response = await apiGetStrategyTabs();
      const tabs = [defaultTab];
      response.forEach((item) => {
        tabs.push({
          label: item.name,
          key: item._id,
          description: item.description,
          children: (
            <p
              style={{
                whiteSpace: "pre-wrap",
                wordBreak: "break-word",
                marginBlockEnd: 10,
              }}
              dangerouslySetInnerHTML={{ __html: item.description }}
            />
          ),
        });
      });
      setStrategyTabs(tabs);
      if (selectTab) {
        setSelectedStrategyTab(selectTab);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getUserStrategies = async () => {
    const formData = {
      enabled: showEnabledStrategies,
      accountType,
    };

    if (selectedStrategyTab !== "all") {
      formData.strategyTabs = selectedStrategyTab;
    }

    const response = await apiGetUserStrategies(formData);
    setStrategies(response);

    if (loadedStrategies) {
      const updatedStrategies = [];
      response.forEach((item) => {
        if (selectedIds[item._id] || item._id === strategyFromUrl) {
          updatedStrategies.push(item);
        }
        if (item._id === strategyFromUrl) {
          setLoadedStrategySelectedTab(item._id);
        }
      });
      setLoadedStrategies(updatedStrategies);
    }
  };

  const onLoadStrategy = (strategy) => {
    setLoadedStrategies((prevState) => {
      const isLoaded = prevState.find((item) => item._id === strategy._id);
      if (isLoaded) {
        return prevState;
      }
      return [strategy, ...prevState];
    });
    setLoadedStrategySelectedTab(strategy._id);
  };

  const handleChangeTab = (resultId) => {
    setLoadedStrategySelectedTab(resultId);
  };

  const onUnloadStrategy = (strategyId) => {
    setLoadedStrategies((prevState) => {
      const updatedState = prevState.filter((item) => item._id !== strategyId);
      if (strategyId === loadedStrategySelectedTab) {
        setLoadedStrategySelectedTab(updatedState[0]?._id);
      }

      return updatedState;
    });
  };

  const clearLoadedStrategies = () => {
    setLoadedStrategies([]);
  };

  const onEdit = (targetKey, action) => {
    if (action === "add") {
      // add();
    } else {
      onUnloadStrategy(targetKey);
    }
  };

  const items = useMemo(() => {
    return loadedStrategies.map((item) => {
      return {
        key: item._id,
        label: `${item.symbol} - ${item.name}`,
        children: (
          <LoadedStrategy
            selectedStrategy={item}
            getUserStrategies={getUserStrategies}
            strategyTabs={strategyTabs}
          />
        ),
      };
    });
  }, [loadedStrategies]);

  useEffect(() => {
    getUserStrategies();
  }, [showEnabledStrategies, accountType, selectedStrategyTab]);

  useEffect(() => {
    getUserStrategyTabs();

    document.title = "Strategies | Dindicator Dashboard";
  }, []);

  return (
    <>
      {!strategyFromUrl && (
        <>
          <StrategyTabsBar
            strategyTabs={strategyTabs}
            getUserStrategyTabs={getUserStrategyTabs}
            selectedStrategyTab={selectedStrategyTab}
            setSelectedStrategyTab={setSelectedStrategyTab}
            showEnabledStrategies={showEnabledStrategies}
            setShowEnabledStrategies={setShowEnabledStrategies}
          />

          <StrategiesOverview strategies={strategies} />

          <StrategiesTable
            strategies={strategies}
            onLoadStrategy={onLoadStrategy}
            onUnloadStrategy={onUnloadStrategy}
            selectedIds={selectedIds}
          />
        </>
      )}

      {loadedStrategies.length > 0 && (
        <div style={{ minHeight: 800 }}>
          <Tabs
            type="editable-card"
            items={items}
            onEdit={!strategyFromUrl && onEdit}
            tabBarExtraContent={{
              right: !strategyFromUrl && (
                <div>
                  <Button type="primary" onClick={clearLoadedStrategies}>
                    Clear
                  </Button>
                </div>
              ),
            }}
            hideAdd
            activeKey={loadedStrategySelectedTab}
            onChange={handleChangeTab}
          />
        </div>
      )}
    </>
  );
};

export default Strategies;
