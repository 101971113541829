import React, { useState } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { calcProfitPerc, returnUpDownClassname } from "utils/helpers";
import { useSelector } from "react-redux";
import { Button, DatePicker, Form, Input, InputNumber, Modal } from "antd";
import { apiConfirmStrategyTrade } from "api/apiStrategies";

const ConfirmPosition = (props) => {
  const { pendingDeal, getUserStrategies } = props;
  const [form] = Form.useForm();

  const [isOpen, setIsOpen] = useState(false);
  const userPremiumFee = useSelector(
    (state) => state.main.user.preferences.premiumFee
  );

  const buyPrice = Form.useWatch("openRate", form);
  const percChange = calcProfitPerc(pendingDeal.advicedBuyPrice, buyPrice) || 0;

  const handleConfirmPosition = async (values) => {
    try {
      const data = {
        dealId: pendingDeal._id,
        openRate: values.openRate,
        amount: values.amount,
        openValue: values.openRate * values.amount,
        premiumFee: values.premiumFee,
        openDate: values.openDate,
      };

      await apiConfirmStrategyTrade(data);
      getUserStrategies();
    } catch (err) {}
  };

  const updatePositionValue = (values, allValues) => {
    const value = allValues.openRate * allValues.amount;
    form.setFieldValue("openValue", +value?.toFixed(2));
  };

  return (
    <>
      <Button type="primary" onClick={() => setIsOpen(true)}>
        Confirm
      </Button>
      <Modal
        title="Confirm Position"
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={null}
        width={800}
        destroyOnClose={true}
      >
        <Form
          form={form}
          layout="vertical"
          name="confirm_position"
          onFinish={handleConfirmPosition}
          onValuesChange={updatePositionValue}
          initialValues={{
            openRate: pendingDeal.advicedBuyPrice,
            amount: pendingDeal.advicedBuyAmount,
            openValue:
              pendingDeal.advicedBuyPrice * pendingDeal.advicedBuyAmount,
            premiumFee: userPremiumFee || 2.5,
            openDate: dayjs(),
          }}
          preserve={false}
        >
          <Form.Item
            name="openRate"
            label="Price per share"
            rules={[
              {
                required: true,
                message: "Price is required!",
              },
            ]}
            required={false}
          >
            <InputNumber step={0.01} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="amount"
            label="Amount"
            rules={[
              {
                required: true,
                message: "Amount is required!",
              },
            ]}
            required={false}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            name="openValue"
            label="Value"
            rules={[
              {
                required: true,
              },
            ]}
            required={false}
          >
            <Input prefix="$" disabled style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            name="premiumFee"
            label="Commission"
            rules={[
              {
                required: true,
              },
            ]}
            required={false}
          >
            <InputNumber prefix="$" style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            name="openDate"
            label="Open date"
            rules={[
              {
                required: true,
              },
            ]}
            required={false}
          >
            <DatePicker showTime style={{ width: "100%" }} showSecond={false} />
          </Form.Item>
          <div>Recommended buy price: ${pendingDeal.advicedBuyPrice}</div>
          <div>
            Price change from recommendation:{" "}
            <span className={returnUpDownClassname(percChange)}>
              {percChange}%
            </span>
          </div>

          <Form.Item shouldUpdate style={{ paddingBlockStart: 10 }}>
            {() => (
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

ConfirmPosition.propTypes = {
  pendingDeal: PropTypes.object,
  getUserStrategies: PropTypes.func,
};

export default ConfirmPosition;
