import React from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown, Segmented } from "antd";
import { paths } from "utils/constants";

import { useDispatch, useSelector } from "react-redux";
import { onUserLogout } from "store/asyncThunk";
import { toggleDrawer, toggleRealDemo } from "store/slices/main";

import ProfileAvatar from "components/UI/ProfileAvatar";
import {
  DownOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";

import "./Header.scss";

const items = [
  {
    label: "Settings",
    key: "settings",
  },
  {
    label: "Logout",
    key: "0",
  },
];

const Header = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.main.user);
  const accountType = useSelector((state) => state.main.accountType);
  const isDrawerOpen = useSelector((state) => state.main.isDrawerOpen);
  const navigate = useNavigate();

  const onLogout = () => {
    dispatch(onUserLogout());
  };

  const handleOnClick = (e) => {
    if (e.key === "0") {
      onLogout();
    } else if (e.key === "settings") {
      navigate(paths.userSettings);
    }
  };

  const onToggleDrawer = () => {
    dispatch(toggleDrawer());
  };

  const onToggleRealDemo = () => {
    dispatch(toggleRealDemo());
  };

  return (
    <div className="app-header">
      <div className="header">
        <div className="header-left">
          <div onClick={onToggleDrawer}>
            {isDrawerOpen ? (
              <MenuFoldOutlined className="menu-button" />
            ) : (
              <MenuUnfoldOutlined className="menu-button" />
            )}
          </div>
          <Segmented
            className="real-demo-switch"
            options={[
              { label: "Demo", value: "demo" },
              { label: "Live", value: "real" },
            ]}
            value={accountType}
            onChange={onToggleRealDemo}
          />
        </div>
        <Dropdown
          menu={{
            items,
            onClick: handleOnClick,
          }}
          trigger={["click"]}
        >
          <div className="user-profile-name">
            <ProfileAvatar user={user} showTooltip={false} />
            <span className="user-fullname">
              <span style={{ color: "#666c75" }}>Hello, </span>
              {user.firstName}
            </span>
            <DownOutlined className="down-arrow" />
          </div>
        </Dropdown>
      </div>
      {/* <Navbar /> */}
    </div>
  );
};

export default Header;
