import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "antd";
import HoverableTooltip from "components/UI/Tooltip/HoverableTooltip";
import moment from "moment";

const ModelDetails = (props) => {
  const { model } = props;
  const [open, setOpen] = useState(false);
  return (
    <>
      <HoverableTooltip text={model.name} onClick={() => setOpen(true)} />
      <Modal
        title={model.name}
        open={open}
        footer={null}
        width={800}
        destroyOnClose={true}
        onCancel={() => setOpen(false)}
      >
        <h3>Trained on</h3>
        <p>{model?.leads?.map((stock) => `${stock}, `)}</p>
        <br />
        <h3>Trained data</h3>
        <p>
          {moment(model.results?.start_date).format("DD/MM/YY")}
          {" - "}
          {moment(model.results?.end_date).format("DD/MM/YY")}
        </p>
        <br />
        <h3>Profit target:</h3>
        <p style={{ color: "#ffffff" }}>
          {((model.results?.perc_to_gain - 1) * 100).toFixed()}% in{" "}
          {model.results?.days} days
        </p>
        <br />
        <h3>Buy % from total</h3>
        <p>{(model?.results?.buy_from_total * 100).toFixed(2)}%</p>
        <br />
        <p>
          {model?.creator
            ? `Created by ${model.creator.firstName} ${model?.creator?.lastName}, `
            : ""}
          {moment(model.date).format("HH:mm DD/MM/YY")}
        </p>
      </Modal>
    </>
  );
};

ModelDetails.propTypes = {
  model: PropTypes.object.isRequired,
};

export default ModelDetails;
