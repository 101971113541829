import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Card, Form, Input } from "antd";

const NewNoteForm = (props) => {
  const { createNewStrategyNote, onToggleShowNewNoteForm } = props;
  const [form] = Form.useForm();
  const [isPending, setIsPending] = useState(false);

  const onCreateNewNote = async (values) => {
    setIsPending(true);
    try {
      await createNewStrategyNote(values);
    } catch (err) {}
    setIsPending(false);
  };

  return (
    <Form
      form={form}
      name="new_note"
      onFinish={onCreateNewNote}
      style={{ marginBlockStart: 15 }}
    >
      <Card
        title={
          <Form.Item
            name="title"
            noStyle
            required={false}
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <Input style={{ maxWidth: 400 }} placeholder="Enter title" />
          </Form.Item>
        }
        extra={<Button onClick={onToggleShowNewNoteForm}>Cancel</Button>}
      >
        <Form.Item
          name="content"
          required={false}
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        >
          <Input.TextArea
            placeholder="Enter content"
            style={{ minHeight: 200 }}
          />
        </Form.Item>
        <Form.Item shouldUpdate>
          {() => (
            <Button type="primary" htmlType="submit" loading={isPending}>
              Create
            </Button>
          )}
        </Form.Item>
      </Card>
    </Form>
  );
};

NewNoteForm.propTypes = {
  createNewStrategyNote: PropTypes.func,
  onToggleShowNewNoteForm: PropTypes.func,
};

export default NewNoteForm;
