import React from "react";
import PropTypes from "prop-types";

import GenericCard from "components/UI/GenericCard/GenericCard";

import { Line } from "@ant-design/plots";

const StrategySuccessRateChart = (props) => {
  const { backtestResults } = props;
  const data = backtestResults?.transactions
    ? backtestResults?.transactions?.slice().reverse()
    : null;

  const lastResult = data[data.length - 1]?.strategySuccessRate || 0;

  const config = {
    data,
    padding: "auto",
    xField: "closeDate",
    yField: "strategySuccessRate",
    slider: {},
    annotations: [
      {
        type: "regionFilter",
        start: ["min", "mean"],
        end: ["max", "0"],
        color: "#F4664A",
      },
      {
        type: "text",
        position: ["min", "mean"],
        offsetY: -6,
        style: {
          textBaseline: "bottom",
          fill: "#fff",
        },
        background: {
          padding: 4,
          style: {
            radius: 4,
            fill: "rgba(0,0,0,0.6)",
          },
        },
      },
      {
        type: "line",
        start: ["min", "mean"],
        end: ["max", "mean"],
        style: {
          stroke: "red",
          lineDash: [2, 2],
        },
      },
    ],
    xAxis: {
      type: "timeCat",
      tickCount: 5,
    },
  };
  return (
    <GenericCard
      title={`Strategy success rate over time (${lastResult}%)`}
      maxHeight={600}
      isCardOpenByDefault={false}
      className="table-card"
    >
      <Line {...config} height={400} autoFit={false} />
    </GenericCard>
  );
};

StrategySuccessRateChart.propTypes = { backtestResults: PropTypes.object };

export default StrategySuccessRateChart;
