import React, { useCallback } from "react";
import PropTypes from "prop-types";

import GenericCard from "components/UI/GenericCard/GenericCard";
import AntTable from "components/UI/AntTable";
import dayjs from "dayjs";
import { Button, Tooltip } from "antd";
import { formatNumber, returnUpDownClassname } from "utils/helpers";
import DashedTooltip from "components/UI/DashedTooltip";
import ExportCsvButton from "components/UI/ExportCsvButton";

const AiBacktestTable = (props) => {
  const { transactions, setTransactionVisibleRange } = props;

  const sortByNumber = useCallback((a, b) => {
    // handle null / N/A values
    if (a === b) return 0;
    if (a === null || a === "N/A") return -1;
    if (b === null || b === "N/A") return 1;
    return a > b ? 1 : -1;
  }, []);

  const sortByText = useCallback((a, b) => {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  }, []);

  const columns = [
    // {
    //   title: "#",
    //   dataIndex: "tradeNumber",
    //   width: 70,
    // },
    {
      title: "Buy Point",
      dataIndex: "advicedBuyPrice",
      render: (text, properties) => <div>{text || "N/A"}</div>,
      sorter: {
        compare: (a, b) => sortByNumber(a.advicedBuyPrice, b.advicedBuyPrice),
      },
    },
    {
      title: "Sell Point",
      dataIndex: "advicedSellPrice",
      render: (text, properties) => <div>{text || "N/A"}</div>,
      sorter: {
        compare: (a, b) => sortByNumber(a.advicedSellPrice, b.advicedSellPrice),
      },
    },
    {
      title: "Buy Price",
      dataIndex: "openRate",
      render: (text, properties) => (
        <div>
          <div>{text}</div>
          <div>
            <span style={{ fontSize: 12 }}>
              ${formatNumber(properties.openValue)}
            </span>
          </div>
        </div>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.openRate, b.openRate),
      },
    },
    {
      title: "Sell Price",
      dataIndex: "closeRate",
      render: (text, properties) => (
        <div>
          <div>{text}</div>
          <div>
            <span style={{ fontSize: 12 }}>
              ${formatNumber(properties.closeValue)}
            </span>
          </div>
        </div>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.closeRate, b.closeRate),
      },
    },
    {
      title: "Contracts",
      dataIndex: "amount",
      width: 100,
      sorter: {
        compare: (a, b) => sortByNumber(a.amount, b.amount),
      },
    },
    {
      title: "Signal",
      dataIndex: "closeReason",
      sorter: {
        compare: (a, b) => sortByText(a.closeReason, b.closeReason),
      },
    },
    {
      title: "Profit",
      dataIndex: "profit.value",
      render: (text, properties) => (
        <Tooltip
          color="black"
          title={
            <div>
              Net Profit:{" "}
              <span
                className={returnUpDownClassname(properties.netProfit.value)}
              >
                ${properties.netProfit.value.toLocaleString()}
              </span>
            </div>
          }
        >
          <span className={returnUpDownClassname(properties.profit.value)}>
            ${properties.profit.value.toLocaleString()}
          </span>
        </Tooltip>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.profit.value, b.profit.value),
      },
    },
    {
      title: "Change",
      dataIndex: "profit.perc",
      render: (text, properties) => (
        <span className={returnUpDownClassname(properties.profit.perc)}>
          {properties.profit.perc}%
        </span>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.profit.perc, b.profit.perc),
      },
    },
    {
      title: (
        <DashedTooltip text="Highest profit calculated during regular trading hours">
          Max
        </DashedTooltip>
      ),
      dataIndex: "max.perc",
      render: (text, properties) => (
        <Tooltip
          color="black"
          title={
            <div>
              <div>Rate: {properties.max.rate}</div>
              <div>Date: {dayjs(properties.max.date).format("DD/MM/YY")}</div>
            </div>
          }
        >
          <div className={returnUpDownClassname(properties.max.value)}>
            <div>${formatNumber(properties.max.value)}</div>
            <div className="text-[12px]">%{properties.max.perc}</div>
          </div>
        </Tooltip>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.max.perc, b.max.perc),
      },
    },
    {
      title: (
        <DashedTooltip text="Higest loss calculated during regular trading hours">
          Min
        </DashedTooltip>
      ),
      dataIndex: "min.perc",
      render: (text, properties) => (
        <Tooltip
          color="black"
          title={
            <div>
              <div>Rate: {properties.min.rate}</div>
              <div>Date: {dayjs(properties.min.date).format("DD/MM/YY")}</div>
            </div>
          }
        >
          <div className={returnUpDownClassname(properties.min.value)}>
            <div>${formatNumber(properties.min.value)}</div>
            <div className="text-[12px]">%{properties.min.perc}</div>
          </div>
        </Tooltip>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.min.perc, b.min.perc),
      },
    },
    {
      title: (
        <DashedTooltip text="Highest profit calculated by close rate">
          Run-up
        </DashedTooltip>
      ),
      dataIndex: "runup.perc",
      render: (text, properties) => (
        <Tooltip
          color="black"
          title={
            <div>
              <div>Rate: {properties.runup.rate}</div>
              <div>Date: {dayjs(properties.runup.date).format("DD/MM/YY")}</div>
            </div>
          }
        >
          <div className={returnUpDownClassname(properties.runup.value)}>
            <div>${formatNumber(properties.runup.value)}</div>
            <div className="text-[12px]">%{properties.runup.perc}</div>
          </div>
        </Tooltip>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.runup.perc, b.runup.perc),
      },
    },
    {
      title: (
        <DashedTooltip text="Highest loss calculated by close rate">
          Drawdown
        </DashedTooltip>
      ),
      dataIndex: "drawdown.perc",
      render: (text, properties) => (
        <Tooltip
          color="black"
          title={
            <div>
              <div>Rate: {properties.drawdown.rate}</div>
              <div>
                Date: {dayjs(properties.drawdown.date).format("DD/MM/YY")}
              </div>
            </div>
          }
        >
          <div className={returnUpDownClassname(properties.drawdown.value)}>
            <div>${formatNumber(properties.drawdown.value)}</div>
            <div className="text-[12px]">%{properties.drawdown.perc}</div>
          </div>
        </Tooltip>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.drawdown.perc, b.drawdown.perc),
      },
    },
    {
      title: "Start",
      dataIndex: "openDate",
      render: (text) => <>{dayjs(text).format("DD/MM/YY")}</>,
      sorter: {
        compare: (a, b) => sortByNumber(a.openDate, b.openDate),
      },
    },
    {
      title: "End",
      dataIndex: "closeDate",
      render: (text) => <>{dayjs(text).format("DD/MM/YY")}</>,
      sorter: {
        compare: (a, b) => sortByNumber(a.closeDate, b.closeDate),
      },
    },
    {
      title: "Days",
      dataIndex: "dealDaysOpen",
      sorter: {
        compare: (a, b) => sortByNumber(a.dealDaysOpen, b.dealDaysOpen),
      },
    },
    {
      title: "Fees",
      dataIndex: "premiumFee",
      render: (text) => <span>$-{text.toLocaleString()}</span>,

      sorter: {
        compare: (a, b) => sortByNumber(a.premiumFee, b.premiumFee),
      },
    },
    // {
    //   title: "Cum. Profit",
    //   dataIndex: "accProfit",
    //   render: (text) => (
    //     <span className={returnUpDownClassname(text)}>
    //       ${text.toLocaleString()}
    //     </span>
    //   ),

    //   sorter: {
    //     compare: (a, b) => sortByNumber(a.accProfit, b.accProfit),
    //   },
    // },
    // {
    //   title: "Balance",
    //   dataIndex: "balance",
    //   render: (text) => <span>${text.toLocaleString()}</span>,

    //   sorter: {
    //     compare: (a, b) => sortByNumber(a.balance, b.balance),
    //   },
    // },
    {
      title: "",
      key: "uid",
      dataIndex: "",
      render: (text, obj) => (
        <Button
          onClick={() =>
            setTransactionVisibleRange(obj.openDate, obj.closeDate)
          }
        >
          Chart
        </Button>
      ),
    },
  ];

  return (
    <GenericCard
      title="Closed Trades"
      className="table-card"
      isCardOpenByDefault={false}
      maxHeight={700}
    >
      <AntTable rowKey="_id" dataSource={transactions} columns={columns} />
      <div className="pt-4">
        <ExportCsvButton data={transactions} fileName="backtest-trades" />
      </div>
    </GenericCard>
  );
};
AiBacktestTable.propTypes = {
  transactions: PropTypes.array,
  setTransactionVisibleRange: PropTypes.func,
};

export default AiBacktestTable;
