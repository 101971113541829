import axios from "utils/axios";
import { routes } from "api/routes";

/**
 * Fetch user details - if null, user not logged in
 */
export const apiGetUserDetails = async () => {
  const { data } = await axios.get(routes.getUserDetails);
  return data;
};

/**
 * Login user
 */
export const apiLoginUser = async (loginData) => {
  const { data } = await axios.post(routes.login, loginData);
  return data;
};

/**
 * Logout user
 */
export const apiLogoutUser = async () => {
  const { data } = await axios.post(routes.logout);
  return data;
};

export const apiGetOnlineUsers = async (q) => {
  const { data } = await axios.get(routes.online);
  return data;
};

export const apiChangeUserDefaultPremiumFee = async (formData) => {
  const { data } = await axios.put(routes.changeDefaultPremiumFee, formData);
  return data;
};

export const apiChangeUserPassword = async (formData) => {
  const { data } = await axios.put(routes.changePassword, formData);
  return data;
};

export const apiGetOrderTrackerToken = async () => {
  const { data } = await axios.get(routes.getOrderTrackerToken);
  return data;
};

export const apiUnlinkTelegramAccount = async () => {
  const { data } = await axios.get(routes.unlinkTelegramAccount);
  return data;
};

export const apiGetUserInvitationByToken = async (token) => {
  const { data } = await axios.get(routes.getUserInvitationByToken, {
    params: { token },
  });
  return data;
};

export const apiCompleteUserRegistration = async (formData) => {
  const { data } = await axios.post(routes.completeUserRegistration, formData);
  return data;
};
