import React, { useState, useEffect } from "react";
import axios from "axios";
import GenericCard from "components/UI/GenericCard/GenericCard";
import ActiveLight from "components/UI/ActiveLight/ActiveLight";

const RenderOpen = ({ children }) => (
  <div className="flex item-center h-full">
    <ActiveLight text="Open" />
    <p className="flex items-center ps-[10px] pt-1">{children}</p>
  </div>
);

const RenderClosed = ({ children }) => (
  <span>
    <span className="red text-[1.25rem] pe-2">Closed </span>
    {children}
  </span>
);

const NasdaqStatus = () => {
  const [nasdaqStatus, setNasdaqStatus] = useState("");

  const returnIsInHoursRange = (date, startHour, endHour) => {
    const currentHour = date.getHours();
    const currentMinute = date.getMinutes();
    const currentTimeInMinutes = currentHour * 60 + currentMinute;
    return (
      currentTimeInMinutes >= startHour * 60 &&
      currentTimeInMinutes <= endHour * 60
    );
  };

  useEffect(() => {
    const checkNasdaqStatus = async () => {
      const nyseTime = new Date().toLocaleString("en-US", {
        timeZone: "America/New_York",
      });

      const now = new Date(nyseTime);
      const currentDay = now.getDay(); // 0 is Sunday, 1 is Monday, etc.

      // Fetch holiday data for the current year (United States)
      const year = now.getFullYear();
      const { data: holidays } = await axios.get(
        `https://date.nager.at/api/v3/PublicHolidays/${year}/US`
      );

      // Check if today is a holiday
      const holiday = holidays.find((holiday) => {
        const holidayDate = new Date(holiday.date);
        return (
          holidayDate.getMonth() === now.getMonth() &&
          holidayDate.getDate() === now.getDate()
        );
      });

      if (holiday) {
        setNasdaqStatus(<RenderClosed>Holiday: {holiday.name}</RenderClosed>);
        return;
      }

      const isWeekday = currentDay >= 1 && currentDay <= 5; // Monday to Friday
      if (!isWeekday) {
        setNasdaqStatus(<RenderClosed />);
        return;
      }

      const tradingHours = [
        { label: "(Pre-Market)", start: 4, end: 9.5 },
        { label: "(Regular Trading Hours)", start: 9.5, end: 16 },
        { label: "(After-hours)", start: 16, end: 20 },
      ];

      const tradingStatus = tradingHours.find(({ start, end }) =>
        returnIsInHoursRange(now, start, end)
      );

      if (tradingStatus) {
        setNasdaqStatus(<RenderOpen>{tradingStatus.label}</RenderOpen>);
      } else {
        setNasdaqStatus(<RenderClosed />);
      }
    };

    checkNasdaqStatus();

    // Refresh every minute
    const interval = setInterval(checkNasdaqStatus, 60000);

    return () => clearInterval(interval);
  }, []);

  return (
    <GenericCard title="Market Status">
      <p>{nasdaqStatus}</p>
    </GenericCard>
  );
};

export default NasdaqStatus;
