import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { copyToClipboard, createURLWithQueryParams } from "utils/helpers";
import { startBacktest } from "./BacktestStrategy";

import {
  Divider,
  Row,
  Form,
  Col,
  InputNumber,
  Select,
  App,
  Switch,
  Button,
  Popover,
} from "antd";
import {
  ExclamationCircleFilled,
  ShareAltOutlined,
  WhatsAppOutlined,
  LinkOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import {
  apiCheckForSimilarStrategy,
  apiCreateNewStrategy,
} from "api/apiStrategies";
import GenericCard from "components/UI/GenericCard/GenericCard";
import AverageProfitPerTransaction from "components/Cards/AverageProfitPerTransaction";
import MinMaxPerTransactionCard from "components/Cards/MinMaxPerTransactionCard";
import TotalPositionsClosedWithProfit from "components/Cards/TotalPositionsClosedWithProfit";
import TotalTransactions from "components/Cards/TotalTransactions";
import BuyAndHoldReturn from "components/Cards/BuyAndHoldReturn";
import TVChartContainer from "components/TVChartContainer/TVChartContainer";
import AiBacktestTable from "components/AiMagician/AiBacktestTable";
import AccProfitChart from "./Charts/AccProfitChart";
import CreateStrategy from "components/Strategies/Modals/CreateStrategy";
import SimilarStrategies from "./SimilarStrategies";
import BacktestPerformance from "./BacktestPerformance";
import StrategyOpenOrders from "components/Strategies/Cards/StrategyOpenOrders";
import StrategySuccessRateChart from "./Charts/StrategySuccessRateChart";
import StrategySuccessRateCard from "components/Cards/StrategySuccessRateCard";
import AverageHold from "components/Cards/AverageHold";
import ProfitFactor from "components/Cards/ProfitFactor";
import VarianceCard from "components/Cards/VarianceCard";
import WinningMonthsCard from "components/Cards/WinningMonthsCard";
import RangeSlider from "./RangeSlider";
import ChooseModelsForStrategy from "./ChooseModelsForStrategy";

const { Option } = Select;

const AiBacktestSection = (props) => {
  const { results, setStrategyResults, modelsList, handleSelectModel } = props;
  const { message, modal } = App.useApp();
  const location = useLocation();

  const userPremiumFee = useSelector(
    (state) => state.main.user.preferences.premiumFee
  );
  const [premiumFee, setPremiumFee] = useState(userPremiumFee);
  const [premiumPerShare, setPremiumPerShare] = useState(0);
  const [slippage, setSlippage] = useState(0);
  const [takeProfitPercent, setTakeProfitPercent] = useState(
    ((results.model.results.perc_to_gain - 1) * 100).toFixed(2)
  );
  const [stopLossPercent, setStopLossPercent] = useState(0);
  // const [trailingStopLossPercent, setTrailingStopLossPercent] = useState(0);
  const [enterPositionBuyCount, setEnterPositionBuyCount] = useState(1);
  const [exitPositionNoBuyCount, setExitPositionNoBuyCount] = useState(0);
  const [daysOpenExitCount, setDaysOpenExitCount] = useState(
    results.model.results.days
  );
  const [maxOpenOrders, setMaxOpenOrders] = useState(1);
  const [resetTrendAfterSell, setResetTrendAfterSell] = useState(true);

  const [initialBalance, setInitialBalance] = useState(1000);
  const [orderSize, setOrderSize] = useState(100);
  const [orderSizeType, setOrderSizeType] = useState("percent");
  const [visibleRange, setVisibleRange] = useState({ from: 0, to: 0 });
  const [similarStrategies, setSimilarStrategies] = useState(false);

  const [backtestResults, setBacktestResults] = useState();
  const shareUrl = useRef("");
  const queryParams = new URLSearchParams(location.search);
  const isShared = queryParams.get("shared");
  const [startDate, setStartDate] = useState(
    +new Date(results.predictionsTable[0].date)
  );
  const [endDate, setEndDate] = useState(
    +new Date(
      results.predictionsTable[results.predictionsTable.length - 1].date
    )
  );

  const handleChangeTradingRange = (dates) => {
    setStartDate(dates[0]);
    setEndDate(dates[1]);
  };

  const createShareUrl = (params, startDate, endDate) => {
    const url = createURLWithQueryParams(window.location.href, {
      shared: true,
      ...params,
      startDate: dayjs(startDate).format("YYYY-MM-DD"),
      endDate: dayjs(endDate).format("YYYY-MM-DD"),
    });
    shareUrl.current = url;
  };

  useEffect(() => {
    const backtestSettings = {
      takeProfitPercent,
      enterPositionBuyCount,
      stopLossPercent,
      // trailingStopLossPercent,
      exitPositionNoBuyCount,
      daysOpenExitCount,
      initialBalance,
      orderSize,
      orderSizeType,
      maxOpenOrders,
      resetTrendAfterSell,

      symbol: results.symbol,
      modelId: results.model._id,
      userCommission: premiumFee,
      premiumPerShare,
      slippage,
      startDate,
      endDate,
    };

    createShareUrl(
      backtestSettings,
      results.predictionsTable[0].date,
      results.predictionsTable[results.predictionsTable.length - 1].date
    );

    backtestSettings.predictionsTable = results.predictionsTable;

    const backtestResultsData = startBacktest(backtestSettings);
    setBacktestResults(backtestResultsData);
  }, [
    premiumFee,
    premiumPerShare,
    takeProfitPercent,
    enterPositionBuyCount,
    stopLossPercent,
    // trailingStopLossPercent,
    exitPositionNoBuyCount,
    daysOpenExitCount,
    initialBalance,
    orderSize,
    orderSizeType,
    results,
    maxOpenOrders,
    slippage,
    resetTrendAfterSell,
    startDate,
    endDate,
  ]);

  const resetVisibleRange = () => {
    setVisibleRange({ from: 0, to: 0 });
  };

  const setTransactionVisibleRange = (openDate, closeDate) => {
    const from = Math.floor(+new Date(openDate)) / 1000;
    const to = Math.floor(+new Date(closeDate)) / 1000;
    setVisibleRange({ from, to });
  };

  const createNewStrategy = async (force, values) => {
    const strategy = {
      symbol: results.symbol,
      model: results.model._id,

      params: {
        takeProfitPercent,
        stopLossPercent,
        enterPositionBuyCount,
        exitPositionNoBuyCount,
        daysOpenExitCount,
        orderSizeType,
        orderSize,
      },

      backtestStartDate: dayjs(startDate).format("YYYY-MM-DD"),

      initialBalance,
      balance: initialBalance,
      equity: initialBalance,
      force,
      ...values,
    };
    if (values.automated && !values.isDemo) {
      strategy.automationSettings = {
        buyMaxPercentDiff: values.buyMaxPercentDiff,
        orderUpdateInterval: values.orderUpdateInterval,
        trailingTakeProfit: {
          enabled: values.trailingTakeProfitEnabled || false,
          activationThreshold: values.trailingTakeProfitActivationThreshold,
          declineFromPeakLimit: values.trailingTakeProfitDeclineFromPeakLimit,
        },
      };
    }

    const response = await apiCreateNewStrategy(strategy);
    if (response.exists) {
      modal.confirm({
        title: "Similar strategy already exist!",
        content: (
          <div>
            <p>Strategy with the following params already exists:</p>
            <p>
              Buy predict count:{" "}
              {response.strategy.params.enterPositionBuyCount}
            </p>
            <p>
              No Buy count: {response.strategy.params.exitPositionNoBuyCount}
            </p>
            <p>Take profit: {response.strategy.params.takeProfitPercent}</p>
            <p>Stop loss: {response.strategy.params.stopLossPercent}</p>
            <p>
              Exit after days open: {response.strategy.params.daysOpenExitCount}
            </p>
          </div>
        ),
        icon: <ExclamationCircleFilled />,
        okText: "Create Anyway",
        okType: "primary",
        cancelText: "Cancel",
        onOk() {
          createNewStrategy(true, values);
        },
        onCancel() {},
      });
    } else {
      message.success("Strategy created successfully");
    }
  };

  const checkIfSimilarStrategyExists = async () => {
    const response = await apiCheckForSimilarStrategy({
      symbol: results.symbol,
      model: results.model._id,
    });
    if (response.exists) {
      setSimilarStrategies(response.existingStrategies);
    }
  };

  const onCopyBacktestLinkToClipboard = () => {
    copyToClipboard(shareUrl.current);
    message.success("Copied share URL to clipboard");
  };

  const shareToWhatsapp = () => {
    const encodedMessage = encodeURIComponent(shareUrl.current);
    window.open(
      `https://api.whatsapp.com/send?text=${encodedMessage}`,
      "_blank"
    );
  };

  useEffect(() => {
    checkIfSimilarStrategyExists();
  }, []);

  useEffect(() => {
    if (backtestResults) {
      setStrategyResults((prevState) => {
        return { ...prevState, [backtestResults._id]: backtestResults };
      });
    }

    return () => {
      if (backtestResults) {
        setStrategyResults((prevState) => {
          delete prevState[backtestResults._id];
          return prevState;
        });
      }
    };
  }, [backtestResults]);

  useEffect(() => {
    // set backtest params from share url
    if (isShared) {
      setTakeProfitPercent(+queryParams.get("takeProfitPercent"));
      setEnterPositionBuyCount(+queryParams.get("enterPositionBuyCount"));
      setStopLossPercent(+queryParams.get("stopLossPercent"));
      setExitPositionNoBuyCount(+queryParams.get("exitPositionNoBuyCount"));
      setDaysOpenExitCount(+queryParams.get("daysOpenExitCount"));
      setInitialBalance(+queryParams.get("initialBalance"));
      setOrderSize(+queryParams.get("orderSize"));
      setOrderSizeType(queryParams.get("orderSizeType"));
      setMaxOpenOrders(+queryParams.get("maxOpenOrders") || 1);
      setPremiumFee(+queryParams.get("userCommission"));

      setResetTrendAfterSell(
        queryParams.get("resetTrendAfterSell") !== "false"
      );
    }
  }, []);

  useEffect(() => {
    // set backtest params from strategy explorer
    if (results?.backtestParams) {
      const params = results.backtestParams;
      setTakeProfitPercent(params.takeProfitPercent);
      setEnterPositionBuyCount(params.enterPositionBuyCount);
      setStopLossPercent(params.stopLossPercent);
      setExitPositionNoBuyCount(params.exitPositionNoBuyCount);
      setDaysOpenExitCount(params.daysOpenExitCount);
      setInitialBalance(params.initialBalance);
      setOrderSize(params.orderSize);
      setOrderSizeType(params.orderSizeType);
      setPremiumFee(+params.userCommission);
    }
  }, [results]);

  return (
    <>
      <Form layout="vertical" name="overview">
        <GenericCard>
          {results.predictionsTable && (
            <RangeSlider
              predictionsTable={results.predictionsTable}
              handleChangeTradingRange={handleChangeTradingRange}
              startDate={startDate}
              endDate={endDate}
            />
          )}
          <Row gutter={32} align="start">
            <Col xs={12}>
              <Divider orientation="left">Strategy properties</Divider>

              <Row gutter={8}>
                <Col>
                  <Form.Item label="Inital balance">
                    <InputNumber
                      min={0}
                      prefix="$"
                      style={{ width: "100%" }}
                      value={initialBalance}
                      onChange={setInitialBalance}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    label="Order size"
                    tooltip={
                      <div>
                        <p>
                          <strong>USD:</strong> Fixed amount every order
                        </p>
                        <p>
                          <strong>% of Equity:</strong> Each order is % of
                          equity
                        </p>
                        {/* <p>
                      <strong>% of A. Balance:</strong> Each order is % of
                      available strategy balance
                    </p> */}
                      </div>
                    }
                  >
                    <InputNumber
                      min={0}
                      max={orderSizeType === "percent" ? 100 : undefined}
                      addonAfter={
                        <Form.Item noStyle>
                          <Select
                            style={{
                              width: 115,
                            }}
                            value={orderSizeType}
                            onChange={setOrderSizeType}
                          >
                            <Option value="USD">USD</Option>
                            <Option value="percent">% of equity</Option>
                          </Select>
                        </Form.Item>
                      }
                      style={{ width: "100%" }}
                      value={orderSize}
                      onChange={setOrderSize}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    label="Slippage"
                    tooltip={
                      <p>
                        The difference between the expected price of a trade and
                        the price at which the trade is executed
                      </p>
                    }
                  >
                    <InputNumber
                      step={0.01}
                      style={{ width: "100%" }}
                      value={slippage}
                      onChange={setSlippage}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    label="Commission per share"
                    tooltip={
                      <p>
                        The commission paid to the Broker per share to open the
                        trade
                      </p>
                    }
                  >
                    <InputNumber
                      min={0}
                      prefix="$"
                      step={0.01}
                      style={{ width: "100%" }}
                      value={premiumPerShare}
                      onChange={setPremiumPerShare}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    label="Min Commission"
                    tooltip={
                      <p>
                        The minimum commission amount paid to the Broker to open
                        the trade
                      </p>
                    }
                  >
                    <InputNumber
                      min={0}
                      prefix="$"
                      style={{ width: "100%" }}
                      value={premiumFee}
                      onChange={setPremiumFee}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col sm={24} lg={12}>
              <Row gutter={16}>
                <Divider orientation="left">Supported only in backtest</Divider>
                <Col>
                  <Form.Item label="Max open orders">
                    <InputNumber
                      min={1}
                      style={{ width: "100%" }}
                      value={maxOpenOrders}
                      onChange={setMaxOpenOrders}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item label="Reset prediction trend after sell">
                    <Switch
                      checked={resetTrendAfterSell}
                      onChange={setResetTrendAfterSell}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={32}>
            <Col xs={24} lg={6}>
              <Divider orientation="left">Deal Enter rules</Divider>
              <Row gutter={8} align="bottom">
                <Col>
                  <Form.Item label="Buy predict count">
                    <InputNumber
                      min={1}
                      style={{ width: "100%" }}
                      value={enterPositionBuyCount}
                      onChange={setEnterPositionBuyCount}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item label="Eclipse">
                    <ChooseModelsForStrategy
                      modelsList={modelsList}
                      strategyModel={results.model}
                      results={results}
                      handleSelectModel={handleSelectModel}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xs={24} lg={18}>
              <Divider orientation="left">Deal close rules</Divider>
              <Row gutter={16} align="bottom" justify="start">
                <Col>
                  <Form.Item label="Take Profit">
                    <InputNumber
                      min={0}
                      prefix="%"
                      style={{ width: "100%" }}
                      value={takeProfitPercent}
                      onChange={setTakeProfitPercent}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item label="Stop Loss">
                    <InputNumber
                      max={0}
                      prefix="%"
                      style={{ width: "100%" }}
                      value={stopLossPercent}
                      onChange={setStopLossPercent}
                    />
                  </Form.Item>
                </Col>
                {/* <Col>
                  <Form.Item label="Trailing Stop Loss">
                    <InputNumber
                      max={0}
                      prefix="%"
                      style={{ width: "100%" }}
                      value={trailingStopLossPercent}
                      onChange={setTrailingStopLossPercent}
                    />
                  </Form.Item>
                </Col> */}
                <Col>
                  <Form.Item label="No buy count">
                    <InputNumber
                      min={0}
                      style={{ width: "100%" }}
                      value={exitPositionNoBuyCount}
                      onChange={setExitPositionNoBuyCount}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item label="Days Open Count">
                    <InputNumber
                      min={0}
                      style={{ width: "100%" }}
                      value={daysOpenExitCount}
                      onChange={setDaysOpenExitCount}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item>
                    <CreateStrategy createNewStrategy={createNewStrategy} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          {similarStrategies && (
            <SimilarStrategies similarStrategies={similarStrategies} />
          )}
        </GenericCard>
      </Form>
      <Divider orientation="left">Backtest results</Divider>
      {backtestResults && (
        <div className="cards-wrapper">
          <TotalTransactions backtestResults={backtestResults} />
          <BuyAndHoldReturn backtestResults={backtestResults} />
          <StrategySuccessRateCard backtestResults={backtestResults} />
          <TotalPositionsClosedWithProfit backtestResults={backtestResults} />
          <VarianceCard backtestResults={backtestResults} />
          <MinMaxPerTransactionCard backtestResults={backtestResults} />
          <AverageProfitPerTransaction backtestResults={backtestResults} />
          <ProfitFactor backtestResults={backtestResults} />
          <AverageHold backtestResults={backtestResults} />
          <WinningMonthsCard backtestResults={backtestResults} />

          {backtestResults.openOrders &&
            backtestResults.openOrders.length > 0 && (
              <GenericCard
                title="Open Orders"
                className="table-card"
                maxHeight={610}
              >
                <StrategyOpenOrders
                  openOrders={backtestResults.openOrders}
                  profitTargetPercent={backtestResults.params.takeProfitPercent}
                />
              </GenericCard>
            )}

          <AiBacktestTable
            transactions={backtestResults.transactions}
            setTransactionVisibleRange={setTransactionVisibleRange}
          />
          <GenericCard
            title="Trading View"
            className="table-card"
            isCardOpenByDefault={false}
          >
            <TVChartContainer
              symbol={results?.symbol}
              transactions={backtestResults.transactions}
              openPositions={backtestResults.openOrders}
              visibleRange={visibleRange}
              resetVisibleRange={resetVisibleRange}
            />
          </GenericCard>
          <AccProfitChart transactions={backtestResults.transactions} />
          <StrategySuccessRateChart backtestResults={backtestResults} />
          <BacktestPerformance backtestResults={backtestResults} />

          <Popover
            content={
              <div className="flex gap-2 flex-wrap">
                <Button
                  icon={<LinkOutlined />}
                  onClick={onCopyBacktestLinkToClipboard}
                >
                  Copy link
                </Button>
                <Button onClick={shareToWhatsapp} icon={<WhatsAppOutlined />}>
                  Whatsapp
                </Button>
                <a href={shareUrl.current} target="_blank" rel="noreferrer">
                  <Button icon={<ExportOutlined />}>New tab</Button>
                </a>
              </div>
            }
            title="Share this backtest with others"
          >
            <Button
              icon={<ShareAltOutlined />}
              onClick={onCopyBacktestLinkToClipboard}
            >
              Share
            </Button>
          </Popover>
        </div>
      )}
    </>
  );
};

AiBacktestSection.propTypes = {};

export default AiBacktestSection;
