import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { App, Button, Col, Form, InputNumber, Modal, Row } from "antd";
import { apiDeleteTrade, apiEditTrade } from "api/apiAdmin";
import { ExclamationCircleFilled } from "@ant-design/icons";

const EditTradeModal = (props) => {
  const { deal, reloadTrades } = props;
  const [form] = Form.useForm();
  const { message, modal } = App.useApp();

  const [isOpen, setIsOpen] = useState(false);
  const [isUpdatePending, setIsUpdatePending] = useState(false);

  const fieldLabels = {
    amount: "Amount",
    openRate: "Open Rate",
    premiumFee: "Premium Fee",
    closeRate: "Close Rate",
  };

  const updateableFields = {
    amount: deal.amount,
    openRate: deal.openRate,
    premiumFee: deal.premiumFee,
    closeRate: deal.closeRate || null,
  };

  const handleUpdateTrade = async (values) => {
    setIsUpdatePending(true);
    try {
      await apiEditTrade({ tradeId: deal._id, newValues: values });
      await reloadTrades();
      message.success("Trade has been updated successfully");
      setIsOpen(false);
    } catch (err) {
      message.error("Failed to update trade");
    }
    setIsUpdatePending(false);
  };

  const handleDeleteTrade = async () => {
    try {
      await apiDeleteTrade(deal._id);
      await reloadTrades();
      message.success("Trade has been deleted successfully");
      setIsOpen(false);
    } catch (err) {
      message.error("Failed to delete trade");
    }
  };

  const onDeleteTrade = async () => {
    modal.confirm({
      title: "Are you sure you want to delete this trade?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDeleteTrade();
      },
      onCancel() {},
    });
  };

  useEffect(() => {
    form.setFieldsValue(updateableFields);
  }, [deal, isOpen]);

  return (
    <>
      <Button type="default" onClick={() => setIsOpen(true)}>
        Edit
      </Button>
      <Modal
        title="Edit Trade"
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={null}
        width={800}
        destroyOnClose={true}
      >
        <Form
          form={form}
          layout="vertical"
          name="edit_trade"
          onFinish={handleUpdateTrade}
          initialValues={updateableFields}
          preserve={false}
        >
          {Object.entries(updateableFields).map(([key, value]) => {
            if (!value) return null;
            return (
              <Form.Item
                key={key}
                name={key}
                label={fieldLabels[key]}
                rules={[
                  {
                    required: true,
                    message: `${fieldLabels[key]}} is required`,
                  },
                ]}
              >
                <InputNumber />
              </Form.Item>
            );
          })}

          <Row gutter={8} align="middle" justify="space-between">
            <Col>
              <Form.Item shouldUpdate style={{ paddingBlockStart: 10 }}>
                {() => (
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={isUpdatePending}
                  >
                    Update
                  </Button>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Col>
            <Button type="primary" danger onClick={onDeleteTrade}>
              Delete trade
            </Button>
          </Col>
        </Form>
      </Modal>
    </>
  );
};

EditTradeModal.propTypes = {
  deal: PropTypes.object,
  reloadTrades: PropTypes.func,
};

export default EditTradeModal;
