import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import GenericCard from "components/UI/GenericCard/GenericCard";
import AntTable from "components/UI/AntTable";
import dayjs from "dayjs";
import {
  calcProfitPerc,
  formatMomentDuration,
  returnUpDownClassname,
} from "utils/helpers";
import TriggerSellPosition from "components/Strategies/Modals/TriggerSellPosition";
import ConfirmSellPosition from "components/Strategies/Modals/ConfirmSellPosition";
import UnrealizedPnl from "./UnrealizedPnl";
import PortfolioBalance from "./PortfolioBalance";
import { apiGetUserStrategies } from "api/apiStrategies";

const OpenPositions = () => {
  const [openPositions, setOpenPositions] = useState([]);
  const accountType = useSelector((state) => state.main.accountType);

  const getUserStrategies = async () => {
    const response = await apiGetUserStrategies({
      accountType,
    });
    const positions = response.map((strategy) => strategy.openPositions).flat();
    setOpenPositions(positions);
  };

  const sortByNumber = useCallback((a, b) => {
    // handle null / N/A values
    if (a === b) return 0;
    if (a === null || a === "N/A") return -1;
    if (b === null || b === "N/A") return 1;
    return a > b ? 1 : -1;
  }, []);

  const sortByText = useCallback((a, b) => {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  }, []);

  const columns = [
    {
      title: "Symbol",
      dataIndex: "symbol",
      sorter: {
        compare: (a, b) => sortByText(a.symbol, b.symbol),
      },
    },
    {
      title: "Contracts",
      dataIndex: "amount",
      sorter: {
        compare: (a, b) => sortByNumber(a.amount, b.amount),
      },
    },
    {
      title: "Buy Price",
      dataIndex: "openRate",
      render: (text, properties) => (
        <div>
          <div>{text}</div>
          <div>
            <span style={{ fontSize: 12 }}>
              ${properties.openValue.toLocaleString()}
            </span>
          </div>
        </div>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.openRate, b.openRate),
      },
    },
    {
      title: "Last Close",
      dataIndex: "currentRate",
      render: (text, properties) => (
        <div>
          <div>{text}</div>
          <div>
            <span style={{ fontSize: 12 }}>
              {(text * properties.amount || 0).toLocaleString()}
            </span>
          </div>
        </div>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.currentRate, b.currentRate),
      },
    },
    {
      title: "Profit",
      dataIndex: "profit.value",
      render: (text, properties) => (
        <span className={returnUpDownClassname(properties.profit.value)}>
          ${properties.profit.value.toLocaleString()}
        </span>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.profit.value, b.profit.value),
      },
    },
    {
      title: "Change",
      dataIndex: "profit.perc",
      render: (text, properties) => (
        <span className={returnUpDownClassname(properties.profit.perc)}>
          {properties.profit.perc}%
        </span>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.profit.perc, b.profit.perc),
      },
    },
    {
      title: "Run-up",
      dataIndex: "runup.perc",
      render: (text, properties) => (
        <div
          className={returnUpDownClassname(properties.runup.value)}
          title={`Rate: ${properties.runup.rate}`}
        >
          <div>${properties.runup.value.toLocaleString()}</div>
          <span style={{ fontSize: 12 }}>%{properties.runup.perc}</span>
        </div>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.runup.value, b.runup.value),
      },
    },
    {
      title: "Drawdown",
      dataIndex: "drawdown.perc",
      render: (text, properties) => (
        <div
          className={returnUpDownClassname(properties.drawdown.value)}
          title={`Rate: ${properties.drawdown.rate}`}
        >
          <div>${properties.drawdown.value.toLocaleString()}</div>
          <span style={{ fontSize: 12 }}>%{properties.drawdown.perc}</span>
        </div>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.drawdown.value, b.drawdown.value),
      },
    },
    {
      title: "Live Rate",
      dataIndex: "liveRate",
      render: (text, properties) => {
        const change = calcProfitPerc(properties.openRate, text);
        return (
          <div>
            <div>{text}</div>
            <div>
              <span
                style={{ fontSize: 12 }}
                className={returnUpDownClassname(change)}
              >
                {change}%
              </span>
            </div>
          </div>
        );
      },
      sorter: {
        compare: (a, b) => sortByNumber(a.liveRate, b.liveRate),
      },
    },
    {
      title: "Max Rate",
      dataIndex: "maxLiveRate",
      render: (text, properties) => {
        const change = calcProfitPerc(properties.openRate, text);
        return (
          <div>
            <div>{text}</div>
            <div>
              <span
                style={{ fontSize: 12 }}
                className={returnUpDownClassname(change)}
              >
                {change}%
              </span>
            </div>
          </div>
        );
      },
      sorter: {
        compare: (a, b) => sortByNumber(a.maxLiveRate, b.maxLiveRate),
      },
    },
    {
      title: "Min Rate",
      dataIndex: "minLiveRate",
      render: (text, properties) => {
        const change = calcProfitPerc(properties.openRate, text);
        return (
          <div>
            <div>{text}</div>
            <div>
              <span
                style={{ fontSize: 12 }}
                className={returnUpDownClassname(change)}
              >
                {change}%
              </span>
            </div>
          </div>
        );
      },
      sorter: {
        compare: (a, b) => sortByNumber(a.minLiveRate, b.minLiveRate),
      },
    },
    {
      title: "Days",
      dataIndex: "dealDaysOpen",
      sorter: {
        compare: (a, b) => sortByNumber(a.dealDaysOpen, b.dealDaysOpen),
      },
    },
    {
      title: "Duration",
      dataIndex: "openDate",
      render: (text) => (
        <span>{formatMomentDuration(+new Date() - +new Date(text))}</span>
      ),
      sorter: {
        compare: (a, b) => sortByText(a.openDate, b.openDate),
      },
    },
    {
      title: "Open Date",
      dataIndex: "openDate",
      render: (text) => <>{dayjs(text).format("HH:mm:ss - DD/MM/YY")}</>,
      sorter: {
        compare: (a, b) => sortByNumber(a.openDate, b.openDate),
      },
    },
    {
      title: "Trigger Sell",
      key: "uid",
      dataIndex: "",
      render: (text) =>
        text.orderStatus === "active" ? (
          <TriggerSellPosition
            activePosition={text}
            getUserStrategies={getUserStrategies}
          />
        ) : (
          <div className="w-[70px]"></div>
        ),
    },
    {
      title: "Sell",
      key: "uid",
      dataIndex: "",
      render: (text) => (
        <ConfirmSellPosition
          activePosition={text}
          getUserStrategies={getUserStrategies}
        />
      ),
    },
  ];

  useEffect(() => {
    getUserStrategies();
  }, [accountType]);

  useEffect(() => {
    document.title = "Open Positions | Dindicator Dashboard";
  }, []);

  return (
    <div className="cards-wrapper">
      <PortfolioBalance openPositions={openPositions} />
      <UnrealizedPnl openPositions={openPositions} />
      <GenericCard
        title="Open Positions"
        className="table-card"
        isCardOpenByDefault={true}
        maxHeight={610}
      >
        <AntTable rowKey="_id" dataSource={openPositions} columns={columns} />
      </GenericCard>
    </div>
  );
};

export default OpenPositions;
