import React, { useState } from "react";
import PropTypes from "prop-types";
import { App, Button, Card, Form, Input, Row } from "antd";
import {
  apiDeleteStrategyNote,
  apiUpdateStrategyNote,
} from "api/apiStrategies";

import { DeleteOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import dayjs from "dayjs";

const StrategyNoteCard = (props) => {
  const { note, strategyId, getUserStrategies } = props;
  const { modal } = App.useApp();
  const [isEditMode, setIsEditMode] = useState(false);
  const [isEditPending, setIsEditPending] = useState(false);

  const [form] = Form.useForm();

  const onUpdateNote = async (values) => {
    setIsEditPending(true);
    try {
      await apiUpdateStrategyNote(strategyId, { ...values, _id: note._id });
      await getUserStrategies();
      setIsEditMode(false);
    } catch (err) {}
    setIsEditPending(false);
  };

  const showDeleteConfirm = () => {
    modal.confirm({
      title: "Are you sure you want to delete this note?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        try {
          await apiDeleteStrategyNote(strategyId, note._id);
          await getUserStrategies();
        } catch (err) {}
      },
      onCancel() {},
    });
  };

  return !isEditMode ? (
    <Card
      title={note.title}
      extra={
        <Button type="link" onClick={() => setIsEditMode(true)}>
          Edit
        </Button>
      }
      style={{ marginBlockStart: 15 }}
    >
      <p
        style={{
          whiteSpace: "pre-wrap",
          wordBreak: "break-word",
        }}
        dangerouslySetInnerHTML={{ __html: note.content }}
      />
      <br />
      <Row justify={"space-between"}>
        <Card.Meta
          description={`Created at ${dayjs(note.created).format(
            "HH:mm - DD/MM/YY"
          )}`}
        />
        {note.created !== note.lastUpdate && (
          <Card.Meta
            description={`Last update: ${dayjs(note.lastUpdate).format(
              "HH:mm - DD/MM/YY"
            )}`}
          />
        )}
      </Row>
    </Card>
  ) : (
    <Form
      form={form}
      name="new_note"
      onFinish={onUpdateNote}
      initialValues={{ title: note.title, content: note.content }}
      style={{ marginBlockStart: 20 }}
    >
      <Card
        title={
          <Form.Item
            name="title"
            noStyle
            required={false}
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <Input style={{ maxWidth: 400 }} placeholder="Enter title" />
          </Form.Item>
        }
        extra={[
          <Button
            style={{ marginInlineEnd: 10 }}
            icon={<DeleteOutlined />}
            onClick={showDeleteConfirm}
            ghost
            danger
          />,
          <Button
            type="default"
            style={{ marginInlineEnd: 10 }}
            onClick={() => setIsEditMode(false)}
          >
            Cancel
          </Button>,
          <Form.Item shouldUpdate noStyle>
            {() => (
              <Button type="primary" htmlType="submit" loading={isEditPending}>
                Update
              </Button>
            )}
          </Form.Item>,
        ]}
      >
        <Form.Item
          name="content"
          required={false}
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        >
          <Input.TextArea
            placeholder="Enter content"
            style={{ minHeight: 200 }}
          />
        </Form.Item>
      </Card>
    </Form>
  );
};

StrategyNoteCard.propTypes = {
  note: PropTypes.object,
  strategyId: PropTypes.string,
  getUserStrategies: PropTypes.func,
};

export default StrategyNoteCard;
