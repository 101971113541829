import React from "react";
import PropTypes from "prop-types";
import { Avatar, Badge, Tooltip } from "antd";
import { UserOutlined } from "@ant-design/icons";

const ProfileAvatar = (props) => {
  const { user, size, count = 0, showTooltip = true, handleSelectUser } = props;
  const nameCombination = user
    ? `${user.firstName[0]}${user.lastName[0]}`
    : "N/A";

  const fullName =
    user && showTooltip ? `${user?.firstName} ${user?.lastName}` : "";

  return (
    <Tooltip title={fullName}>
      <Badge count={count !== 1 ? count : 0} size="small">
        <Avatar
          size={size}
          icon={!user && <UserOutlined />}
          src={user ? `/images/${user?.profilePic}` : ""}
          style={{
            verticalAlign: "middle",
          }}
          onClick={handleSelectUser ? () => handleSelectUser(user._id) : null}
        >
          {nameCombination}
        </Avatar>
      </Badge>
    </Tooltip>
  );
};

ProfileAvatar.propTypes = {
  user: PropTypes.object,
  size: PropTypes.string,
  count: PropTypes.number,
  showTooltip: PropTypes.bool,
  handleSelectUser: PropTypes.func,
};

export default ProfileAvatar;
