import axios from "utils/axios";
import { routes } from "api/routes";

export const apiGetBrokerOrders = async (formData) => {
  const { data } = await axios.get(routes.getBrokerOrders, formData);
  return data;
};

export const apiRetryToOpenTrade = async (formData) => {
  const { data } = await axios.post(routes.retryToOpenTrade, formData);
  return data;
};
