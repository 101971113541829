import axios from "utils/axios";
import { routes } from "api/routes";

export const apiGetStrategyTabs = async () => {
  const { data } = await axios.get(routes.getAllStrategyTabs);
  return data;
};

export const apiCreateNewStrategyTab = async (formData) => {
  const { data } = await axios.post(routes.createNewStrategyTab, formData);
  return data;
};

export const apiDeleteStrategyTab = async (tabId) => {
  const { data } = await axios.delete(routes.deleteStrategyTabById, {
    params: { tabId },
  });
  return data;
};

export const apiUpdateStrategyTab = async (tabId, updatedData) => {
  const { data } = await axios.put(routes.updateStrategyTabById, updatedData, {
    params: { tabId },
  });
  return data;
};
