import React from "react";
import PropTypes from "prop-types";

import "./ActiveLight.scss";

const ActiveLight = (props) => {
  const { text = "Active" } = props;
  return (
    <div className="flex items-center">
      <div className="blob"></div>
      <span className="green text-[1.25rem]">{text}</span>
    </div>
  );
};

ActiveLight.propTypes = { text: PropTypes.string };

export default ActiveLight;
