import React from "react";
import PropTypes from "prop-types";
import { Navigate, useLocation } from "react-router";
import { useSelector } from "react-redux";
import { paths } from "utils/constants";

const ProtectedRoute = (props) => {
  const {
    children,
    redirectPath = paths.home,
    requiredPermissions = [],
  } = props;

  const location = useLocation();

  const user = useSelector((state) => state.main.user);

  const isRoleAllowed =
    requiredPermissions.length === 0 ||
    requiredPermissions.some((role) => role === user?.role) ||
    user?.role === "admin";

  if (!user) {
    return <Navigate to={paths.login} state={{ from: location }} replace />;
  } else if (user && isRoleAllowed) {
    return children;
  } else {
    return <Navigate to={redirectPath} state={{ from: location }} replace />;
  }
};

ProtectedRoute.propTypes = {
  redirectPath: PropTypes.string,
  requiredPermissions: PropTypes.array,
};

export default ProtectedRoute;
