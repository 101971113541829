import React, { useState } from "react";

import { Button, Divider, Form, InputNumber } from "antd";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const allowedIndicators = [
  "RSI",
  "OBV",
  "StochasticOscillator",
  "WilliamsRange",
  "PROC",
  "MACD",
  // "CBOE",
];

const IndicatorsSelector = () => {
  const [unusedIndicators, setUnusedIndicators] = useState(allowedIndicators);
  const [wantedIndicators, setWantedIndicators] = useState([]);

  const addIndicator = (indicator) => {
    if (indicator === "MACD") {
      setWantedIndicators((prevState) => [...prevState, indicator]);
    } else {
      setWantedIndicators((prevState) => [indicator, ...prevState]);
    }
    setUnusedIndicators((prevState) =>
      prevState.filter((item) => item !== indicator)
    );
  };

  const removeIndicator = (indicator) => {
    setWantedIndicators((prevState) =>
      prevState.filter((item) => item !== indicator)
    );
    setUnusedIndicators((prevState) => [...prevState, indicator]);
  };

  const returnIndicatorInput = (indicator) => {
    switch (indicator) {
      case "MACD":
        return (
          <div key={indicator} className="flex gap-2 items-end basis-[100%]">
            <Form.Item
              name={["indicators", indicator, "short_period"]}
              initialValue={12}
              label={
                <div>
                  <MinusCircleOutlined
                    className="me-2 cursor-pointer"
                    onClick={() => removeIndicator(indicator)}
                  />
                  {indicator} <br /> Short Period
                </div>
              }
              required={false}
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              name={["indicators", indicator, "long_period"]}
              initialValue={26}
              label="Long Period"
              required={false}
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              name={["indicators", indicator, "macd_period"]}
              initialValue={9}
              label="MACD Period"
              required={false}
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <InputNumber />
            </Form.Item>
          </div>
        );

      default:
        let initialValue;
        if (indicator === "RSI") {
          initialValue = 14;
        }
        if (indicator === "OBV") {
          initialValue = 9;
        }
        if (indicator === "StochasticOscillator") {
          initialValue = 14;
        }
        if (indicator === "WilliamsRange") {
          initialValue = 14;
        }
        if (indicator === "PROC") {
          initialValue = 9;
        }
        if (indicator === "CBOE") {
          initialValue = 1;
        }
        return (
          <div key={indicator}>
            <Form.Item
              name={["indicators", indicator]}
              label={
                <div>
                  <MinusCircleOutlined
                    className="me-2 cursor-pointer"
                    onClick={() => removeIndicator(indicator)}
                  />
                  {indicator}
                </div>
              }
              required={false}
              initialValue={initialValue}
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </div>
        );
    }
  };
  return (
    <div className="basis-[70%]">
      {/* <p className="mb-1">Add Indicators</p> */}
      <Divider orientation="left">Select Indicators</Divider>
      <div className="flex flex-wrap gap-2 mb-4">
        {unusedIndicators.map((indicator, idx) => (
          <div key={idx}>
            <Button
              type="dashed"
              icon={<PlusOutlined />}
              onClick={() => addIndicator(indicator)}
            >
              {indicator}
            </Button>
          </div>
        ))}
      </div>
      {/* <p className="mb-1 text-lg">Indicators</p> */}
      <div className="flex flex-wrap gap-2">
        {wantedIndicators.map((indicator, idx) =>
          returnIndicatorInput(indicator)
        )}
      </div>
    </div>
  );
};

export default IndicatorsSelector;
