import React from "react";
import PropTypes from "prop-types";
import csvDownload from "json-to-csv-export";
import { Button } from "antd";

const ExportCsvButton = (props) => {
  const { data, fileName } = props;
  return data?.length > 0 ? (
    <Button
      type="default"
      onClick={() => csvDownload({ data })}
      filename={fileName}
    >
      Download CSV
    </Button>
  ) : (
    <></>
  );
};

ExportCsvButton.propTypes = {
  data: PropTypes.array.isRequired,
  fileName: PropTypes.string.isRequired,
};

export default ExportCsvButton;
