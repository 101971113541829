import React, { useMemo } from "react";
import PropTypes from "prop-types";
import GenericCard from "components/UI/GenericCard/GenericCard";
import dayjs from "dayjs";
import { returnUpDownClassname } from "utils/helpers";

const LivePnl = (props) => {
  const { openPositions, closedPositions } = props;

  const livePnl = useMemo(() => {
    let lastUpdate;
    const realizedPnl = closedPositions.reduce((acc, item) => {
      acc += item.netProfit.value;
      return acc;
    }, 0);
    const unrealizedPnl = openPositions.reduce((acc, item) => {
      acc += item.profit.value;
      if (!lastUpdate && item.lastUpdate)
        lastUpdate = new Date(item.lastUpdate);
      if (+lastUpdate < +new Date(item.lastUpdate)) {
        lastUpdate = new Date(item.lastUpdate);
      }
      return acc;
    }, 0);

    return { value: realizedPnl + unrealizedPnl, lastUpdate };
  }, [closedPositions]);

  return (
    <GenericCard title="Live PNL" tooltipText="Realized PNL + Unrealized PNL">
      <h2 className={returnUpDownClassname(livePnl?.value)}>
        ${livePnl?.value.toLocaleString()}
      </h2>
      {livePnl?.lastUpdate && (
        <h5>
          Last update: {dayjs(livePnl.lastUpdate).format("HH:mm:ss DD/MM/YY")}
        </h5>
      )}
    </GenericCard>
  );
};

LivePnl.propTypes = {
  openPositions: PropTypes.array,
  closedPositions: PropTypes.array,
};

export default LivePnl;
