import React, { useCallback, useEffect, useState } from "react";
import { apiGetBrokerOrders, apiRetryToOpenTrade } from "api/apiBrokerOrders";
import AntTable from "components/UI/AntTable";
import GenericCard from "components/UI/GenericCard/GenericCard";
import dayjs from "dayjs";

const OrdersStatus = () => {
  const [executions, setExecutions] = useState([]);
  const [pending, setPending] = useState(false);

  const getOrders = async () => {
    const response = await apiGetBrokerOrders();
    setExecutions(response);
  };

  const onRetryOpenTrade = async (orderDetails) => {
    if (pending) return;
    setPending(true);
    await apiRetryToOpenTrade(orderDetails);
    getOrders();
    setPending(false);
  };

  const sortByNumber = useCallback((a, b) => {
    // handle null / N/A values
    if (a === b) return 0;
    if (a === null || a === "N/A") return -1;
    if (b === null || b === "N/A") return 1;
    return a > b ? 1 : -1;
  }, []);

  const sortByText = useCallback((a, b) => {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  }, []);

  const columns = [
    {
      title: "Symbol",
      dataIndex: "symbol",
      sorter: {
        compare: (a, b) => sortByText(a.symbol, b.symbol),
      },
    },
    {
      title: "Action",
      dataIndex: "side",
      render: (text) => {
        return text === "BOT" ? (
          <span className="green">Buy</span>
        ) : (
          <span className="red">Sell</span>
        );
      },
      sorter: {
        compare: (a, b) => sortByText(a.side, b.side),
      },
    },
    {
      title: "Original price",
      dataIndex: "originalPrice",
      sorter: {
        compare: (a, b) => sortByNumber(a.originalPrice, b.originalPrice),
      },
    },
    {
      title: "Limit price",
      dataIndex: "lmtPrice",
      sorter: {
        compare: (a, b) => sortByNumber(a.lmtPrice, b.lmtPrice),
      },
    },
    {
      title: "Avg price",
      dataIndex: "avgPrice",
      sorter: {
        compare: (a, b) => sortByNumber(a.avgPrice, b.avgPrice),
      },
    },
    // {
    //   title: "Diff",
    //   dataIndex: "priceDifference",
    //   render: (text) => {
    //     if (text === 0) {
    //       return <span>N/A</span>;
    //     }
    //     return text < 0 ? (
    //       <span className="green">{text}%</span>
    //     ) : (
    //       <span className="red">{text}%</span>
    //     );
    //   },
    //   sorter: {
    //     compare: (a, b) => sortByNumber(a.priceDifference, b.priceDifference),
    //   },
    // },
    {
      title: "Shares",
      dataIndex: "cumQty",
      render: (text, properties) => (
        <>
          {text}/{properties.shares}
        </>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.cumQty, b.cumQty),
      },
    },

    {
      title: "Status",
      dataIndex: "status",
      render: (text, props) => {
        if (text === "Filled") {
          return <span className="green">Filled</span>;
        } else if (text === "PendingSubmit" && props.cumQty > 0) {
          return <span className="yellow">Partial Filled</span>;
        } else if (text === "PendingSubmit") {
          return <span className="yellow">Pending</span>;
        } else if (text === "Canceled") {
          return (
            <div>
              <div className="red">Canceled</div>
              <div className="link" onClick={() => onRetryOpenTrade(props)}>
                Retry
              </div>
            </div>
          );
        }
        return <span className="red">{text}</span>;
      },
      sorter: {
        compare: (a, b) => sortByText(a.status, b.status),
      },
    },
    {
      title: "Create Time",
      dataIndex: "created",
      render: (text) => <>{dayjs(text).format("HH:mm:ss - DD/MM/YY")}</>,
      sorter: {
        compare: (a, b) => sortByNumber(a.time, b.time),
      },
    },
    {
      title: "Update Time",
      dataIndex: "time",
      render: (text) => <>{dayjs(text).format("HH:mm:ss - DD/MM/YY")}</>,
      sorter: {
        compare: (a, b) => sortByNumber(a.time, b.time),
      },
    },
  ];

  useEffect(() => {
    document.title = "Orders Status | Dindicator Dashboard";
    getOrders();
  }, []);

  return (
    <div>
      <GenericCard
        title="Orders Status"
        className="table-card"
        isCardOpenByDefault={true}
        maxHeight={610}
      >
        <AntTable rowKey="_id" dataSource={executions} columns={columns} />
      </GenericCard>
    </div>
  );
};

export default OrdersStatus;
