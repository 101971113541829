import axios from "utils/axios";
import { routes } from "api/routes";

export const apiGetTradesHistory = async (formData) => {
  const { data } = await axios.post(routes.getTradesHistory, formData);
  return data;
};

export const apiTriggerCloseTrade = async (formData) => {
  const { data } = await axios.post(routes.triggerCloseTrade, formData);
  return data;
};
