import React from "react";
import PropTypes from "prop-types";
import GenericCard from "../UI/GenericCard/GenericCard";
import { formatNumber } from "utils/helpers";

const TotalTransactions = (props) => {
  const { backtestResults } = props;

  return (
    <GenericCard title="Transactions">
      <h2>{backtestResults.metrics.totalTransactions}</h2>
      <h5>$ {formatNumber(backtestResults.metrics.totalCommissions)} fees</h5>
    </GenericCard>
  );
};

TotalTransactions.propTypes = { backtestResults: PropTypes.object };

export default TotalTransactions;
