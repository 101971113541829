import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { apiGetTradesHistory } from "api/apiTrades";
import RealizedPnl from "components/Strategies/Cards/RealizedPnl";
import GenericCard from "components/UI/GenericCard/GenericCard";
import AntTable from "components/UI/AntTable";
import { formatMomentDuration, returnUpDownClassname } from "utils/helpers";
import dayjs from "dayjs";
import { Button, DatePicker, Form, Popover } from "antd";
import { rangePresets } from "utils/constants";
import { QuestionCircleOutlined } from "@ant-design/icons";
import ProfitHistoryChart from "./ProfitHistoryChart";

const { RangePicker } = DatePicker;

const returnLast12Months = () => {
  const today = dayjs();
  const last12Months = [];
  for (let i = 0; i < 12; i++) {
    const month = today.subtract(i, "month");
    const startDate = month.startOf("month").format("YYYY-MM-DD");
    const endDate = month.endOf("month").format("YYYY-MM-DD");
    last12Months.push({
      month: month.format("MMMM YY"),
      startDate,
      endDate,
    });
  }
  console.log(last12Months);
  return last12Months;
};

const TradesHistory = () => {
  const [tradesHistory, setTradesHistory] = useState([]);
  const [form] = Form.useForm();
  const accountType = useSelector((state) => state.main.accountType);

  const last12MonthsArray = returnLast12Months();

  const getTradesHistory = async (values) => {
    let query = {
      accountType,
    };
    if (values) {
      const { dates } = values;
      if (dates) {
        const [startDate, endDate] = dates;
        query = {
          ...query,
          startDate: dayjs(startDate).format("YYYY-MM-DD"),
          endDate: dayjs(endDate).format("YYYY-MM-DD"),
        };
      }
    }

    const tradesHistory = await apiGetTradesHistory(query);
    setTradesHistory(tradesHistory);
  };

  const onSelectMonthHistory = (month) => {
    const { startDate, endDate } = month;
    form.setFieldValue("dates", [dayjs(startDate), dayjs(endDate)]);
    getTradesHistory({ dates: [dayjs(startDate), dayjs(endDate)] });
  };

  const sortByNumber = useCallback((a, b) => {
    // handle null / N/A values
    if (a === b) return 0;
    if (a === null || a === "N/A") return -1;
    if (b === null || b === "N/A") return 1;
    return a > b ? 1 : -1;
  }, []);

  const sortByText = useCallback((a, b) => {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  }, []);

  const columns = [
    {
      title: "Details",
      dataIndex: "_id",
      render: (text, properties) => (
        <Popover
          content={
            <div>
              <div className="pb-2">
                <p className="font-semibold">Strategy Name:</p>
                <p>
                  {properties.strategy.name
                    ? properties.strategy.name
                    : "Deleted strategy"}
                </p>
              </div>
              <div>
                <p className="font-semibold">Trade ID:</p>
                <p>{text}</p>
              </div>
            </div>
          }
          trigger="click"
        >
          <Button
            type="text"
            shape="circle"
            icon={<QuestionCircleOutlined />}
          />
        </Popover>
      ),
    },
    {
      title: "Symbol",
      dataIndex: "symbol",
      sorter: {
        compare: (a, b) => sortByText(a.symbol, b.symbol),
      },
    },
    {
      title: "Buy Price",
      dataIndex: "openRate",
      render: (text, properties) => (
        <div>
          <div>{text}</div>
          <div>
            <span style={{ fontSize: 12 }}>
              ${properties.openValue.toLocaleString()}
            </span>
          </div>
        </div>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.openRate, b.openRate),
      },
    },
    {
      title: "Sell Price",
      dataIndex: "closeRate",
      render: (text, properties) => (
        <div>
          <div>{text}</div>
          <div>
            <span style={{ fontSize: 12 }}>
              ${properties.closeValue.toLocaleString()}
            </span>
          </div>
        </div>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.closeRate, b.closeRate),
      },
    },
    {
      title: "Contracts",
      dataIndex: "amount",
      sorter: {
        compare: (a, b) => sortByNumber(a.amount, b.amount),
      },
    },
    {
      title: "Signal",
      dataIndex: "closeReason",
      sorter: {
        compare: (a, b) => sortByText(a.closeReason, b.closeReason),
      },
    },
    {
      title: "Net Profit",
      dataIndex: "profit.value",
      render: (text, properties) => (
        <span className={returnUpDownClassname(properties.netProfit.value)}>
          ${properties.netProfit.value.toLocaleString()}
        </span>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.netProfit.value, b.netProfit.value),
      },
    },
    {
      title: "Change",
      dataIndex: "profit.perc",
      render: (text, properties) => (
        <span className={returnUpDownClassname(properties.netProfit.perc)}>
          {properties.netProfit.perc}%
        </span>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.netProfit.perc, b.netProfit.perc),
      },
    },
    {
      title: "Run-up",
      dataIndex: "runup.perc",
      render: (text, properties) => (
        <div
          className={returnUpDownClassname(properties.runup.value)}
          title={`Rate: ${properties.runup.rate}`}
        >
          <div>${properties.runup.value.toLocaleString()}</div>
          <span style={{ fontSize: 12 }}>%{properties.runup.perc}</span>
        </div>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.runup.value, b.runup.value),
      },
    },
    {
      title: "Drawdown",
      dataIndex: "drawdown.perc",
      render: (text, properties) => (
        <div
          className={returnUpDownClassname(properties.drawdown.value)}
          title={`Rate: ${properties.drawdown.rate}`}
        >
          <div>${properties.drawdown.value.toLocaleString()}</div>
          <span style={{ fontSize: 12 }}>%{properties.drawdown.perc}</span>
        </div>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.drawdown.value, b.drawdown.value),
      },
    },
    {
      title: "Days",
      dataIndex: "dealDaysOpen",
      sorter: {
        compare: (a, b) => sortByNumber(a.dealDaysOpen, b.dealDaysOpen),
      },
    },
    {
      title: "Duration",
      dataIndex: "openDate",
      render: (text, { closeDate }) => (
        <span>
          {formatMomentDuration(+new Date(closeDate) - +new Date(text))}
        </span>
      ),
      sorter: {
        compare: (a, b) => sortByText(a.openDate, b.openDate),
      },
    },
    {
      title: "Start",
      dataIndex: "openDate",
      render: (text) => <>{dayjs(text).format("HH:mm:ss - DD/MM/YY")}</>,
      sorter: {
        compare: (a, b) => sortByNumber(a.openDate, b.openDate),
      },
    },
    {
      title: "End",
      dataIndex: "closeDate",
      render: (text) => <>{dayjs(text).format("HH:mm:ss - DD/MM/YY")}</>,
      sorter: {
        compare: (a, b) => sortByNumber(a.closeDate, b.closeDate),
      },
    },
  ];

  useEffect(() => {
    getTradesHistory();
  }, [accountType]);

  useEffect(() => {
    document.title = "Trades History | Dindicator Dashboard";
  }, []);

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        name="search-trades-history"
        initialValues={{}}
        onFinish={getTradesHistory}
        className="max-w-[400px] pb-2"
      >
        <div className="flex items-end gap-4">
          <Form.Item name={"dates"} label="Dates">
            <RangePicker
              presets={rangePresets}
              format="DD/MM/YY"
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
          <Form.Item shouldUpdate>
            {() => (
              <Button type="primary" htmlType="submit">
                Search
              </Button>
            )}
          </Form.Item>
        </div>
      </Form>
      <div className="flex gap-4 mb-4 flex-wrap">
        {last12MonthsArray.map((month) => (
          <Button
            key={month.month}
            onClick={() => onSelectMonthHistory(month)}
            // className="flex-1"
          >
            {month.month}
          </Button>
        ))}
      </div>
      <div className="cards-wrapper">
        <RealizedPnl closedPositions={tradesHistory} />
        <GenericCard
          title={`Trades History (${tradesHistory.length} trades}`}
          className="table-card"
          isCardOpenByDefault={true}
          maxHeight={610}
        >
          <AntTable rowKey="_id" dataSource={tradesHistory} columns={columns} />
        </GenericCard>
        <ProfitHistoryChart transactions={tradesHistory} />
      </div>
    </div>
  );
};

export default TradesHistory;
