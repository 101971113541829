import React, { useEffect } from "react";
import ChangePremiumFee from "./ChangePremiumFee";
import ChangePassword from "./ChangePassword";
import AutomationOnboarding from "./AutomationOnboarding";

const UserSettings = () => {
  useEffect(() => {
    document.title = "Settings | Dindicator Dashboard";
  }, []);

  return (
    <>
      <div className="cards-wrapper">
        <ChangePassword />
        <ChangePremiumFee />
      </div>

      <AutomationOnboarding />
    </>
  );
};

export default UserSettings;
