import dayjs from "dayjs";

export const paths = {
  home: "/",
  login: "/login",
  register: "/auth/register",
  strategies: "/strategies",
  openPositions: "/open-positions",
  tradesHistory: "/trades-history",
  ai: "/ai",
  aiNew: "/ai-new",
  createModel: "/create-model",
  admin: "/admin",
  symbolsManagement: "/admin/symbols-management",
  usersManagement: "/admin/users-management",
  systemControl: "/admin/system-control",
  editTrades: "/admin/edit-trades",
  savePredictions: "/admin/save-predictions",
  yahooFinancePriceCompare: "/admin/yahoo-finance-price-compare",
  userSettings: "/settings",
  ordersStatus: "/orders-status",
  strategy: "/strategy",
};

export const rangePresets = [
  {
    label: "Today",
    value: [dayjs().add(-1, "d"), dayjs()],
  },
  {
    label: "Last Week",
    value: [dayjs().add(-7, "d"), dayjs()],
  },
  {
    label: "Last 2 Weeks",
    value: [dayjs().add(-14, "d"), dayjs()],
  },
  {
    label: "Last Month",
    value: [dayjs().add(-1, "M"), dayjs()],
  },
  {
    label: "Last 3 Months",
    value: [dayjs().add(-3, "M"), dayjs()],
  },
  {
    label: "Last 6 Months",
    value: [dayjs().add(-6, "M"), dayjs()],
  },
  {
    label: "Last Year",
    value: [dayjs().add(-1, "y"), dayjs()],
  },
];

export const modelSearchRangePresets = [
  {
    label: "Last 3 months",
    value: [dayjs().add(-3, "M"), dayjs()],
  },
  {
    label: "Last Year",
    value: [dayjs().add(-1, "y"), dayjs()],
  },
  {
    label: "Last 2 Year",
    value: [dayjs().add(-2, "y"), dayjs()],
  },
  {
    label: "Last 3 Year",
    value: [dayjs().add(-3, "y"), dayjs()],
  },
];
