import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { Button, DatePicker, Form, Input, InputNumber, Modal } from "antd";
import { apiConfirmStrategyTradeSell } from "api/apiStrategies";
import { calcProfitPerc, returnUpDownClassname } from "utils/helpers";

const ConfirmSellPosition = (props) => {
  const { activePosition, getUserStrategies } = props;
  const [form] = Form.useForm();
  const sellPrice = Form.useWatch("closeRate", form);

  const [isOpen, setIsOpen] = useState(false);
  const userPremiumFee = useSelector(
    (state) => state.main.user.preferences.premiumFee
  );

  const percChange = calcProfitPerc(activePosition.openRate, sellPrice) || 0;

  const handleConfirmSellPosition = async (values) => {
    try {
      const data = {
        dealId: activePosition._id,
        closeRate: values.closeRate,
        amount: values.amount,
        closeValue: values.closeRate * values.amount,
        premiumFee: values.premiumFee,
        closeDate: values.closeDate,
        closeSignal: activePosition.closeSignal || "manual",
        closeReason: values.closeReason,
      };

      await apiConfirmStrategyTradeSell(data);
      await getUserStrategies();
      setIsOpen(false);
    } catch (err) {}
  };

  const updatePositionValue = (values, allValues) => {
    const value = allValues.closeRate * allValues.amount;
    form.setFieldValue("closeValue", +value?.toFixed(2));
  };

  return (
    <>
      <Button type="primary" onClick={() => setIsOpen(true)}>
        Sell
      </Button>
      <Modal
        title="Confirm Sell"
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={null}
        width={800}
        destroyOnClose={true}
      >
        <Form
          form={form}
          layout="vertical"
          name="confirm_sell_position"
          onFinish={handleConfirmSellPosition}
          onValuesChange={updatePositionValue}
          initialValues={{
            closeRate: activePosition.currentRate,
            amount: activePosition.amount,
            closeValue: activePosition.currentRate * activePosition.amount,
            premiumFee: userPremiumFee || 2.5,
            closeDate: dayjs(),
            closeReason: activePosition.closeReason,
          }}
          preserve={false}
        >
          <Form.Item
            name="closeRate"
            label="Price per share"
            rules={[
              {
                required: true,
                message: "Price is required!",
              },
            ]}
            required={false}
          >
            <InputNumber style={{ width: "100%" }} step={0.01} />
          </Form.Item>
          <Form.Item
            name="amount"
            label="Amount"
            rules={[
              {
                required: true,
                message: "Amount is required!",
              },
            ]}
            required={false}
          >
            <InputNumber style={{ width: "100%" }} disabled />
          </Form.Item>
          <Form.Item
            name="closeValue"
            label="Value"
            rules={[
              {
                required: true,
              },
            ]}
            required={false}
          >
            <Input prefix="$" disabled />
          </Form.Item>
          <Form.Item
            name="premiumFee"
            label="Commission"
            rules={[
              {
                required: true,
              },
            ]}
            required={false}
          >
            <InputNumber style={{ width: "100%" }} prefix="$" step={0.1} />
          </Form.Item>
          <Form.Item
            name="closeDate"
            label="Close date"
            rules={[
              {
                required: true,
              },
            ]}
            required={false}
          >
            <DatePicker showTime style={{ width: "100%" }} showSecond={false} />
          </Form.Item>
          <Form.Item
            name="closeReason"
            label="Close Reason"
            rules={[
              {
                required: true,
                message: "Close reason is required!",
              },
            ]}
            required={false}
          >
            <Input disabled={activePosition.closeReason} />
          </Form.Item>
          <div>Buy Price: ${activePosition.openRate}</div>
          <div>
            Profit:{" "}
            <span className={returnUpDownClassname(percChange)}>
              {percChange}%
            </span>
          </div>
          <Form.Item shouldUpdate style={{ paddingBlockStart: 10 }}>
            {() => (
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

ConfirmSellPosition.propTypes = {
  activePosition: PropTypes.object,
  getUserStrategies: PropTypes.func,
};

export default ConfirmSellPosition;
