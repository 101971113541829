import React, { useState } from "react";
import PropTypes from "prop-types";

import GenericCard from "components/UI/GenericCard/GenericCard";

import { Column } from "@ant-design/plots";
import { Segmented } from "antd";
import { PercentageOutlined } from "@ant-design/icons";
import { toFixed } from "utils/helpers";

const BacktestPerformance = (props) => {
  const { backtestResults } = props;
  const [groupBy, setGroupBy] = useState("monthlyPnl");
  const [type, setType] = useState("percent");

  const config = {
    data: backtestResults.metrics[groupBy].data,

    padding: "auto",
    xField: "label",
    yField: type,
    label: {
      position: "middle",
      style: {
        fill: "#FFFFFF",
        opacity: 0.6,
      },
    },

    meta: {
      month: {
        alias: "Profit",
      },
      percent: {
        alias: "Profit",
        formatter: (v) => `${v}%`,
      },
    },

    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    slider: {},
    annotations: [
      // {
      //   type: "regionFilter",
      //   start: ["min", "min"],
      //   end: ["max", "0"],
      //   color: "#F4664A",
      // },
      {
        type: "line",
        start: ["min", "mean"],
        end: ["max", "mean"],
        style: {
          stroke: "red",
          lineDash: [2, 2],
        },
      },
    ],
  };

  if (type === "percent") {
    config.annotations.push({
      type: "text",
      position: ["min", "mean"],
      content: `Average: ${toFixed(backtestResults.metrics[groupBy].average)}%`,
      offsetY: -6,
      style: {
        textBaseline: "bottom",
        fill: "#fff",
      },
      background: {
        padding: 4,
        style: {
          radius: 4,
          fill: "rgba(0,0,0,0.6)",
        },
      },
    });
  }

  return (
    <GenericCard
      title="Profit by Timeframe"
      maxHeight={1200}
      isCardOpenByDefault={false}
      className="table-card"
    >
      <div className="pb-3 flex flex-col gap-1">
        <div>
          <Segmented
            options={[
              {
                label: "Monthly Profit",
                value: "monthlyPnl",
              },
              {
                label: "Yearly Profit",
                value: "yearlyPnl",
              },
            ]}
            value={groupBy}
            onChange={setGroupBy}
          />
        </div>
        <div>
          <Segmented
            options={[
              {
                label: "Profit",
                icon: <PercentageOutlined />,
                value: "percent",
              },
              {
                label: "USD",
                value: "value",
              },
            ]}
            value={type}
            onChange={setType}
          />
        </div>
      </div>

      <Column {...config} autoFit={false} className="!h-[420px]" />
    </GenericCard>
  );
};

BacktestPerformance.propTypes = { backtestResults: PropTypes.object };

export default BacktestPerformance;
