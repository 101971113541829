import React, { useCallback, useEffect, useState } from "react";

import GenericCard from "components/UI/GenericCard/GenericCard";
import { Form } from "antd";
import dayjs from "dayjs";
import AntTable from "components/UI/AntTable";
import { apiCheckYFinanceDifference } from "api/apiUtils";
import SearchForm from "./SearchForm";

const YahooFinancePriceCompare = () => {
  const [data, setData] = useState(null);
  const [form] = Form.useForm();
  const sortByNumber = useCallback((a, b) => {
    // handle null / N/A values
    if (a === b) return 0;
    if (a === null || a === "N/A") return -1;
    if (b === null || b === "N/A") return 1;
    return a > b ? 1 : -1;
  }, []);

  const sortByText = useCallback((a, b) => {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  }, []);

  const columns = [
    {
      title: "Symbol",
      dataIndex: ["item", "symbol"],
      sorter: {
        compare: (a, b) => sortByText(a.item.symbol, b.item.symbol),
      },
    },
    {
      title: "YSymbol",
      dataIndex: ["yfinance", "symbol"],
      sorter: {
        compare: (a, b) => sortByText(a.yfinance.symbol, b.yfinance.symbol),
      },
    },
    {
      title: "Close",
      dataIndex: ["item", "close"],
      sorter: {
        compare: (a, b) => sortByNumber(a.item.close, b.item.close),
      },
    },
    {
      title: "YFinance Close",
      dataIndex: ["yfinance", "close"],
      sorter: {
        compare: (a, b) => sortByNumber(a.yfinance.close, b.yfinance.close),
      },
    },
    {
      title: "Close Difference",
      dataIndex: "closeDiff",
      render: (text) => (
        <span className={text === 0 ? "" : "red"}>
          {text === 0 ? "-" : `${text}%`}
        </span>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.closeDiff, b.closeDiff),
      },
    },
    {
      title: "open",
      dataIndex: ["item", "open"],
      sorter: {
        compare: (a, b) => sortByNumber(a.item.open, b.item.open),
      },
    },
    {
      title: "YFinance open",
      dataIndex: ["yfinance", "open"],
      sorter: {
        compare: (a, b) => sortByNumber(a.yfinance.open, b.yfinance.open),
      },
    },
    {
      title: "Open Difference",
      dataIndex: "openDiff",
      render: (text) => (
        <span className={text === 0 ? "" : "red"}>
          {text === 0 ? "-" : `${text}%`}
        </span>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.openDiff, b.openDiff),
      },
    },
    {
      title: "volume",
      dataIndex: ["item", "volume"],
      sorter: {
        compare: (a, b) => sortByNumber(a.item.volume, b.item.volume),
      },
    },
    {
      title: "YFinance volume",
      dataIndex: ["yfinance", "volume"],
      sorter: {
        compare: (a, b) => sortByNumber(a.yfinance.volume, b.yfinance.volume),
      },
    },
    {
      title: "volume Difference",
      dataIndex: "volumeDiff",
      render: (text) => (
        <span className={text === 0 ? "" : "red"}>
          {text === 0 ? "-" : `${text}%`}
        </span>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.volumeDiff, b.volumeDiff),
      },
    },
    {
      title: "Date",
      dataIndex: ["item", "date"],
      render: (text) => <>{dayjs(text).format("DD/MM/YY")}</>,
      sorter: {
        compare: (a, b) => sortByNumber(a.item.date, b.item.date),
      },
    },
    {
      title: "YDate",
      dataIndex: ["yfinance", "date"],
      render: (text) => <>{dayjs(text).format("DD/MM/YY")}</>,
      sorter: {
        compare: (a, b) => sortByNumber(a.yfinance.date, b.yfinance.date),
      },
    },
  ];

  const onFetchData = async ({ symbols, dates, diffTreshold }) => {
    const options = {
      symbols,
      startDate: dates[0].format("YYYY-MM-DD"),
      endDate: dates[1].format("YYYY-MM-DD"),
      diffTreshold,
    };
    const response = await apiCheckYFinanceDifference(options);
    setData(response);
  };

  useEffect(() => {
    onFetchData();
    document.title = "Yahoo Finance Price Compare";
  }, []);

  return (
    <div className="cards-wrapper">
      <SearchForm form={form} onFetchData={onFetchData} />
      <GenericCard
        title={`Predictions Table - ${
          data ? `${data.length} records found` : ""
        }`}
        className="table-card"
        maxHeight={700}
      >
        <AntTable
          rootClassName="yahoo-finance-price-compare-table"
          rowKey="_id"
          dataSource={data}
          columns={columns}
          tableLayout="fixed"
        />
      </GenericCard>
    </div>
  );
};

export default YahooFinancePriceCompare;
