import React, { useState } from "react";
import { Button, Form, Input, Modal, Result } from "antd";
import { Typography } from "antd";
import { apiInviteUser } from "api/apiAdmin";
const { Paragraph } = Typography;

const InviteNewUser = (props) => {
  const [form] = Form.useForm();
  const [isOpen, setIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [invitationLink, setInvitationLink] = useState("");

  const handleInviteUser = async (values) => {
    try {
      const response = await apiInviteUser(values);
      setInvitationLink(response);
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
  };

  return (
    <>
      <Button type="primary" onClick={() => setIsOpen(true)}>
        Invite new user
      </Button>
      <Modal
        title="Invite New User"
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={null}
        width={500}
        destroyOnClose={true}
      >
        {invitationLink ? (
          <Result
            status="success"
            title="User invited successfully!"
            subTitle={
              <div>
                <p>
                  Send the following link to the user to complete the
                  registration process.
                </p>
                <br />
                <Paragraph copyable>{invitationLink}</Paragraph>
              </div>
            }
            extra={[
              <Button type="primary" onClick={() => setIsOpen(false)}>
                Close
              </Button>,
              <Button onClick={() => invitationLink("")}>
                Invite Another User
              </Button>,
            ]}
          />
        ) : (
          <Form
            form={form}
            layout="vertical"
            name="new_user"
            onFinish={handleInviteUser}
            initialValues={{ isDemo: true, enabled: true, automated: false }}
            preserve={false}
          >
            <Form.Item
              name="username"
              label="Email Address"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
            >
              <Input type="email" placeholder="Email Address" />
            </Form.Item>

            {errorMessage && <div className="red">{errorMessage}</div>}

            <Form.Item shouldUpdate className="pt-2">
              {() => (
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={
                    !form.isFieldsTouched(true) ||
                    !!form
                      .getFieldsError()
                      .filter(({ errors }) => errors.length).length
                  }
                >
                  Invite
                </Button>
              )}
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  );
};

export default InviteNewUser;
