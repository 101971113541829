import React, { useRef, useEffect, useState } from "react";
import { widget } from "../../charting_library";
import PropTypes from "prop-types";
import "./index.scss";

const TVChartContainer = (props) => {
  const {
    symbol,
    transactions,
    openPositions,
    visibleRange,
    resetVisibleRange,
  } = props;

  const ref = useRef();
  const [tvWidget, setTvWidget] = useState(null);
  const positions = useRef([]);

  const createExecutionShape = (tvWidgetInstance, order, type, isOpen) => {
    let direction = type;
    let text = "Buy";
    let color = "#00c4ff";
    let time = Math.floor(new Date(order.openDate).setHours(20) / 1000);
    let price = order.openRate;
    let tooltip = "";

    if (type === "sell") {
      text = `Sell: ${order.profit.perc} %`;
      color = order.profit.perc > 0 ? "#0F0" : "#ff5900";
      time = Math.floor(new Date(order.closeDate).setHours(20) / 1000);
      price = order.closeRate;
      tooltip = `Duration: ${order.dealDaysOpen} candles`;
    }
    if (isOpen) {
      text = "Buy *Active";
    }

    // if (type === "max" || type === "min") {
    //   text = type;
    //   color = "#fbff00";
    //   time = Math.floor(+new Date(order.sellOrder[type].date) / 1000);
    //   price = order.sellOrder[type].value;
    //   tooltip = "";
    //   direction = type === "max" ? "sell" : "buy";
    // }

    positions.current.push(
      tvWidgetInstance
        .activeChart()
        .createExecutionShape({ disableUndo: true })
        .setText(text)
        .setTextColor(color)
        .setArrowColor(color)
        .setArrowHeight(15)
        .setArrowSpacing(5)
        .setFont("12px Verdana")
        .setTooltip(tooltip)
        .setDirection(direction)
        .setTime(time)
        .setPrice(price)
    );
  };

  const showTransactionsOnChart = (tvWidgetInstance) => {
    positions?.current?.forEach((item) => {
      item?.remove();
    });
    positions.current = [];
    transactions?.forEach((order) => {
      if (order.symbol !== symbol) return;
      createExecutionShape(tvWidgetInstance, order, "buy");
      if (order.orderStatus === "closed") {
        createExecutionShape(tvWidgetInstance, order, "sell");
      }
    });
    if (openPositions?.length > 0) {
      openPositions.forEach((order) => {
        createExecutionShape(tvWidgetInstance, order, "buy", true);
      });
    }
  };

  useEffect(() => {
    // change visible range
    if (tvWidget && visibleRange?.from) {
      tvWidget
        .activeChart()
        .setVisibleRange({ from: visibleRange.from, to: visibleRange.to });
      resetVisibleRange();
    }
  }, [tvWidget, visibleRange?.from]);

  useEffect(() => {
    let debounce;
    if (tvWidget && symbol) {
      debounce = setTimeout(() => {
        showTransactionsOnChart(tvWidget);
      }, 1000);
    }
    return () => clearTimeout(debounce);
  }, [tvWidget, transactions, symbol]);

  useEffect(() => {
    if (tvWidget && symbol) {
      tvWidget.setSymbol(symbol, tvWidget.symbolInterval().interval);
    }
  }, [tvWidget, symbol]);

  const onChartReady = (tvWidgetInstance) => {
    setTvWidget(tvWidgetInstance);
    // tvWidgetInstance
    //   .chart()
    //   .createStudy("MA Cross", false, false, [50, 200]);
  };

  const onMount = () => {
    const widgetOptions = {
      symbol,
      // BEWARE: no trailing slash is expected in feed URL
      datafeed: new window.Datafeeds.UDFCompatibleDatafeed(
        props.datafeedUrl,
        3000
      ),
      interval: props.interval,
      container: ref.current,
      library_path: props.libraryPath,

      locale: "en",
      // disabled_features: ["use_localstorage_for_settings"],
      enabled_features: ["pre_post_market_sessions"],
      // debug: true,
      client_id: props.clientId,
      user_id: props.userId,
      timezone: props.timezone,
      fullscreen: props.fullscreen,
      autosize: props.autosize,
      theme: props.theme,
      studies_overrides: props.studiesOverrides,
    };

    const tvWidgetInstance = new widget(widgetOptions);
    tvWidgetInstance.onChartReady(() => onChartReady(tvWidgetInstance));
  };

  const onUnmount = () => {
    if (tvWidget !== null) {
      tvWidget.remove();
      setTvWidget(null);
    }
  };
  useEffect(() => {
    onMount();

    return () => {
      onUnmount();
    };
  }, []);

  return <div ref={ref} className="TVChartContainer" />;
};

TVChartContainer.propTypes = {
  symbol: PropTypes.string,
  transactions: PropTypes.array,
  openPositions: PropTypes.array,
};

TVChartContainer.defaultProps = {
  symbol: "SOXL",
  interval: "1D",
  datafeedUrl: "/api/tv_chart",
  libraryPath: "/charting_library/",
  chartsStorageUrl: "https://saveload.tradingview.com",
  chartsStorageApiVersion: "1.1",
  clientId: "tradingview.com",
  userId: "public_user_id",
  timezone: "Asia/Jerusalem",
  fullscreen: false,
  theme: "Dark",
  autosize: true,
  studiesOverrides: {},
};

export default TVChartContainer;
