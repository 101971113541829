import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const ProtectedContent = (props) => {
  const { children, options, adminOnly, requiredPermissions = [] } = props;
  const userRole = useSelector((state) => state.main.user.role);
  const userId = useSelector((state) => state.main.user._id);

  const isRoleAllowed =
    requiredPermissions.some((role) => role === userRole) ||
    userRole === "admin";

  const isUserOwner = requiredPermissions.some(
    (role) => role === "user_owned" && options.ownerId === userId
  );

  if (adminOnly && userId !== "6378d92a05e2fdc826b567a5") {
    return <></>;
  }

  if (isRoleAllowed || isUserOwner) {
    return children;
  }

  return <></>;
};

ProtectedContent.propTypes = {
  redirectPath: PropTypes.string,
  options: PropTypes.object,
  adminOnly: PropTypes.bool,
  requiredPermissions: PropTypes.array,
};

export default ProtectedContent;
