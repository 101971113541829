import React from "react";
import PropTypes from "prop-types";
import GenericCard from "components/UI/GenericCard/GenericCard";
import { returnUpDownClassname } from "utils/helpers";

const RealizedPnl = (props) => {
  const { closedPositions } = props;

  const pnl = closedPositions.reduce(
    (acc, item) => acc + item.netProfit.value,
    0
  );

  return (
    <GenericCard title="Realized PNL" tooltipText="Total closed position PNL">
      <h2 className={returnUpDownClassname(pnl)}>${pnl.toLocaleString()}</h2>
    </GenericCard>
  );
};

RealizedPnl.propTypes = { closedPositions: PropTypes.array };

export default RealizedPnl;
