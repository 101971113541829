import React, { useMemo } from "react";
import PropTypes from "prop-types";
import TotalTransactions from "components/Cards/TotalTransactions";
import BuyAndHoldReturn from "components/Cards/BuyAndHoldReturn";
import TotalPositionsClosedWithProfit from "components/Cards/TotalPositionsClosedWithProfit";
import { calcProfitPerc } from "utils/helpers";
import GenericCard from "components/UI/GenericCard/GenericCard";

const AllResutls = (props) => {
  const { strategyResults } = props;
  const allResults = useMemo(() => {
    {
      const strategies = Object.values(strategyResults);
      const mergedStrategy = strategies.reduce(
        (acc, item) => {
          acc.metrics.totalTransactions += item.metrics.totalTransactions;
          acc.metrics.totalCommissions += item.metrics.totalCommissions;
          acc.metrics.closedWithProfit += item.metrics.closedWithProfit;
          acc.metrics.closedWithNetProfit += item.metrics.closedWithNetProfit;
          // acc.metrics.successRate += item.metrics.successRate;
          acc.initialBalance += item.initialBalance;
          acc.balance += item.balance;
          acc.equity += item.equity;
          acc.metrics.pnl += item.metrics.pnl;
          acc.metrics.grossProfit += item.metrics.grossProfit;
          acc.metrics.grossLoss += item.metrics.grossLoss;
          acc.metrics.buyHoldReturn += item.metrics.buyHoldReturn;
          // acc.metrics.averageProfit += item.metrics.averageProfit;
          // acc.metrics.averageDuration += item.metrics.averageDuration;
          // acc.metrics.highestProfit += item.metrics.highestProfit;
          // acc.metrics.highestLoss += item.metrics.highestLoss;
          acc.transactions.push(...item.transactions);
          acc.predictions.push(...item.predictions);
          return acc;
        },
        {
          // symbol: options.symbol,
          transactions: [],
          predictions: [],
          initialBalance: 0,
          balance: 0,
          equity: 0,
          metrics: {
            successRate: 0,
            totalTransactions: 0,
            totalCommissions: 0,
            closedWithProfit: 0,
            closedWithNetProfit: 0,
            buyHoldReturn: 0,
            pnl: 0,
            grossProfit: 0,
            grossLoss: 0,
            averageProfit: 0,
            averageDuration: 0,
            highestProfit: 0,
            highestLoss: 0,
          },
        }
      );
      mergedStrategy.metrics.pnlPercent = calcProfitPerc(
        mergedStrategy.initialBalance,
        mergedStrategy.equity
      );
      mergedStrategy.metrics.pnl = mergedStrategy?.metrics.pnl.toFixed(2);
      // mergedStrategy.metrics.buyHoldReturn =
      //   (mergedStrategy.metrics.buyHoldReturnProfit /
      //     mergedStrategy.initialBalance) *
      //   100;
      return mergedStrategy;
    }
  }, [strategyResults]);

  return (
    <div>
      {allResults && (
        <div className="cards-wrapper">
          <TotalTransactions backtestResults={allResults} />
          <GenericCard title="Initial balance">
            <div className="total-profit-wrapper">
              <h2 style={{ paddingInlineEnd: 10 }}>
                ${allResults.initialBalance}
              </h2>
            </div>
            <br />
            <h5>Current balance</h5>
            <h2>${allResults.equity.toFixed(2)}</h2>
          </GenericCard>
          <BuyAndHoldReturn backtestResults={allResults} />
          <TotalPositionsClosedWithProfit backtestResults={allResults} />
          <GenericCard title="Total strategies">
            <h2 style={{ paddingInlineEnd: 10 }}>
              {Object.keys(strategyResults).length}
            </h2>
          </GenericCard>
          {/* <AiBacktestTable transactions={allResults.transactions} /> */}

          {/* <AccProfitChart transactions={allResults} /> */}
        </div>
      )}
    </div>
  );
};

AllResutls.propTypes = { strategyResults: PropTypes.any };

export default AllResutls;
