// App
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { paths } from "utils/constants";

// Components
import Home from "./Home/Home";
import SavePredictions from "components/AdminDashboard/SavePredictions/SavePredictions";
import Login from "components/Login/Login";
import ProtectedRoute from "components/ProtectedRoute";
import SymbolsManagement from "components/SymbolsManagement/SymbolsManagement";
import AdminDashboard from "components/AdminDashboard/AdminDashboard";
import ModelCreation from "components/ModelCreation/ModelCreation";
import UsersManagement from "components/AdminDashboard/UsersManagement/UsersManagement";
import SystemControl from "components/AdminDashboard/SystemControl/SystemControl";
import Strategies from "components/Strategies/Strategies";
import OpenPositions from "components/OpenPositions/OpenPositions";
import UserSettings from "components/UserSettings/UserSettings";
import AiMagician from "components/AiMagician/AiMagician";
import TradesHistory from "components/TradesHistory/TradesHistory";
import OrdersStatus from "components/OrdersStatus/OrdersStatus";
import YahooFinancePriceCompare from "components/AdminDashboard/YahooFinancePriceCompare/YahooFinancePriceCompare";
import StrategyPreview from "components/StrategyPreview/StrategyPreview";
import Register from "components/Register/Register";

const RouterConfig = () => {
  return (
    <Routes>
      <Route path={paths.login} element={<Login />} />
      <Route path={paths.register} element={<Register />} />
      <Route
        path={paths.home}
        element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        }
      />

      <Route
        path={paths.ai}
        element={
          <ProtectedRoute>
            <AiMagician />
          </ProtectedRoute>
        }
      />

      <Route
        path={paths.aiNew}
        element={
          <ProtectedRoute>
            <AiMagician />
          </ProtectedRoute>
        }
      />

      <Route
        path={paths.strategies}
        element={
          <ProtectedRoute>
            <Strategies />
          </ProtectedRoute>
        }
      />
      <Route path={paths.strategy} element={<StrategyPreview />} />

      <Route
        path={paths.openPositions}
        element={
          <ProtectedRoute>
            <OpenPositions />
          </ProtectedRoute>
        }
      />

      <Route
        path={paths.tradesHistory}
        element={
          <ProtectedRoute>
            <TradesHistory />
          </ProtectedRoute>
        }
      />

      <Route
        path={paths.ordersStatus}
        element={
          <ProtectedRoute requiredPermissions={["manager"]}>
            <OrdersStatus />
          </ProtectedRoute>
        }
      />

      <Route
        path={paths.createModel}
        element={
          <ProtectedRoute requiredPermissions={["manager"]}>
            <ModelCreation />
          </ProtectedRoute>
        }
      />

      <Route
        path={paths.userSettings}
        element={
          <ProtectedRoute>
            <UserSettings />
          </ProtectedRoute>
        }
      />

      <Route
        path={paths.admin}
        element={
          <ProtectedRoute requiredPermissions={["manager"]}>
            <AdminDashboard />
          </ProtectedRoute>
        }
      >
        <Route path={paths.usersManagement} element={<UsersManagement />} />
        <Route path={paths.systemControl} element={<SystemControl />} />
        <Route path={paths.symbolsManagement} element={<SymbolsManagement />} />
        <Route
          path={paths.yahooFinancePriceCompare}
          element={<YahooFinancePriceCompare />}
        />
        <Route path={paths.savePredictions} element={<SavePredictions />} />
      </Route>

      {/* Redirect to home on unknown paths */}
      <Route path="*" element={<Navigate to={paths.home} />} />
    </Routes>
  );
};

export default RouterConfig;
