import React, { useState } from "react";
import PropTypes from "prop-types";
import HoverableTooltip from "components/UI/Tooltip/HoverableTooltip";
import { UpOutlined, DownOutlined } from "@ant-design/icons";

import "./GenericCard.scss";

const GenericCard = (props) => {
  const {
    children,
    title,
    className = "",
    isCardOpenByDefault = true,
    tooltipText,
    noPaddingTop,
    maxHeight = 600,
    minHeight = 184,
  } = props;
  const [isCardOpen, setCardIsOpen] = useState(isCardOpenByDefault);

  const onToggleCard = () => {
    setCardIsOpen((prevState) => !prevState);
  };

  return (
    <div
      className={`generic-card-wrapper ${className} ${
        isCardOpen ? "" : "card-closed"
      }`}
    >
      {title && (
        <div className="card-header" onClick={onToggleCard}>
          <div className="card-title">
            {tooltipText && <HoverableTooltip text={tooltipText} />}
            <h4>{title}</h4>
          </div>
          {isCardOpen ? <UpOutlined /> : <DownOutlined />}
        </div>
      )}
      {isCardOpen && (
        <div
          className="content"
          style={{
            maxHeight,
            minHeight,
            paddingTop: noPaddingTop ? 0 : undefined,
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
};

GenericCard.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  className: PropTypes.string,
  isCardOpenByDefault: PropTypes.bool,
  tooltipText: PropTypes.string,
  maxHeight: PropTypes.number,
  noPaddingTop: PropTypes.bool,
};

export default GenericCard;
