import React from "react";
import PropTypes from "prop-types";

import GenericCard from "../UI/GenericCard/GenericCard";
import DashedTooltip from "components/UI/DashedTooltip";

const MinMaxPerTransactionCard = (props) => {
  const { backtestResults } = props;

  return (
    <GenericCard title="Min/Max profit per transaction">
      <div className="flex">
        <div className="pe-[30px]">
          <h5>
            <DashedTooltip text="The highest profit % in a single trade">
              Highest profit
            </DashedTooltip>
          </h5>
          <h2> {backtestResults.metrics.highestProfit || 0}%</h2>
        </div>
        <div>
          <h5>
            <DashedTooltip text="The highest loss % in a single trade">
              Highest loss
            </DashedTooltip>
          </h5>
          <h2> {backtestResults.metrics.highestLoss || 0}%</h2>
        </div>
      </div>

      {backtestResults.metrics?.maxDrawdown ? (
        <div className="flex pt-2">
          <div className="pe-[45px]">
            <h5>
              <DashedTooltip
                text={
                  <div>
                    <p>
                      This is worst peak-to-valley drawdown % experienced for
                      this trading strategy.
                    </p>
                  </div>
                }
              >
                Max portfolio Drawdown
              </DashedTooltip>
            </h5>
            <h2>{backtestResults.metrics.maxDrawdown}%</h2>
          </div>
        </div>
      ) : (
        ""
      )}
    </GenericCard>
  );
};
MinMaxPerTransactionCard.propTypes = { backtestResults: PropTypes.object };

export default MinMaxPerTransactionCard;
