import React, { useEffect, useMemo, useState } from "react";
import moment from "moment/moment";
import { Row, Form, Button, Col, Input, Select, List, App, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  apiDisableSymbolById,
  apiEnableSymbolById,
  apiPullDayCandlesFullHistory,
} from "api/apiSymbols";
import { fetchSymbols } from "store/asyncThunk";

import {
  DeleteOutlined,
  ExclamationCircleFilled,
  SearchOutlined,
} from "@ant-design/icons";
import { apiAddNewSymbol, apiDeleteSymbolById } from "api/apiSymbols";

import GenericCard from "components/UI/GenericCard/GenericCard";
import EditSymbol from "./EditSymbol";

import "./SymbolsManagement.scss";

const SymbolsManagement = () => {
  const { modal } = App.useApp();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const sectorsList = useSelector((state) => state.main.sectorsList);
  const allSymbols = useSelector((state) => state.main.allSymbols);
  const enabledSymbolsCount = useSelector(
    (state) => state.main.symbolsList.length
  );

  const [isPending, setIsPending] = useState(false);
  const [isFetchDailyHistoryPending, setIsFetchDailyHistoryPending] =
    useState(false);
  const [isDeleteSymbolPending, setIsDeleteSymbolPending] = useState(false);
  const [symbolToEdit, setSymbolToEdit] = useState(null);
  const [isSymbolEditModalOpen, setIsSymbolEditModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const symbolsFiltered = useMemo(() => {
    return allSymbols.reduce((acc, symbol) => {
      if (
        (symbol?.ticker ?? "")
          .toLowerCase()
          .includes((searchQuery ?? "").toLowerCase()) ||
        (symbol?.name ?? "")
          .toLowerCase()
          .includes((searchQuery ?? "").toLowerCase())
      ) {
        acc.push(symbol);
      }
      return acc;
    }, []);
  }, [allSymbols, searchQuery]);

  const onAddNewSymbol = async (data) => {
    try {
      setIsPending(true);
      await apiAddNewSymbol(data);
      dispatch(fetchSymbols());
      setErrorMessage("");
    } catch (err) {
      setErrorMessage("Error: possible duplicate");
    }
    setIsPending(false);
    form.resetFields();
  };

  const searchSymbol = (query, option) => {
    return (
      (option?.name ?? "")
        .toLowerCase()
        .includes((query ?? "").toLowerCase()) ||
      (option?.value ?? "").toLowerCase().includes((query ?? "").toLowerCase())
    );
  };

  const deleteSymbolById = async (symbolId) => {
    setIsDeleteSymbolPending(true);
    try {
      await apiDeleteSymbolById(symbolId);
      dispatch(fetchSymbols());
    } catch (err) {
      console.error(err);
    }
    setIsDeleteSymbolPending(false);
  };

  const showDeleteConfirm = (symbolId) => {
    modal.confirm({
      title: "Are you sure you want to delete this symbol?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteSymbolById(symbolId);
      },
      onCancel() {},
    });
  };

  const openEditSymbolForm = (symbol) => {
    setSymbolToEdit(symbol);
    setIsSymbolEditModalOpen(true);
  };

  const onToggleSymbolActive = async (symbol) => {
    try {
      if (symbol.enabled) {
        modal.confirm({
          title: "Are you sure you want to disable this symbol?",
          content:
            "This will disable the symbol from being used in our system and delete all its data.",
          icon: <ExclamationCircleFilled />,
          okText: "Yes",
          okType: "danger",
          cancelText: "No",
          async onOk() {
            setIsDeleteSymbolPending(true);
            await apiDisableSymbolById(symbol._id);
            dispatch(fetchSymbols());
            setIsDeleteSymbolPending(false);
          },
          onCancel() {},
        });
      } else {
        modal.confirm({
          title: "Are you sure you want to enable this symbol?",
          content:
            "After the symbol is enabled, historical data needs to be fetched.",
          icon: <ExclamationCircleFilled />,
          okText: "Yes",
          okType: "danger",
          cancelText: "No",
          async onOk() {
            setIsDeleteSymbolPending(true);
            await apiEnableSymbolById(symbol._id);
            dispatch(fetchSymbols());
            setIsDeleteSymbolPending(false);
          },
          onCancel() {},
        });
      }
    } catch (err) {}
  };

  const onPullDataForNewSymbols = async () => {
    setIsFetchDailyHistoryPending(true);
    try {
      await apiPullDayCandlesFullHistory();
      dispatch(fetchSymbols());
    } catch (err) {}
    setIsFetchDailyHistoryPending(false);
  };

  const onSearchSymbol = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    document.title = "Symbol Management | Dindicator Dashboard";
  }, []);

  return (
    <div className="cards-wrapper">
      <GenericCard title="Add new stock" className="table-card">
        <Form
          form={form}
          layout="vertical"
          name="ai"
          style={{
            maxWidth: 800,
          }}
          onFinish={onAddNewSymbol}
        >
          <Row gutter={5} align="bottom">
            <Col xs={24} sm={6}>
              <Form.Item
                name="name"
                label="Name"
                required={false}
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={3}>
              <Form.Item
                name="ticker"
                label="Ticker"
                normalize={(value) => (value || "").toUpperCase()}
                required={false}
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input placeholder="Ticker" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={7}>
              <Form.Item
                name="sector"
                label="Sector"
                required={false}
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={searchSymbol}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "name")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  fieldNames={{ label: "name", value: "_id" }}
                  options={sectorsList}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item shouldUpdate>
                {() => (
                  <Button loading={isPending} type="primary" htmlType="submit">
                    Add
                  </Button>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      </GenericCard>
      <GenericCard className="table-card">
        <Row gutter={8}>
          <Col>
            <Button
              type="primary"
              onClick={onPullDataForNewSymbols}
              loading={isFetchDailyHistoryPending}
            >
              Fetch data for new symbols
            </Button>
          </Col>
        </Row>
        <br />

        <p>Active symbols: {enabledSymbolsCount}</p>
        <p>Total symbols: {allSymbols?.length}</p>

        <br />
        <div>
          <Input
            placeholder="input search text"
            onChange={onSearchSymbol}
            suffix={<SearchOutlined />}
            allowClear
            style={{
              width: 200,
            }}
          />
        </div>
        <br />
        <List
          size="small"
          dataSource={symbolsFiltered}
          bordered
          style={{
            backgroundColor: "#ffffff10",
            borderColor: "#d4d4d457",
          }}
          renderItem={(item) => (
            <List.Item
              actions={[
                item.strategy_count === 0 && (
                  <>
                    {item.enabled === false && (
                      <Button
                        icon={<DeleteOutlined />}
                        onClick={() => showDeleteConfirm(item._id)}
                        ghost
                        danger
                        loading={isDeleteSymbolPending}
                        className="me-2"
                      />
                    )}
                    {item.enabled ? (
                      <Button
                        type="primary"
                        ghost
                        className="min-w-[80px]"
                        onClick={() => onToggleSymbolActive(item)}
                        loading={isDeleteSymbolPending}
                      >
                        Disable
                      </Button>
                    ) : (
                      <Button
                        type="primary"
                        ghost
                        className="min-w-[80px]"
                        onClick={() => onToggleSymbolActive(item)}
                        loading={isDeleteSymbolPending}
                      >
                        Enable
                      </Button>
                    )}
                  </>
                ),

                <Button
                  type="primary"
                  ghost
                  onClick={() => openEditSymbolForm(item)}
                >
                  Edit
                </Button>,
              ]}
            >
              <List.Item.Meta
                title={
                  <p className={item.enabled ? "" : "red line-through"}>
                    {item.ticker} - {item.name}
                  </p>
                }
                description={
                  <>
                    <p>{item.sector.name}</p>
                    <p>
                      <span>
                        {item.hasDailyCandlesData
                          ? `Available Data: ${moment(
                              item.firstDayCandle
                            ).format("DD/MM/YY")} - ${moment(
                              item.lastDayCandle
                            ).format("DD/MM/YY")}`
                          : "No data"}
                      </span>
                      {item.strategy_count > 0 && (
                        <span className="red">
                          {" "}
                          - Used in {item.strategy_count} strategies
                        </span>
                      )}
                      {item.enabled === false && item.disableComment && (
                        <div>
                          <span className="pe-2">Comment: </span>
                          <span className="text-[#ffffff]">
                            {item.disableComment}
                          </span>
                        </div>
                      )}
                    </p>
                  </>
                }
              />
            </List.Item>
          )}
        />
        <Modal
          title="Edit Symbol"
          open={isSymbolEditModalOpen}
          onCancel={() => {
            setIsSymbolEditModalOpen(false);
            setSymbolToEdit(null);
          }}
          footer={null}
        >
          <EditSymbol
            symbolToEdit={symbolToEdit}
            onCloseModal={() => {
              setIsSymbolEditModalOpen(false);
              setSymbolToEdit(null);
            }}
            sectorsList={sectorsList}
          />
        </Modal>
      </GenericCard>
    </div>
  );
};

export default SymbolsManagement;
