import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import GenericCard from "components/UI/GenericCard/GenericCard";
import {
  App,
  Button,
  Col,
  Descriptions,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Segmented,
  Select,
  Switch,
  Tag,
} from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import dayjs from "dayjs";
import {
  apiDeleteStrategy,
  apiRecountStrategyTrend,
  apiToggleStrategyAccountType,
  apiToggleStrategyActive,
  apiUpdateStrategy,
} from "api/apiStrategies";
import CheckableTag from "antd/es/tag/CheckableTag";
import { createURLWithQueryParams } from "utils/helpers";
import { paths } from "utils/constants";
import { useSelector } from "react-redux";
import { apiGetSymbolRecommendations } from "api/apiPredictions";

const StrategyDetails = (props) => {
  const { strategy, getUserStrategies, strategyTabs } = props;
  const { message, modal } = App.useApp();
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedTags, setSelectedTags] = useState(strategy.strategyTabs);
  const [symbolRecommendations, setSymbolRecommendations] = useState([]);
  const [form] = Form.useForm();
  const trailingTakeProfitEnabled = Form.useWatch(
    "trailingTakeProfitEnabled",
    form
  );
  const userCommission = useSelector(
    (state) => state.main.user.preferences.premiumFee
  );

  const createShareUrl = (endDateType) => {
    const params = {
      ...strategy.params,
      symbol: strategy.symbol,
      modelId: strategy.model._id,
      initialBalance: strategy.initialBalance,
      userCommission,
      shared: true,
      startDate: dayjs(strategy.backtestStartDate).format("YYYY-MM-DD"),
    };

    if (endDateType === "today") {
      params.endDate = dayjs().format("YYYY-MM-DD");
    } else if (endDateType === "validate") {
      params.startDate = dayjs(strategy.created).format("YYYY-MM-DD");
      params.endDate = dayjs().format("YYYY-MM-DD");
    } else {
      params.endDate = dayjs(strategy.created).format("YYYY-MM-DD");
    }

    return createURLWithQueryParams(
      `${window.location.origin}${paths.ai}/`,
      params
    );
  };

  const backtestTillTodayUrl = createShareUrl("today");
  const backtestValidateUrl = createShareUrl("validate");
  const backtestTillCreatedUrl = createShareUrl();

  const onToggleStrategyActive = async () => {
    await apiToggleStrategyActive({
      strategyId: strategy._id,
      enabled: !strategy.enabled,
    });
    getUserStrategies();
  };

  const handleChangeStrategyTabs = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);
    setSelectedTags(nextSelectedTags);
  };

  const onToggleStrategyAccountType = async () => {
    await apiToggleStrategyAccountType({
      strategyId: strategy._id,
      isDemo: !strategy.isDemo,
    });
    getUserStrategies();
  };

  const deleteStrategyById = async (strategyId) => {
    try {
      await apiDeleteStrategy(strategyId);

      getUserStrategies();
      message.success("Strategy deleted successfully");
    } catch (err) {
      message.error("Failed to delete strategy");
    }
  };

  const showDeleteConfirm = (strategyId) => {
    modal.confirm({
      title: "Are you sure you want to delete this strategy?",
      content: (
        <div>
          <p>
            This will completely delete the strategy, and all its closed and
            open positions, like it never existed.
          </p>
          <p>You can "disable" the strategy instead.</p>
          <h3 style={{ color: "red", textDecoration: "underline" }}>
            This operation cannot be undone. Would you like to proceed?
          </h3>
        </div>
      ),
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteStrategyById(strategyId);
      },
      onCancel() {},
    });
  };

  const onUpdateStrategy = async (values) => {
    const formData = { ...values, strategyTabs: selectedTags };
    await apiUpdateStrategy(strategy._id, formData);
    await getUserStrategies();
    setIsEditMode(false);
  };

  const returnTrend = () => {
    if (strategy.buyPredictionsInRow) {
      return <span className="green">{strategy.buyPredictionsInRow} Buy</span>;
    } else if (strategy.noBuyPredictionsInRow) {
      return (
        <span className="red">{strategy.noBuyPredictionsInRow} No Buy</span>
      );
    } else {
      return "Unavailable";
    }
  };

  const onRecountTrend = async () => {
    await apiRecountStrategyTrend({ strategyId: strategy._id });
    getUserStrategies();
  };

  const getSymbolRecommendations = async () => {
    const response = await apiGetSymbolRecommendations(strategy.symbol);
    setSymbolRecommendations(response);
  };

  useEffect(() => {
    getSymbolRecommendations();
  }, []);

  const fields = [
    {
      label: "Name",
      value: isEditMode ? (
        <Form.Item name="name" noStyle>
          <Input
            size="small"
            min={0}
            style={{ width: "100%", marginInlineEnd: 20 }}
          />
        </Form.Item>
      ) : (
        strategy.name
      ),
    },
    {
      label: "Symbol",
      value: strategy.symbol,
    },
    {
      label: "Model",
      value: strategy.model.name,
    },
    {
      label: "Enabled",
      value: strategy.enabled ? (
        <CheckOutlined style={{ fontSize: 22, color: "#00c900" }} />
      ) : (
        <CloseOutlined style={{ fontSize: 22, color: "#ff0000" }} />
      ),
    },
    {
      label: "Trend",
      value: returnTrend(),
    },
    {
      label: "Initial Balance",
      value: `$${strategy.initialBalance.toLocaleString()}`,
    },
    {
      label: "Balance",
      value: `$${strategy.balance.toLocaleString()}`,
    },
    {
      label: "Equity",
      value: `$${strategy.equity.toLocaleString()}`,
    },
    {
      label: "Order Size",
      value: isEditMode ? (
        <Form.Item name="orderSize" noStyle>
          <InputNumber
            size="small"
            addonAfter={
              <Form.Item noStyle name="orderSizeType">
                <Select
                  style={{
                    width: 60,
                  }}
                >
                  <Select.Option value="amount">$</Select.Option>
                  <Select.Option value="percent">%</Select.Option>
                </Select>
              </Form.Item>
            }
            min={0}
            style={{ width: "100%", marginInlineEnd: 20 }}
          />
        </Form.Item>
      ) : strategy.params.orderSizeType === "percent" ? (
        `${strategy.params.orderSize}%`
      ) : (
        `$${strategy.params.orderSize}`
      ),
    },
    {
      label: "Created",
      value: dayjs(strategy.created).format("HH:mm DD/MM/YY"),
    },
    {
      label: "Automated Trading",
      // hide: !strategy.isDemo,
      value: isEditMode ? (
        <Form.Item
          initialValue={strategy.automated || false}
          valuePropName="checked"
          name="automated"
          noStyle
        >
          <Switch />
        </Form.Item>
      ) : (
        <>
          {strategy.automated ? (
            <CheckOutlined style={{ fontSize: 22, color: "#00c900" }} />
          ) : (
            <CloseOutlined style={{ fontSize: 22, color: "#ff0000" }} />
          )}
        </>
      ),
    },
    {
      label: "AI Recommendations",
      value: `Buy: ${symbolRecommendations.buy} - No buy: ${symbolRecommendations.noBuy}`,
    },
  ];

  return (
    <GenericCard className="table-card" maxHeight={2000}>
      <Form
        form={form}
        layout="vertical"
        name="edit-strategy"
        onFinish={onUpdateStrategy}
        initialValues={{
          name: strategy.name,
          orderSize: strategy.params.orderSize,
          orderSizeType: strategy.params.orderSizeType,
          takeProfitPercent: strategy.params.takeProfitPercent,
          stopLossPercent: strategy.params.stopLossPercent,
          enterPositionBuyCount: strategy.params.enterPositionBuyCount,
          exitPositionNoBuyCount: strategy.params.exitPositionNoBuyCount,
          daysOpenExitCount: strategy.params.daysOpenExitCount,
        }}
      >
        <Descriptions
          title="Strategy Details"
          column={{
            xxl: 4,
            xl: 4,
            lg: 3,
            md: 3,
            sm: 2,
            xs: 1,
          }}
          extra={
            isEditMode ? (
              <div style={{ display: "flex" }}>
                <Button
                  onClick={() => {
                    setIsEditMode(false);
                    form.resetFields();
                  }}
                  style={{ marginInlineEnd: 10 }}
                >
                  Cancel
                </Button>
                <Form.Item shouldUpdate noStyle>
                  {() => (
                    <Button type="primary" htmlType="submit">
                      Update
                    </Button>
                  )}
                </Form.Item>
              </div>
            ) : (
              <Button type="primary" onClick={() => setIsEditMode(true)}>
                Edit
              </Button>
            )
          }
        >
          {fields.map((field) => {
            // if (field.hide) return null;
            return (
              <Descriptions.Item
                key={field.label}
                label={field.label}
                span={field.span}
              >
                {field.value}
              </Descriptions.Item>
            );
          })}
        </Descriptions>

        <Row gutter={32}>
          <Col xs={24} lg={4}>
            <Divider orientation="left">Deal Enter rules</Divider>
            <Row gutter={8} align="bottom">
              <Col>
                <Form.Item
                  label="Buy predict count"
                  name="enterPositionBuyCount"
                >
                  {isEditMode ? (
                    <InputNumber
                      size="small"
                      min={0}
                      style={{ width: "100%", marginInlineEnd: 20 }}
                    />
                  ) : (
                    strategy.params.enterPositionBuyCount
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col xs={24} lg={20}>
            <Divider orientation="left">Deal close rules</Divider>
            <div className="flex gap-5 flex-wrap sm:flex-nowrap">
              <Form.Item
                className="w-full"
                label="Take Profit"
                name="takeProfitPercent"
              >
                {isEditMode ? (
                  <InputNumber
                    size="small"
                    min={0}
                    prefix="%"
                    style={{ width: "100%", marginInlineEnd: 20 }}
                  />
                ) : (
                  <div className="w-full me-[20px]">
                    {strategy.params.takeProfitPercent}%
                  </div>
                )}
              </Form.Item>
              <Form.Item
                className="w-full"
                label="Stop Loss"
                name="stopLossPercent"
              >
                {isEditMode ? (
                  <InputNumber
                    size="small"
                    max={0}
                    prefix="%"
                    style={{ width: "100%", marginInlineEnd: 20 }}
                  />
                ) : (
                  <div className="w-full me-[20px]">
                    {strategy.params.stopLossPercent}%
                  </div>
                )}
              </Form.Item>
              <Form.Item
                className="w-full"
                label="No buy count"
                name="exitPositionNoBuyCount"
              >
                {isEditMode ? (
                  <InputNumber
                    size="small"
                    min={0}
                    style={{ width: "100%", marginInlineEnd: 20 }}
                  />
                ) : (
                  <div className="w-full me-[20px]">
                    {strategy.params.exitPositionNoBuyCount}
                  </div>
                )}
              </Form.Item>
              <Form.Item
                className="w-full"
                label="Days Open Count"
                name="daysOpenExitCount"
              >
                {isEditMode ? (
                  <InputNumber
                    size="small"
                    min={0}
                    style={{ width: "100%", marginInlineEnd: 20 }}
                  />
                ) : (
                  <div className="w-full me-[20px]">
                    {strategy.params.daysOpenExitCount}
                  </div>
                )}
              </Form.Item>
            </div>
          </Col>
        </Row>

        {strategy.automated && (
          <>
            <Divider orientation="left">Automated trading settings</Divider>
            <Row gutter={32} align="bottom">
              <Col>
                <Form.Item
                  label="Max % difference from buy point"
                  name="buyMaxPercentDiff"
                  initialValue={
                    strategy?.automationSettings?.buyMaxPercentDiff || 0.5
                  }
                >
                  {isEditMode ? (
                    <InputNumber
                      size="small"
                      min={0}
                      style={{ width: "100%", marginInlineEnd: 20 }}
                      prefix="%"
                    />
                  ) : (
                    <span>
                      {strategy?.automationSettings?.buyMaxPercentDiff || 0.5}%
                    </span>
                  )}
                </Form.Item>
              </Col>

              <Col>
                <Form.Item
                  label="Price update interval"
                  name="orderUpdateInterval"
                  initialValue={
                    strategy?.automationSettings?.orderUpdateInterval || 15
                  }
                >
                  {isEditMode ? (
                    <InputNumber
                      size="small"
                      min={0}
                      style={{ width: "100%", marginInlineEnd: 20 }}
                      addonAfter="Seconds"
                    />
                  ) : (
                    <span>
                      {strategy?.automationSettings?.orderUpdateInterval || 15}s
                    </span>
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Divider orientation="left">Trailing Take Profit</Divider>
            <div className="flex items-center pb-4">
              <span className="pe-2">Enabled: </span>
              {isEditMode ? (
                <Form.Item
                  initialValue={
                    strategy?.automationSettings?.trailingTakeProfit?.enabled ||
                    false
                  }
                  valuePropName="checked"
                  name="trailingTakeProfitEnabled"
                  noStyle
                >
                  <Switch />
                </Form.Item>
              ) : strategy?.automationSettings?.trailingTakeProfit?.enabled ? (
                <CheckOutlined style={{ fontSize: 22, color: "#00c900" }} />
              ) : (
                <CloseOutlined style={{ fontSize: 22, color: "#ff0000" }} />
              )}
            </div>
            {(strategy?.automationSettings?.trailingTakeProfit?.enabled ||
              (isEditMode && trailingTakeProfitEnabled)) && (
              <Row gutter={32} align="bottom">
                <Col>
                  <Form.Item
                    label="Activation Threshold %"
                    name="trailingTakeProfitActivationThreshold"
                    initialValue={
                      strategy?.automationSettings?.trailingTakeProfit
                        ?.activationThreshold || 6.5
                    }
                  >
                    {isEditMode ? (
                      <InputNumber
                        size="small"
                        min={0}
                        style={{ width: "100%", marginInlineEnd: 20 }}
                        prefix="%"
                      />
                    ) : (
                      <span>
                        {
                          strategy?.automationSettings?.trailingTakeProfit
                            ?.activationThreshold
                        }
                        %
                      </span>
                    )}
                  </Form.Item>
                </Col>

                <Col>
                  <Form.Item
                    label="Decline from peak limit"
                    name="trailingTakeProfitDeclineFromPeakLimit"
                    initialValue={
                      strategy?.automationSettings?.trailingTakeProfit
                        ?.declineFromPeakLimit || 1
                    }
                  >
                    {isEditMode ? (
                      <InputNumber
                        size="small"
                        max={0}
                        style={{ width: "100%", marginInlineEnd: 20 }}
                        prefix="%"
                      />
                    ) : (
                      <span>
                        {
                          strategy?.automationSettings?.trailingTakeProfit
                            ?.declineFromPeakLimit
                        }
                        %
                      </span>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            )}
          </>
        )}
      </Form>
      <Divider orientation="left">Tabs</Divider>
      {isEditMode ? (
        <Row>
          {strategyTabs.map((tag) => {
            if (tag.key === "all") return null;
            return (
              <CheckableTag
                key={tag.key}
                checked={selectedTags.includes(tag.key)}
                onChange={(checked) =>
                  handleChangeStrategyTabs(tag.key, checked)
                }
              >
                {tag.label}
              </CheckableTag>
            );
          })}
        </Row>
      ) : (
        <Row>
          {strategyTabs.length > 0 && strategy.strategyTabs.length > 0 ? (
            strategyTabs.map((tab) => {
              if (strategy.strategyTabs.includes(tab.key)) {
                return <Tag>{tab.label}</Tag>;
              }
              return null;
            })
          ) : (
            <p>No tabs</p>
          )}
        </Row>
      )}

      <Divider orientation="left">Account Type</Divider>
      <Segmented
        options={[
          { label: "Demo Account", value: "demo" },
          { label: "Live Account", value: "real" },
        ]}
        value={strategy.isDemo ? "demo" : "real"}
        onChange={onToggleStrategyAccountType}
      />
      <Divider orientation="left">Actions</Divider>
      <Row gutter={8}>
        <Col>
          <Button
            type="primary"
            danger={strategy.enabled}
            onClick={onToggleStrategyActive}
          >
            {strategy.enabled ? "Disable Strategy" : "Enable Strategy"}
          </Button>
        </Col>
        <Col>
          <Button
            icon={<DeleteOutlined />}
            onClick={() => showDeleteConfirm(strategy._id)}
            danger
            ghost
          >
            Delete Strategy
          </Button>
        </Col>
        {strategy.openPositions.length === 0 &&
          strategy.pendingBuys.length === 0 && (
            <Col>
              <Button onClick={onRecountTrend}>Recount trend</Button>
            </Col>
          )}
      </Row>

      <Divider orientation="left">Backtest</Divider>
      <Row gutter={8}>
        <Col>
          <Button href={backtestTillCreatedUrl} target="_blank">
            Until Created
          </Button>
        </Col>
        <Col>
          <Button href={backtestValidateUrl} target="_blank">
            Strategy Duration
          </Button>
        </Col>
        <Col>
          <Button href={backtestTillTodayUrl} target="_blank">
            Until Today
          </Button>
        </Col>
      </Row>
    </GenericCard>
  );
};

StrategyDetails.propTypes = {
  strategy: PropTypes.object,
  getUserStrategies: PropTypes.func,
  strategyTabs: PropTypes.array,
};

export default StrategyDetails;
