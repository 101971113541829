import React from "react";
import PropTypes from "prop-types";
import GenericCard from "../UI/GenericCard/GenericCard";

const AverageProfitPerTransaction = (props) => {
  const { backtestResults } = props;

  return (
    <GenericCard title="Average profit per transaction">
      <h5>All transactions</h5>
      <h3>{backtestResults.metrics.averageProfit || 0}%</h3>
      <div className="flex">
        <div className="pe-[30px]">
          <h5>On success</h5>
          <h3>{backtestResults.metrics.avgProfit || 0}%</h3>
        </div>
        <div>
          <h5>On failure</h5>
          <h3>{backtestResults.metrics.avgLoss || 0}%</h3>
        </div>
      </div>
    </GenericCard>
  );
};

AverageProfitPerTransaction.propTypes = { backtestResults: PropTypes.object };

export default AverageProfitPerTransaction;
