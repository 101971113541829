import React from "react";
import PropTypes from "prop-types";

import GenericCard from "components/UI/GenericCard/GenericCard";

import { Line } from "@ant-design/plots";
import { toFixed } from "utils/helpers";

const ProfitHistoryChart = (props) => {
  const { transactions } = props;
  // add every transaction profit to total and save date and total to data array, use reduce for it
  const data = transactions
    .slice()
    .reverse()
    .reduce((acc, transaction) => {
      const { closeDate, netProfit } = transaction;
      const accProfit = acc.length ? acc[acc.length - 1].accProfit : 0;
      const total = accProfit + netProfit.value;
      return [...acc, { closeDate, accProfit: toFixed(total) }];
    }, []);

  const config = {
    data,
    padding: "auto",
    xField: "closeDate",
    yField: "accProfit",
    slider: {},
    xAxis: {
      type: "timeCat",
      tickCount: 5,
    },
  };
  return (
    <GenericCard
      title="Profit over time"
      maxHeight={600}
      className="table-card"
    >
      <Line {...config} height={400} autoFit={false} />
    </GenericCard>
  );
};

ProfitHistoryChart.propTypes = { transactions: PropTypes.object };

export default ProfitHistoryChart;
