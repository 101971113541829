import React, { useState } from "react";
import PropTypes from "prop-types";
import { App, Button } from "antd";

const PredictButton = (props) => {
  const { model, handleCheckAiSymbol, isDisabled } = props;
  const { message } = App.useApp();

  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(false);

  const onSuccess = () => {
    setIsPending(false);
    message.success(model.name, 1.3);
  };

  const onFailure = () => {
    setIsPending(false);
    setError(true);
    message.error(model.name, 5);
  };

  const onHandlePredict = () => {
    setError(false);
    setIsPending(true);
    handleCheckAiSymbol(model, onSuccess, onFailure);
  };

  return (
    <Button
      type="primary"
      onClick={onHandlePredict}
      disabled={isPending || isDisabled}
      danger={error}
    >
      {isPending ? "Loading" : "Predict"}
    </Button>
  );
};

PredictButton.propTypes = {
  model: PropTypes.object,
  handleCheckAiSymbol: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default PredictButton;
