import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "antd";

const DashedTooltip = (props) => {
  const { children, text } = props;
  return (
    <Tooltip title={text} color="black">
      <span className="border-b-[1px] border-t-0 border-s-0 border-e-0 border-dashed cursor-help pb-[2px]">
        {children}
      </span>
    </Tooltip>
  );
};

DashedTooltip.propTypes = {
  children: PropTypes.node,
  text: PropTypes.any,
};

export default DashedTooltip;
