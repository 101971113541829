import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GenericCard from "components/UI/GenericCard/GenericCard";
import { Button, Form, InputNumber } from "antd";
import { updateUserPremiumFee } from "store/asyncThunk";

const ChangePremiumFee = () => {
  const dispatch = useDispatch();
  const userPremiumFee = useSelector(
    (state) => state.main.user.preferences.premiumFee
  );
  const [submittable, setSubmittable] = useState(false);
  const [form] = Form.useForm();

  const handleChangeUserPremiumFee = async (values) => {
    try {
      await dispatch(updateUserPremiumFee(values)).unwrap();
      setSubmittable(false);
    } catch (err) {}
  };

  return (
    <GenericCard
      title="Change Premium Fee"
      tooltipText="Set your default Interactive Brokers Premium fee (buy/sell commissions), and it will set your commission in every Buy / Sell form automatically"
    >
      <div style={{ paddingBlockEnd: 10 }}>
        Your default premium fee: ${userPremiumFee}
      </div>
      <Form
        form={form}
        layout="vertical"
        name="change-premium"
        initialValues={{
          premiumFee: userPremiumFee,
        }}
        onValuesChange={(values) => {
          if (values.premiumFee === userPremiumFee) {
            setSubmittable(false);
          } else {
            setSubmittable(true);
          }
        }}
        onFinish={handleChangeUserPremiumFee}
      >
        <Form.Item name="premiumFee" label="Default Premium Fee">
          <InputNumber prefix="$" step={0.1} />
        </Form.Item>
        <Form.Item shouldUpdate>
          {() => (
            <Button type="primary" htmlType="submit" disabled={!submittable}>
              Update
            </Button>
          )}
        </Form.Item>
      </Form>
    </GenericCard>
  );
};

export default ChangePremiumFee;
