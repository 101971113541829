import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import socket from "utils/socket";
import { apiGetOnlineUsers } from "api/apiUser";

import ProtectedContent from "components/UI/ProtectedContent";
import ProfileAvatar from "components/UI/ProfileAvatar";
import GenericCard from "components/UI/GenericCard/GenericCard";

const OnlineUsers = (props) => {
  const {
    title = "Online money makers",
    selectedUserId,
    handleSelectUser,
  } = props;
  const [onlineUsers, setOnlineUsers] = useState([]);

  const getOnlineUsers = async () => {
    const online = await apiGetOnlineUsers();
    setOnlineUsers(online);
  };

  useEffect(() => {
    socket.on("refresh_online_users", (data) => {
      try {
        const updatedOnlineUsers = JSON.parse(data);
        setOnlineUsers(updatedOnlineUsers);
      } catch (err) {}
    });

    return () => {
      // unsubscribe on unmount
      socket.off("refresh_online_users");
    };
  }, [selectedUserId]);

  useEffect(() => {
    getOnlineUsers();
  }, []);

  return (
    <ProtectedContent requiredPermissions={["manager"]}>
      <GenericCard title={title} minHeight={85}>
        <div>
          {onlineUsers.length ? (
            onlineUsers.map((user) => (
              <ProfileAvatar
                key={user._id}
                user={user}
                count={user?.sessions?.length}
                handleSelectUser={handleSelectUser}
              />
            ))
          ) : (
            <ProfileAvatar />
          )}
        </div>
      </GenericCard>
    </ProtectedContent>
  );
};

OnlineUsers.propTypes = {
  title: PropTypes.string,
  selectedUserId: PropTypes.any,
  handleSelectUser: PropTypes.func,
};

export default OnlineUsers;
