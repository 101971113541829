import React, { useEffect, useMemo, useRef } from "react";

import { Pie, G2 } from "@ant-design/plots";
import GenericCard from "components/UI/GenericCard/GenericCard";
const noneSelected = [{ type: "None selected", value: 0 }];

export const SelectedSectorsChart = (props) => {
  const { data = noneSelected } = props;
  const chartRef = useRef(null);
  const G = G2.getEngine("canvas");

  const config = useMemo(() => {
    return {
      appendPadding: 10,
      data: noneSelected,
      angleField: "value",
      colorField: "type",
      radius: 0.9,
      legend: false,
      label: {
        type: "spider",
        labelHeight: 40,
        formatter: (data, mappingData) => {
          const group = new G.Group({});
          group.addShape({
            type: "circle",
            attrs: {
              x: 0,
              y: 0,
              width: 40,
              height: 50,
              r: 5,
              fill: mappingData.color,
            },
          });
          group.addShape({
            type: "text",
            attrs: {
              x: 10,
              y: 8,
              text: `${data.type}`,
              fill: mappingData.color,
              fontSize: 12,
            },
          });
          group.addShape({
            type: "text",
            attrs: {
              x: 0,
              y: 25,
              text: `${data.value}% (${data.amount})`,
              fill: "rgba(250, 250, 250)",
              fontWeight: 700,
            },
          });
          return group;
        },
      },
      height: 230,
      interactions: [
        {
          type: "element-active",
        },
      ],
    };
  }, []);

  useEffect(() => {
    // update data
    if (chartRef.current && chartRef.current.getChart()) {
      chartRef.current.getChart().changeData(data);
    }
  }, [data]);

  return (
    <GenericCard title="Selected Sectors">
      <Pie ref={chartRef} {...config} autoFit={false} height={320} />
    </GenericCard>
  );
};

export default React.memo(SelectedSectorsChart);
