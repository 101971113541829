import React, { useCallback } from "react";
import PropTypes from "prop-types";

import AntTable from "components/UI/AntTable";
import dayjs from "dayjs";
import {
  formatMomentDuration,
  returnUpDownClassname,
  toFixed,
} from "utils/helpers";
import ConfirmSellPosition from "../Modals/ConfirmSellPosition";

const StrategyOpenOrders = (props) => {
  const { openOrders, profitTargetPercent, getUserStrategies } = props;

  const sortByNumber = useCallback((a, b) => {
    // handle null / N/A values
    if (a === b) return 0;
    if (a === null || a === "N/A") return -1;
    if (b === null || b === "N/A") return 1;
    return a > b ? 1 : -1;
  }, []);

  const sortByText = useCallback((a, b) => {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  }, []);

  const columns = [
    {
      title: "Symbol",
      dataIndex: "symbol",
    },
    {
      title: "Contracts",
      dataIndex: "amount",
      width: 100,
      sorter: {
        compare: (a, b) => sortByNumber(a.amount, b.amount),
      },
    },
    {
      title: "Buy Point",
      dataIndex: "advicedBuyPrice",
      render: (text, properties) => <div>{text || "N/A"}</div>,
      sorter: {
        compare: (a, b) => sortByNumber(a.advicedBuyPrice, b.advicedBuyPrice),
      },
    },
    {
      title: "Target",
      dataIndex: "advicedBuyPrice",
      render: (text, properties) => (
        <div>{toFixed(text * (profitTargetPercent / 100 + 1))}</div>
      ),
    },
    {
      title: "Buy Price",
      dataIndex: "openRate",
      render: (text, properties) => (
        <div>
          <div>{text}</div>
          <div>
            <span style={{ fontSize: 12 }}>
              ${properties.openValue.toLocaleString()}
            </span>
          </div>
        </div>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.openRate, b.openRate),
      },
    },
    {
      title: "Current",
      dataIndex: "currentRate",
      render: (text, properties) => (
        <div>
          <div>{text || properties.openRate}</div>
          <div>
            <span style={{ fontSize: 12 }}>
              $
              {text
                ? (text * properties.amount).toLocaleString()
                : (properties.openRate * properties.amount).toLocaleString()}
            </span>
          </div>
        </div>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.currentRate, b.currentRate),
      },
    },
    {
      title: "Profit",
      dataIndex: "profit.value",
      render: (text, properties) => (
        <span className={returnUpDownClassname(properties.profit.value)}>
          ${properties.profit.value.toLocaleString()}
        </span>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.profit.value, b.profit.value),
      },
    },
    {
      title: "Change",
      dataIndex: "profit.perc",
      render: (text, properties) => (
        <span className={returnUpDownClassname(properties.profit.perc)}>
          {properties.profit.perc}%
        </span>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.profit.perc, b.profit.perc),
      },
    },
    {
      title: "Run-up",
      dataIndex: "runup.perc",
      render: (text, properties) => (
        <div
          className={returnUpDownClassname(properties.runup.value)}
          title={`Rate: ${properties.runup.rate}`}
        >
          <div>${properties.runup.value.toLocaleString()}</div>
          <span style={{ fontSize: 12 }}>%{properties.runup.perc}</span>
        </div>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.runup.value, b.runup.value),
      },
    },
    {
      title: "Drawdown",
      dataIndex: "drawdown.perc",
      render: (text, properties) => (
        <div
          className={returnUpDownClassname(properties.drawdown.value)}
          title={`Rate: ${properties.drawdown.rate}`}
        >
          <div>${properties.drawdown.value.toLocaleString()}</div>
          <span style={{ fontSize: 12 }}>%{properties.drawdown.perc}</span>
        </div>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.drawdown.value, b.drawdown.value),
      },
    },
    {
      title: "Days",
      dataIndex: "dealDaysOpen",
      sorter: {
        compare: (a, b) => sortByNumber(a.dealDaysOpen, b.dealDaysOpen),
      },
    },
    {
      title: "Duration",
      dataIndex: "openDate",
      render: (text) => (
        <span>{formatMomentDuration(+new Date() - +new Date(text))}</span>
      ),
      sorter: {
        compare: (a, b) => sortByText(a.openDate, b.openDate),
      },
    },
    {
      title: "Open Date",
      dataIndex: "openDate",
      render: (text) => <>{dayjs(text).format("HH:mm:ss - DD/MM/YY")}</>,
      sorter: {
        compare: (a, b) => sortByNumber(a.openDate, b.openDate),
      },
    },
  ];

  if (getUserStrategies) {
    columns.push({
      title: "Sell",
      key: "uid",
      dataIndex: "",
      render: (text) => (
        <ConfirmSellPosition
          activePosition={text}
          getUserStrategies={getUserStrategies}
        />
      ),
    });
  }

  return (
    <AntTable rowKey="_id" dataSource={openOrders} columns={columns} bordered />
  );
};
StrategyOpenOrders.propTypes = {
  openOrders: PropTypes.object,
  getUserStrategies: PropTypes.func,
};

export default StrategyOpenOrders;
