import React from "react";
import PropTypes from "prop-types";
import { Table } from "antd";
import { useVT } from "virtualizedtableforantd4";

const AntTable = (props) => {
  const { expandable } = props;
  const [vt, set_components] = useVT(() => ({ scroll: { y: 500 } }), []);

  return (
    <Table
      scroll={{
        y: 500,
        // x: "max-content",
      }}
      tableLayout="auto"
      components={vt}
      pagination={false}
      sortOrder="descend"
      expandable={expandable}
      size="middle"
      {...props}
    />
  );
};

AntTable.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  className: PropTypes.string,
  rowKey: PropTypes.string.isRequired,
  expandable: PropTypes.object,
};

export default AntTable;
