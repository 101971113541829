import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Col, Form, Input, Modal, Row } from "antd";
import {
  apiDeleteStrategyTab,
  apiUpdateStrategyTab,
} from "api/apiStrategyTabs";

const EditStrategyTab = (props) => {
  const { getUserStrategyTabs, selectedStrategyTab } = props;
  const [form] = Form.useForm();
  const [isOpen, setIsOpen] = useState(false);
  const [isUpdatePending, setIsUpdatePending] = useState(false);
  const [isDeletePending, setIsDeletePending] = useState(false);

  const handleUpdateStrategyTab = async (values) => {
    setIsUpdatePending(true);
    try {
      await apiUpdateStrategyTab(selectedStrategyTab.key, values);
      await getUserStrategyTabs();
      setIsOpen(false);
    } catch (err) {
      console.error(err);
    }
    setIsUpdatePending(false);
  };

  const handleDeleteStrategyTab = async () => {
    setIsDeletePending(true);
    try {
      await apiDeleteStrategyTab(selectedStrategyTab.key);
      await getUserStrategyTabs("all");
      setIsOpen(false);
    } catch (err) {
      console.error(err);
    }
    setIsDeletePending(false);
  };

  useEffect(() => {
    form.setFieldsValue({
      name: selectedStrategyTab?.label,
      description: selectedStrategyTab?.description,
    });
  }, [selectedStrategyTab, isOpen]);

  return (
    <>
      <Button type="default" onClick={() => setIsOpen(true)}>
        Edit Tab
      </Button>
      <Modal
        title="Edit tab"
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={null}
        width={800}
        destroyOnClose={true}
      >
        <Form
          form={form}
          layout="vertical"
          name="new_strategy_tab"
          onFinish={handleUpdateStrategyTab}
          initialValues={{
            name: selectedStrategyTab?.label,
            description: selectedStrategyTab?.description,
          }}
          preserve={false}
        >
          <Form.Item
            name="name"
            label="Tab Name"
            rules={[
              {
                required: true,
                message: "Tab name is required",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="description" label="Description">
            <Input.TextArea />
          </Form.Item>
          <Row gutter={8} align="middle" justify="space-between">
            <Col>
              <Form.Item shouldUpdate style={{ paddingBlockStart: 10 }}>
                {() => (
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={isUpdatePending}
                  >
                    Update
                  </Button>
                )}
              </Form.Item>
            </Col>
            <Col>
              <Button
                type="primary"
                danger
                onClick={handleDeleteStrategyTab}
                loading={isDeletePending}
              >
                Delete Tab
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

EditStrategyTab.propTypes = {
  getUserStrategyTabs: PropTypes.func,
  selectedStrategyTab: PropTypes.object,
};

export default EditStrategyTab;
