import axios from "utils/axios";
import { routes } from "api/routes";

export const apiGetAllSymbols = async () => {
  const { data } = await axios.get(routes.getAllSymbols);
  return data;
};

export const apiAddNewSymbol = async (symbol) => {
  const { data } = await axios.post(routes.addNewSymbol, symbol);
  return data;
};

export const apiDeleteSymbolById = async (symbolId) => {
  const { data } = await axios.delete(routes.deleteSymbolById, {
    params: { symbolId },
  });
  return data;
};

export const apiUpdateSymbolById = async (symbolId, updatedData) => {
  const { data } = await axios.put(routes.updateSymbolById, updatedData, {
    params: { symbolId },
  });
  return data;
};

export const apiEnableSymbolById = async (symbolId) => {
  const { data } = await axios.get(routes.enableSymbolById, {
    params: { symbolId },
  });
  return data;
};

export const apiDisableSymbolById = async (symbolId) => {
  const { data } = await axios.get(routes.disableSymbolById, {
    params: { symbolId },
  });
  return data;
};

export const apiPullDayCandlesFullHistory = async () => {
  const { data } = await axios.get(routes.pullDayCandlesFullHistory);
  return data;
};
