import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Form, Input, Modal } from "antd";
import { apiCreateNewStrategyTab } from "api/apiStrategyTabs";

const CreateStrategyTab = (props) => {
  const { getUserStrategyTabs } = props;
  const [form] = Form.useForm();
  const [isOpen, setIsOpen] = useState(false);
  const [isPending, setIsPending] = useState(false);

  const handleCreateNewStrategyTab = async (values) => {
    setIsPending(true);
    try {
      await apiCreateNewStrategyTab(values);
      await getUserStrategyTabs();
      setIsOpen(false);
    } catch (err) {
      console.log(err);
    }
    setIsPending(false);
  };

  return (
    <>
      <Button type="primary" onClick={() => setIsOpen(true)}>
        New Tab
      </Button>
      <Modal
        title="New tab"
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={null}
        width={800}
        destroyOnClose={true}
      >
        <Form
          form={form}
          layout="vertical"
          name="new_strategy_tab"
          onFinish={handleCreateNewStrategyTab}
          preserve={false}
        >
          <Form.Item
            name="name"
            label="Tab Name"
            rules={[
              {
                required: true,
                message: "Tab name is required",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="description" label="Description">
            <Input.TextArea />
          </Form.Item>

          <Form.Item shouldUpdate style={{ paddingBlockStart: 10 }}>
            {() => (
              <Button type="primary" htmlType="submit" loading={isPending}>
                Create
              </Button>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

CreateStrategyTab.propTypes = {
  getUserStrategyTabs: PropTypes.func,
};

export default CreateStrategyTab;
