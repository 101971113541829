import { useState, useRef } from "react";

const useTimer = () => {
  const [time, setTime] = useState(null);
  const intervalRef = useRef();

  const startTimer = () => {
    setTime(0);
    intervalRef.current = setInterval(() => {
      setTime((prevTime) => prevTime + 1);
    }, 1000);
  };

  const stopTimer = () => {
    clearInterval(intervalRef.current);
  };

  return { startTimer, stopTimer, time };
};

export default useTimer;
