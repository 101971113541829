import React, { useEffect, useMemo, useRef, useState } from "react";
import { apiCreateNewModel } from "api/apiAiModels";

import { App, Form } from "antd";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import SelectedSectorsChart from "components/ModelCreation/SelectedSectorsChart";
import socket from "utils/socket";
import { apiCheckAiSymbol } from "api/apiAiResults";
import { v4 as uuidv4 } from "uuid";
import CreateModelForm from "./CreateModelForm";
import BuyTotalStocksCard from "./BuyTotalStocksCard";
import ModelCreationStatus from "./ModelCreationStatus";
import { apiEvaluateBuySellPolarity } from "api/apiPredictions";

const ModelCreation = () => {
  const symbolsList = useSelector((state) => state.main.symbolsList);
  const symbolsDict = useSelector((state) => state.main.symbolsDict);
  const { message } = App.useApp();

  const [form] = Form.useForm();
  const [isPending, setIsPending] = useState(false);
  const [requestStatus, setRequestStatus] = useState("");
  const [sectorsDist, setSectorsDist] = useState([]);
  const [evaluation, setEvaluation] = useState("");
  const [totalCount, setTotalCount] = useState(null);
  const [buyTotalCount, setBuyTotalCount] = useState(null);
  const [isPredicting, setIsPredicting] = useState(false);

  const [buyTotalResults, setBuyTotalResults] = useState(null);

  const symbolsGroupedInSectored = useMemo(() => {
    const sectors = symbolsList.reduce((acc, item) => {
      const symbol = {
        label: `${item.name} - (${item.ticker}) ${
          buyTotalResults && buyTotalResults[item.ticker]?.polarity
            ? buyTotalResults[item.ticker]?.polarity + "%"
            : ""
        }`,
        value: item.ticker,
      };
      acc[item.sector.name] = acc[item.sector.name]
        ? [...acc[item.sector.name], symbol]
        : [symbol];
      return acc;
    }, {});

    const result = Object.entries(sectors).map((sector) => {
      return {
        label: sector[0],
        options: sector[1],
      };
    });
    return result;
  }, [symbolsList, buyTotalResults]);

  const evaluateBuySellPolarity = async () => {
    const { dates, days, percToGain } = form.getFieldsValue();
    const response = await apiEvaluateBuySellPolarity({
      startDate: dates[0].format("YYYY-MM-DD"),
      endDate: dates[1].format("YYYY-MM-DD"),
      days,
      percToGain,
    });

    setBuyTotalResults(response);
  };

  const evaluateTotalPolarity = () => {
    const { leads } = form.getFieldsValue();
    if (!buyTotalResults || !leads) return;
    const results = leads.reduce(
      (acc, value) => {
        acc.allTrue += buyTotalResults[value]?.trueCount || 0;
        acc.allTotal += buyTotalResults[value]?.total || 0;
        return acc;
      },
      { allTrue: 0, allTotal: 0 }
    );
    const allTruePercentage = (results.allTrue / results.allTotal) * 100;
    setEvaluation(allTruePercentage);
    setTotalCount(results.allTotal);
    setBuyTotalCount(results.allTrue);
  };

  const handleCreateModel = async (values) => {
    if (!values.indicators) {
      return;
    }
    const { leads, dates, name, percToGain, days } = values;
    setIsPending(true);
    setRequestStatus("");
    try {
      const { reqId } = await apiCreateNewModel({
        leads,
        name,
        percToGain,
        days,
        indicators: values.indicators,
        startDate: dates[0].format("YYYY-MM-DD"),
        endDate: dates[1].format("YYYY-MM-DD"),
      });
      socket.on(reqId, (data) => {
        if (data === "success") {
          setRequestStatus(reqId);
          message.success("Model created successfully!");
        } else if (data === "failed") {
          setRequestStatus("error");
        }
        setIsPending(false);
      });
    } catch (err) {
      setRequestStatus("error");
    }
  };

  const predictAllModelHistory = async () => {
    setIsPredicting(true);
    const newReqId = uuidv4();
    const values = form.getFieldsValue();
    const formData = {
      reqId: newReqId,
      symbol: symbolsList.map((item) => item.ticker),
      modelId: requestStatus,
      startDate: dayjs("2010-01-01").format("YYYY-MM-DD"),
      endDate: dayjs().add(1, "d").format("YYYY-MM-DD"),
      save_predictions: true,
      percToGain: values.percToGain,
      days: values.days,
      indicators: values.indicators,
    };
    await apiCheckAiSymbol(formData);

    socket.on(newReqId, (data) => {
      if (data === "success") {
        setIsPredicting(false);
        setRequestStatus("success");
        message.success("Model Predicted successfully!");
        // form.resetFields();
      }
    });
  };

  useEffect(() => {
    if (
      requestStatus &&
      requestStatus !== "" &&
      requestStatus !== "error" &&
      requestStatus !== "success"
    ) {
      predictAllModelHistory();
    }
  }, [requestStatus]);

  useEffect(() => {
    if (buyTotalResults) {
      evaluateTotalPolarity();
    }
  }, [buyTotalResults]);

  const handleSectorsPieChart = (symbols) => {
    const sectors = symbols.reduce((acc, item) => {
      const symbol = symbolsDict[item];
      acc[symbol.sector.name] = acc[symbol.sector.name]
        ? [...acc[symbol.sector.name], item]
        : [item];
      return acc;
    }, {});

    const sectorsData = Object.entries(sectors).map((sector) => {
      return {
        type: sector[0],
        value: +((sector[1].length / symbols.length) * 100).toFixed(2),
        amount: sector[1].length,
      };
    });
    setSectorsDist(sectorsData);
  };

  useEffect(() => {
    document.title = "Create Model | Dindicator Dashboard";
  }, []);

  return (
    <>
      <h1 className="pb-3 ps-1">Create New Model</h1>
      <div className="cards-wrapper">
        <CreateModelForm
          form={form}
          isPending={isPending}
          isPredicting={isPredicting}
          handleCreateModel={handleCreateModel}
          symbolsGroupedInSectored={symbolsGroupedInSectored}
          handleSectorsPieChart={handleSectorsPieChart}
          evaluateTotalPolarity={evaluateTotalPolarity}
          evaluateBuySellPolarity={evaluateBuySellPolarity}
        />
      </div>
      <div className="cards-wrapper">
        <ModelCreationStatus
          requestStatus={requestStatus}
          isPredicting={isPredicting}
        />

        <SelectedSectorsChart data={sectorsDist} />

        <BuyTotalStocksCard
          evaluation={evaluation}
          totalCount={totalCount}
          buyTotalCount={buyTotalCount}
          form={form}
        />
      </div>
    </>
  );
};

export default ModelCreation;
