import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";

import GenericCard from "components/UI/GenericCard/GenericCard";

import { Area, Line } from "@ant-design/plots";
import { Segmented } from "antd";
import { PercentageOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

const AccProfitChart = (props) => {
  const { transactions } = props;
  const [type, setType] = useState("accProfit");
  const data = transactions ? transactions?.slice().reverse() : null;

  const dataNormalized = useMemo(() => {
    if (data) {
      return [
        { closeDate: "2000-01-01", accProfit: 0, accProfitPercent: 0 },
        ...data,
      ];
    }
    return null;
  }, [data]);

  const dataWithBuyHold = useMemo(() => {
    if (data && type === "buyHold") {
      return data.reduce(
        (acc, item) => {
          acc.push({
            date: dayjs(item.closeDate).format("YYYY-MM-DD"),
            value: item.accProfitPercent,
            type: "Profit %",
          });
          acc.push({
            date: dayjs(item.closeDate).format("YYYY-MM-DD"),
            value: item.buyHoldReturn,
            type: "Buy and Hold %",
          });
          return acc;
        },
        [
          { date: "2000-01-01", value: 0, type: "Profit %" },
          { date: "2000-01-01", value: 0, type: "Buy and Hold %" },
        ]
      );
    }
    return [];
  }, [data, type]);

  const config = {
    data: dataNormalized,
    padding: "auto",
    xField: "closeDate",
    yField: type,
    slider: {},
    xAxis: {
      type: "timeCat",
      tickCount: 5,
    },
    meta: {
      accProfitPercent: {
        alias: "Profit %",
        formatter: (v) => `${v}%`,
      },
      accProfit: {
        alias: "Profit",
        formatter: (v) => `$${v}`,
      },
    },
  };

  const configLine = {
    data: dataWithBuyHold,
    xField: "date",
    yField: "value",
    limitInPlot: false,
    seriesField: "type",
    slider: {},
    meta: {
      value: {
        alias: "Value",
        formatter: (v) => `${v}%`,
      },
    },
  };

  return (
    <GenericCard
      title="Profit over time"
      maxHeight={1000}
      isCardOpenByDefault={false}
      className="table-card"
    >
      <div className="pb-4">
        <Segmented
          options={[
            {
              label: "USD",
              value: "accProfit",
            },
            {
              label: "Profit",
              icon: <PercentageOutlined />,
              value: "buyHold",
            },
          ]}
          value={type}
          onChange={setType}
        />
      </div>
      {type === "accProfit" ? (
        <Area {...config} height={600} autoFit={false} />
      ) : (
        <Line {...configLine} height={600} autoFit={false} />
      )}
    </GenericCard>
  );
};

AccProfitChart.propTypes = { transactions: PropTypes.array };

export default AccProfitChart;
