import React, { useState } from "react";
import PropTypes from "prop-types";
import { App, Button, Form, Input, InputNumber, Modal, Radio } from "antd";
import { apiUpdateLoginDetails } from "api/apiTws";
import GenericCard from "components/UI/GenericCard/GenericCard";

const UpdateIBKRDetails = (props) => {
  const { completed, checkOnboardingFlow } = props;
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const [isOpen, setIsOpen] = useState(false);

  const handleUpdateDetails = async (values) => {
    try {
      await apiUpdateLoginDetails(values);
      message.success("TWS Login Details Updated!");
      checkOnboardingFlow();
      setIsOpen(false);
    } catch (err) {
      form.setFields([
        {
          name: "twsApiPort",
          errors: [err.response.data.message],
        },
      ]);
    }
  };

  return (
    <>
      <GenericCard
        title={
          <span className={completed ? "green" : ""}>
            Set TWS Login Details
          </span>
        }
      >
        <Button type="primary" onClick={() => setIsOpen(true)}>
          {completed ? "Update IBKR Details" : "Set IBKR Details"}
        </Button>
      </GenericCard>
      <Modal
        title="Set TWS Login Details"
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={null}
        width={500}
        destroyOnClose={true}
      >
        <Form
          form={form}
          layout="vertical"
          name="tws_login"
          onFinish={handleUpdateDetails}
          initialValues={{}}
          preserve={false}
        >
          <Form.Item
            name="username"
            label="Username"
            rules={[
              {
                required: true,
                message: "This field is required!",
              },
            ]}
          >
            <Input placeholder="Username" />
          </Form.Item>

          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: "This field is required!",
              },
            ]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>

          <Form.Item
            name="tradingMode"
            label="Account Type"
            rules={[
              {
                required: true,
                message: "This field is required!",
              },
            ]}
          >
            <Radio.Group buttonStyle="solid">
              <Radio.Button value="live">Live</Radio.Button>
              <Radio.Button value="paper">Paper</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="twsApiPort"
            label="TWS API Port"
            rules={
              ([
                {
                  required: true,
                  message: "This field is required!",
                },
              ],
              [
                {
                  pattern: /^[0-9]*$/,
                  message: "This field must be a number!",
                },
              ],
              [
                {
                  pattern: /^\d{4}$/,
                  message: "This field must be a 4 digit number!",
                },
              ])
            }
          >
            <InputNumber placeholder="Port" />
          </Form.Item>

          <Form.Item shouldUpdate className="pt-2">
            {() => (
              <Button
                type="primary"
                htmlType="submit"
                disabled={
                  !form.isFieldsTouched(true) ||
                  !!form.getFieldsError().filter(({ errors }) => errors.length)
                    .length
                }
              >
                Update
              </Button>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

UpdateIBKRDetails.propTypes = {
  completed: PropTypes.bool,
  checkOnboardingFlow: PropTypes.func,
};

export default UpdateIBKRDetails;
