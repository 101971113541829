import React, { useCallback } from "react";
import PropTypes from "prop-types";

import GenericCard from "components/UI/GenericCard/GenericCard";
import AntTable from "components/UI/AntTable";
import dayjs from "dayjs";
import ConfirmPosition from "../Modals/ConfirmPosition";
// import { Button } from "antd";

const PendingDeal = (props) => {
  const { pendingDeal, getUserStrategies } = props;

  const sortByNumber = useCallback((a, b) => {
    // handle null / N/A values
    if (a === b) return 0;
    if (a === null || a === "N/A") return -1;
    if (b === null || b === "N/A") return 1;
    return a > b ? 1 : -1;
  }, []);

  const sortByText = useCallback((a, b) => {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  }, []);

  const columns = [
    {
      title: "Symbol",
      dataIndex: "symbol",
    },
    {
      title: "Adviced Amount",
      dataIndex: "advicedBuyAmount",
      sorter: {
        compare: (a, b) => sortByNumber(a.advicedBuyAmount, b.advicedBuyAmount),
      },
    },
    {
      title: "Adviced Price",
      dataIndex: "advicedBuyPrice",
      sorter: {
        compare: (a, b) => sortByNumber(a.advicedBuyPrice, b.advicedBuyPrice),
      },
    },
    {
      title: "Adviced Value",
      dataIndex: "advicedBuyValue",
      render: (text) => <span>${text}</span>,
      sorter: {
        compare: (a, b) => sortByNumber(a.advicedBuyValue, b.advicedBuyValue),
      },
    },
    {
      title: "Adviced Buy Date",
      dataIndex: "advicedBuyDate",
      render: (text) => <>{dayjs(text).format("DD/MM/YY")}</>,
      sorter: {
        compare: (a, b) => sortByNumber(a.advicedBuyDate, b.advicedBuyDate),
      },
    },
    {
      title: "Time",
      dataIndex: "created",
      render: (text) => <>{dayjs(text).format("HH:mm:ss - DD/MM/YY")}</>,
      sorter: {
        compare: (a, b) => sortByNumber(a.created, b.created),
      },
    },
    {
      title: "Load",
      key: "uid",
      dataIndex: "",
      render: (text) => (
        <ConfirmPosition
          pendingDeal={text}
          getUserStrategies={getUserStrategies}
        />
      ),
    },
    ,
  ];

  return (
    <GenericCard
      title="Pending Deal"
      className="table-card"
      isCardOpenByDefault={true}
      maxHeight={610}
    >
      <AntTable rowKey="_id" dataSource={pendingDeal} columns={columns} />
    </GenericCard>
  );
};
PendingDeal.propTypes = {
  transactions: PropTypes.array,
  getUserStrategies: PropTypes.func,
};

export default PendingDeal;
