import React from "react";
import PropTypes from "prop-types";
import { Button, Form, Input, Select } from "antd";
import { useDispatch } from "react-redux";
import { apiUpdateSymbolById } from "api/apiSymbols";
import { fetchSymbols } from "store/asyncThunk";

const EditSymbol = (props) => {
  const { onCloseModal, sectorsList, symbolToEdit } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  if (symbolToEdit) {
    form.setFieldsValue({
      name: symbolToEdit.name,
      ticker: symbolToEdit.ticker,
      sector: symbolToEdit.sector._id,
      disableComment: symbolToEdit?.disableComment,
    });
  } else {
    form.resetFields();
  }

  const handleSave = async () => {
    const formData = form.getFieldsValue();
    try {
      await apiUpdateSymbolById(symbolToEdit._id, formData);
      dispatch(fetchSymbols());
      onCloseModal();
    } catch (err) {}
  };

  const searchSymbol = (query, option) => {
    return (
      (option?.name ?? "")
        .toLowerCase()
        .includes((query ?? "").toLowerCase()) ||
      (option?.value ?? "").toLowerCase().includes((query ?? "").toLowerCase())
    );
  };

  return (
    <div className="save-backtest-form">
      <Form
        form={form}
        layout="vertical"
        style={
          {
            // maxWidth: 350,
          }
        }
        name="save_settings"
        onFinish={handleSave}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: "Name is required!",
            },
          ]}
          required={false}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="ticker"
          label="Ticker"
          normalize={(value) => (value || "").toUpperCase()}
          rules={[
            {
              required: true,
              message: "Name is required!",
            },
          ]}
          required={false}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="sector"
          label="Sector"
          required={false}
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={searchSymbol}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "name")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            fieldNames={{ label: "name", value: "_id" }}
            options={sectorsList}
          />
        </Form.Item>

        {!symbolToEdit.enabled && (
          <Form.Item name="disableComment" label="Disable Comment">
            <Input />
          </Form.Item>
        )}

        <Form.Item shouldUpdate style={{ paddingBlockStart: 10 }}>
          {() => (
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

EditSymbol.propTypes = {
  onEditSymbolData: PropTypes.func,
  onCloseModal: PropTypes.func,
  sectorsList: PropTypes.array,
  symbolToEdit: PropTypes.any,
};

export default EditSymbol;
