import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import GenericCard from "components/UI/GenericCard/GenericCard";
import { apiGetStrategyActivity } from "api/apiStrategies";
import dayjs from "dayjs";
import { toFixed } from "utils/helpers";

const StrategyActivityCard = (props) => {
  const { selectedStrategy } = props;
  const [strategyActivity, setStrategyActivity] = useState([]);

  const fetchStrategyActivity = async () => {
    const response = await apiGetStrategyActivity(selectedStrategy._id);
    setStrategyActivity(response);
  };

  useEffect(() => {
    fetchStrategyActivity();
  }, []);

  return (
    <GenericCard title="Strategy Activity" className="table-card">
      {strategyActivity.length > 0 &&
        strategyActivity.map((activity) => {
          if (activity.type === "trend-changed") {
            if (activity.data.previousTrend) {
              return (
                <div className="pb-2">
                  {dayjs(activity.created).format("DD/MM/YY HH:mm:ss")} -{" "}
                  <span className="text-green">{activity.type}: </span>
                  Trend changed from {activity.data.previousTrendCount}{" "}
                  {activity.data.previousTrend} to{" "}
                  {activity.data.currentTrendCount} {activity.data.currentTrend}{" "}
                  on{" "}
                  {dayjs(new Date(activity.date).toDateString())
                    .add(+23, "h")
                    .format("DD/MM/YY HH:mm")}
                </div>
              );
            }
            return (
              <div className="pb-2">
                {dayjs(activity.created).format("DD/MM/YY HH:mm:ss")} -{" "}
                <span className="text-green">{activity.type}: </span> Trend
                changed to {activity.data.currentTrendCount}{" "}
                {activity.data.currentTrend} on{" "}
                {dayjs(new Date(activity.date).toDateString())
                  .add(+23, "h")
                  .format("DD/MM/YY HH:mm")}
              </div>
            );
          }
          if (activity.type === "trade-open-notify") {
            return (
              <div className="pb-2">
                {dayjs(activity.created).format("DD/MM/YY HH:mm:ss")} -{" "}
                <span className="text-orange">{activity.type}: </span>
                Pending buy - Amount:{" "}
                {toFixed(activity.data.deal.advicedBuyAmount)}
                {", Adviced Price: "}
                {toFixed(activity.data.deal.advicedBuyPrice)} on{" "}
                {dayjs(
                  new Date(activity.data.deal.advicedBuyDate).toDateString()
                )
                  .add(+23, "h")
                  .format("DD/MM/YY HH:mm")}
              </div>
            );
          }
          if (activity.type === "trade-open") {
            return (
              <div className="pb-2">
                {dayjs(activity.created).format("DD/MM/YY HH:mm:ss")} -{" "}
                <span className="text-yellow">{activity.type}: </span>
                {activity.data.deal?.tradeNumber
                  ? `Trade number: ${activity.data.deal.tradeNumber} - `
                  : ""}{" "}
                Open rate: {activity.data.deal.openRate} Amount:{" "}
                {toFixed(activity.data.deal.amount)} on{" "}
                {dayjs(activity.data.deal.openDate).format("DD/MM/YY HH:mm")}
              </div>
            );
          }
          if (activity.type === "trade-close") {
            return (
              <div className="pb-2">
                {dayjs(activity.created).format("DD/MM/YY HH:mm:ss")} -{" "}
                <span className="text-blue">{activity.type}: </span>
                Trade number: {activity.data.deal?.tradeNumber} Profit: $
                {toFixed(activity.data.deal.profit.value)}{" "}
                {toFixed(activity.data.deal.profit.perc)}% Close rate:{" "}
                {activity.data.deal.openRate} Amount:{" "}
                {toFixed(activity.data.deal.amount)} on{" "}
                {dayjs(activity.data.deal.openDate).format("DD/MM/YY")}
              </div>
            );
          }
          if (activity.type === "delete-pending-buy") {
            return (
              <div className="pb-2">
                {dayjs(activity.created).format("DD/MM/YY HH:mm:ss")} -{" "}
                <span className="text-[#ff5454]">{activity.type}: </span>
                Missed buy opportunity: Amount:{" "}
                {toFixed(activity.data.deal.advicedBuyAmount)}{" "}
                {", Adviced Price: "}{" "}
                {toFixed(activity.data.deal.advicedBuyPrice)} on{" "}
                {dayjs(new Date(activity.date).toDateString())
                  .add(+23, "h")
                  .format("DD/MM/YY HH:mm")}
              </div>
            );
          }
          if (activity.type === "missed-buy-opportunity") {
            return (
              <div className="pb-2">
                {dayjs(activity.created).format("DD/MM/YY HH:mm:ss")} -{" "}
                <span className="text-[#FF0000]">{activity.type}: </span>
                Missed buy opportunity: Amount:{" "}
                {toFixed(activity.data.dealSnapshot.advicedBuyAmount)}{" "}
                {", Current Price: "}{" "}
                {toFixed(activity.data.dealSnapshot.currentRate)}
                {", Adviced Price: "}{" "}
                {toFixed(activity.data.dealSnapshot.advicedBuyPrice)} on{" "}
                {dayjs(new Date(activity.date).toDateString())
                  .add(+23, "h")
                  .format("DD/MM/YY HH:mm")}
              </div>
            );
          }
          if (activity.type === "missed-sell-opportunity") {
            return (
              <div className="pb-2">
                {dayjs(activity.created).format("DD/MM/YY HH:mm:ss")} -{" "}
                <span className="text-[#FF0000]">{activity.type}: </span>
                Missed sell opportunity: Amount:{" "}
                {toFixed(activity.data.dealSnapshot.amount)}{" "}
                {", Current Price: "}{" "}
                {toFixed(activity.data.dealSnapshot.currentRate)}
                {", Adviced Price: "}{" "}
                {toFixed(activity.data.dealSnapshot.advicedSellPrice)} on{" "}
                {dayjs(new Date(activity.date).toDateString())
                  .add(+23, "h")
                  .format("DD/MM/YY HH:mm")}
              </div>
            );
          }

          if (activity.type === "trade-close-notify") {
            return (
              <div className="pb-2">
                {dayjs(activity.created).format("DD/MM/YY HH:mm:ss")} -{" "}
                <span className="text-[#00ffd5]">{activity.type}: </span>
                Pending sell - Amount:{" "}
                {toFixed(activity.data.dealSnapshot.amount)}
                {", Adviced Price: "}
                {toFixed(activity.data.dealSnapshot.advicedSellPrice)} on{" "}
                {dayjs(new Date(activity.date).toDateString())
                  .add(+23, "h")
                  .format("DD/MM/YY HH:mm")}
              </div>
            );
          }

          if (activity.type === "trade-update") {
            return (
              <div className="pb-2">
                {dayjs(activity.created).format("DD/MM/YY HH:mm:ss")} -{" "}
                <span className="text-[#6dbacc]">{activity.type}: </span>
                Trade update - PNL:{" "}
                {toFixed(activity.data.dealSnapshot.profit.perc)}% $
                {toFixed(activity.data.dealSnapshot.profit.value)}
                {", Current rate: "}
                {toFixed(activity.data.dealSnapshot.currentRate)} on{" "}
                {dayjs(new Date(activity.date).toDateString())
                  .add(+23, "h")
                  .format("DD/MM/YY HH:mm")}
              </div>
            );
          }
          return <div className="pb-2">{activity.type}</div>;
        })}
    </GenericCard>
  );
};

StrategyActivityCard.propTypes = {
  selectedStrategy: PropTypes.object.isRequired,
};

export default StrategyActivityCard;
