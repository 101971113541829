import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSectors, fetchSymbols, fetchUserData } from "store/asyncThunk";

import Header from "./components/Header/Header";
import RouterConfig from "./components/RouterConfig";
import Drawer from "components/Drawer/Drawer";
import useMediaQuery from "hooks/useMediaQuery";
import { ErrorBoundary } from "react-error-boundary";
import { toggleDrawer } from "store/slices/main";

import "./App.scss";

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <br />
      <pre>{error.stack}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.main.user);
  const [isLoaded, setIsLoaded] = useState(false);

  const isMobile = useMediaQuery("(max-width: 700px)");

  const checkIfUserLoggedIn = async () => {
    try {
      await dispatch(fetchUserData()).unwrap();
    } catch (err) {
      // not logged in, load content, if using <ProtectedRoute> it will redirect to login
      setIsLoaded(true);
    }
  };

  const fetchAppData = async () => {
    try {
      await Promise.all([
        dispatch(fetchSectors()).unwrap(),
        dispatch(fetchSymbols()).unwrap(),
      ]);

      setIsLoaded(true);
    } catch (err) {}
  };

  useEffect(() => {
    if (user) {
      setIsLoaded(false);
      fetchAppData();
    }
  }, [user?._id]);

  useEffect(() => {
    checkIfUserLoggedIn();
  }, []);

  useEffect(() => {
    dispatch(toggleDrawer(!isMobile));
  }, [isMobile, dispatch]);

  if (!isLoaded) return <div className="splash-screen">Loading...</div>;

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className="app-wrapper">
        {user && <Drawer />}

        <div className="main-wrapper">
          {user && <Header />}
          <div className="content-wrapper">
            <div className="main-content">
              <RouterConfig />
              {user && <div className="pb-10"></div>}
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default App;
