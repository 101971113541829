import React from "react";
import GenericCard from "components/UI/GenericCard/GenericCard";
import { App, Button, Form, Input } from "antd";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";
import { apiChangeUserPassword } from "api/apiUser";

const ChangePassword = () => {
  const [form] = Form.useForm();
  const { message } = App.useApp();

  const handleChangePassword = async (values) => {
    try {
      await apiChangeUserPassword(values);
      message.success("Password changed successfully");
      form.resetFields();
    } catch (err) {
      form.setFields([
        {
          name: "currentPassword",
          errors: [err.response.data.message],
        },
      ]);
    }
  };

  return (
    <GenericCard title="Change Password">
      <Form
        form={form}
        layout="vertical"
        name="change-password"
        onFinish={handleChangePassword}
      >
        <Form.Item
          name="currentPassword"
          label="Current Password"
          rules={[
            {
              required: true,
              message: "Please input your current password!",
            },
          ]}
        >
          <Input.Password
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>

        <Form.Item
          name="newPassword"
          label="New Password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
          hasFeedback
        >
          <Input.Password
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>

        <Form.Item
          name="confirmNewPassword"
          label="Confirm New Password"
          dependencies={["newPassword"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The new password that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>

        <Form.Item shouldUpdate>
          {() => (
            <Button
              type="primary"
              htmlType="submit"
              disabled={
                !form.isFieldsTouched(true) ||
                !!form.getFieldsError().filter(({ errors }) => errors.length)
                  .length
              }
            >
              Update
            </Button>
          )}
        </Form.Item>
      </Form>
    </GenericCard>
  );
};

export default ChangePassword;
