import React, { useCallback } from "react";
import PropTypes from "prop-types";

import GenericCard from "components/UI/GenericCard/GenericCard";
import AntTable from "components/UI/AntTable";
import dayjs from "dayjs";
import ConfirmSellPosition from "../Modals/ConfirmSellPosition";

const PendingSell = (props) => {
  const { pendingSells, getUserStrategies } = props;

  const sortByNumber = useCallback((a, b) => {
    // handle null / N/A values
    if (a === b) return 0;
    if (a === null || a === "N/A") return -1;
    if (b === null || b === "N/A") return 1;
    return a > b ? 1 : -1;
  }, []);

  const sortByText = useCallback((a, b) => {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  }, []);

  const columns = [
    {
      title: "Symbol",
      dataIndex: "symbol",
    },
    {
      title: "Sell Amount",
      dataIndex: "amount",
      sorter: {
        compare: (a, b) => sortByNumber(a.amount, b.amount),
      },
    },
    {
      title: "Adviced Sell Price",
      dataIndex: "advicedSellPrice",
      sorter: {
        compare: (a, b) => sortByNumber(a.advicedSellPrice, b.advicedSellPrice),
      },
    },
    {
      title: "Adviced Sell Value",
      dataIndex: "advicedSellValue",
      render: (text) => <span>${text}</span>,
      sorter: {
        compare: (a, b) => sortByNumber(a.advicedBuyValue, b.advicedBuyValue),
      },
    },
    {
      title: "Adviced Sell Date",
      dataIndex: "advicedSellDate",
      render: (text) => <>{dayjs(text).format("DD/MM/YY")}</>,
      sorter: {
        compare: (a, b) => sortByNumber(a.advicedSellDate, b.advicedSellDate),
      },
    },
    {
      title: "Time",
      dataIndex: "created",
      render: (text) => <>{dayjs(text).format("HH:mm:ss - DD/MM/YY")}</>,
      sorter: {
        compare: (a, b) => sortByNumber(a.created, b.created),
      },
    },
    {
      title: "Sell",
      key: "uid",
      dataIndex: "",
      render: (text) => (
        <ConfirmSellPosition
          activePosition={text}
          getUserStrategies={getUserStrategies}
        />
      ),
    },
  ];

  return (
    <GenericCard
      title="Pending Sell"
      className="table-card"
      isCardOpenByDefault={true}
      maxHeight={610}
    >
      <AntTable rowKey="_id" dataSource={pendingSells} columns={columns} />
    </GenericCard>
  );
};
PendingSell.propTypes = {
  pendingSells: PropTypes.array,
  getUserStrategies: PropTypes.func,
};

export default PendingSell;
