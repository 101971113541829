import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiChangeUserDefaultPremiumFee,
  apiCompleteUserRegistration,
  apiGetUserDetails,
  apiLoginUser,
  apiLogoutUser,
} from "api/apiUser";
import { apiGetAllSectors } from "api/apiSectors";
import { apiGetAllSymbols } from "api/apiSymbols";
import socket from "utils/socket";

export const fetchUserData = createAsyncThunk(
  "main/fetchUserData",
  async () => {
    const data = await apiGetUserDetails();
    if (process.env.NODE_ENV !== "development") {
      socket.connect();
    }
    return data;
  }
);

export const onLoginSubmit = createAsyncThunk(
  "main/onLoginSubmit",
  async (loginData) => {
    const loggedInUserData = await apiLoginUser(loginData);
    socket.connect();
    return loggedInUserData;
  }
);

export const onCompleteRegister = createAsyncThunk(
  "main/onCompleteRegister",
  async (formData) => {
    const loggedInUserData = await apiCompleteUserRegistration(formData);
    socket.connect();
    return loggedInUserData;
  }
);

export const onUserLogout = createAsyncThunk("main/onUserLogout", async () => {
  const data = await apiLogoutUser();
  socket.disconnect();
  return data;
});

export const fetchSectors = createAsyncThunk("main/fetchSectors", async () => {
  const data = await apiGetAllSectors();
  return data;
});

export const fetchSymbols = createAsyncThunk("main/fetchSymbols", async () => {
  const symbolsList = await apiGetAllSymbols();
  const enabledSymbols = symbolsList.filter((item) => item.enabled);
  const symbolsDict = enabledSymbols.reduce((acc, item) => {
    acc[item.ticker] = item;
    return acc;
  }, {});

  const sectors = enabledSymbols.reduce((acc, item) => {
    const symbol = {
      label: `${item.name} - (${item.ticker})`,
      value: item.ticker,
    };
    acc[item.sector.name] = acc[item.sector.name]
      ? [...acc[item.sector.name], symbol]
      : [symbol];
    return acc;
  }, {});

  const symbolsGroupedInSectored = Object.entries(sectors).map((sector) => {
    return {
      label: sector[0],
      options: sector[1],
    };
  });

  return {
    allSymbols: symbolsList,
    symbolsList: enabledSymbols,
    symbolsDict,
    symbolsGroupedInSectored,
  };
});

export const updateUserPremiumFee = createAsyncThunk(
  "main/updatePremiumFee",
  async (formData) => {
    const data = await apiChangeUserDefaultPremiumFee(formData);
    return data;
  }
);
