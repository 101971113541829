import React, { useEffect, useState } from "react";
import GenericCard from "components/UI/GenericCard/GenericCard";
import ActiveLight from "components/UI/ActiveLight/ActiveLight";
import {
  apiGetAutomationStatus,
  apiStartAutomation,
  apiStopAutomation,
} from "api/apiTws";
import { Button } from "antd";
import AutomationLogsModal from "./AutomationLogsModal";

const AutomationRunningStatus = () => {
  const [status, setStatus] = useState(false);

  const getAutomationStatus = async () => {
    try {
      const isActive = await apiGetAutomationStatus();
      setStatus(isActive);
    } catch (err) {}
  };

  const onStartAutomation = async () => {
    try {
      const isActive = await apiStartAutomation();
      setStatus(isActive);
    } catch (err) {}
  };

  const onStopAutomation = async () => {
    try {
      const isActive = await apiStopAutomation();
      setStatus(isActive);
    } catch (err) {}
  };

  useEffect(() => {
    getAutomationStatus();
  }, []);

  return (
    <GenericCard title="Automation Status">
      {status ? (
        <div>
          <ActiveLight />

          <Button className="mt-4" onClick={onStopAutomation}>
            Stop automation
          </Button>
        </div>
      ) : (
        <div>
          <h3 className="red">Off</h3>
          <Button className="mt-4" onClick={onStartAutomation}>
            Start automation
          </Button>
        </div>
      )}
      <div className="pt-4">
        <AutomationLogsModal />
      </div>
    </GenericCard>
  );
};

export default AutomationRunningStatus;
