import React, { useMemo } from "react";
import PropTypes from "prop-types";
import GenericCard from "components/UI/GenericCard/GenericCard";
import { calcProfitPerc, returnUpDownClassname } from "utils/helpers";

const UnrealizedPnl = (props) => {
  const { openPositions } = props;

  const pnl = useMemo(() => {
    const results = openPositions.reduce(
      (acc, item) => {
        acc.value += item.profit.value;
        acc.sumInvested += item.openValue;
        acc.sumWithProfit += item.openValue + item.profit.value;
        return acc;
      },
      { value: 0, sumInvested: 0, sumWithProfit: 0 }
    );

    return {
      value: results.value,
      percent: calcProfitPerc(results.sumInvested, results.sumWithProfit),
    };
  }, [openPositions]);

  return (
    <GenericCard title="Unrealized PNL" tooltipText="Total open position PNL">
      <h2 className={returnUpDownClassname(pnl.value)}>
        ${pnl?.value.toLocaleString()}, {pnl?.percent.toLocaleString()}%
      </h2>
    </GenericCard>
  );
};

UnrealizedPnl.propTypes = { openPositions: PropTypes.array };

export default UnrealizedPnl;
