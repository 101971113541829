import React, { useEffect, useState } from "react";
import { apiCheckIfIBCConfigExists } from "api/apiTws";
import LinkTelegramAccount from "./LinkTelegramAccount";
import UpdateIBKRDetails from "./UpdateIBKRDetails";
import TwsStatus from "components/AdminDashboard/SystemControl/TwsStatus";
import AutomationRunningStatus from "./AutomationRunningStatus";
import { Button, Divider } from "antd";

const AutomationOnboarding = (props) => {
  const [flowStatus, setFlowStatus] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);

  const checkOnboardingFlow = async () => {
    try {
      const response = await apiCheckIfIBCConfigExists();
      setFlowStatus(response);
      setIsEditMode(!response?.ready);
    } catch (err) {
      console.log(err);
    }
  };

  const stepsComponents = [
    <LinkTelegramAccount completed={flowStatus?.step > 0} />,
    <UpdateIBKRDetails
      completed={flowStatus?.step > 1}
      checkOnboardingFlow={checkOnboardingFlow}
    />,
  ];

  useEffect(() => {
    checkOnboardingFlow();
  }, []);

  if (!flowStatus) return <div>Loading...</div>;

  return (
    <>
      <Divider orientation="left">Automation Settings</Divider>
      {flowStatus.ready && (
        <div className="cards-wrapper">
          <TwsStatus />
          <AutomationRunningStatus />
        </div>
      )}
      {flowStatus.ready && !isEditMode ? (
        <div>
          <div className="green pb-4">
            You are all set up! You can now start using the automation features.
          </div>
          <Button type="primary" onClick={() => setIsEditMode(true)}>
            Edit IBKR Details
          </Button>
        </div>
      ) : (
        <div className="cards-wrapper">
          {stepsComponents.map((step, index) => {
            if (index > flowStatus.step) return null;
            return <div key={index}>{step}</div>;
          })}
        </div>
      )}
    </>
  );
};

AutomationOnboarding.propTypes = {};

export default AutomationOnboarding;
