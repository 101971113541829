import React, { useMemo } from "react";
import PropTypes from "prop-types";
import GenericCard from "components/UI/GenericCard/GenericCard";
import { App, Button, Col, Descriptions, Row } from "antd";
import dayjs from "dayjs";
import {
  apiDeleteUser,
  apiRevokeUserToken,
  apiToggleBanUser,
} from "api/apiAdmin";
import { ExclamationCircleFilled } from "@ant-design/icons";

const UserDetails = (props) => {
  const { selectedUser, setSelectedUser } = props;
  const { message, modal } = App.useApp();

  const deleteUser = async () => {
    try {
      await apiDeleteUser(selectedUser._id);
      setSelectedUser(null);
      message.success("User has been deleted successfully");
    } catch (err) {
      console.log(err);
    }
  };

  const onDeleteUser = async () => {
    modal.confirm({
      title: "Are you sure you want to delete this user?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteUser();
      },
      onCancel() {},
    });
  };

  const onToggleBanUser = async () => {
    const res = await apiToggleBanUser({
      userId: selectedUser._id,
      enabled: !selectedUser.enabled,
    });
    setSelectedUser((prevState) => {
      return { ...prevState, enabled: res };
    });
  };

  const onRevokeUserToken = async () => {
    const uSession = await apiRevokeUserToken({
      userId: selectedUser._id,
    });

    setSelectedUser((prevState) => {
      return { ...prevState, uSession };
    });
  };

  const fields = useMemo(() => {
    return [
      {
        label: "Username",
        value: selectedUser.username,
      },
      {
        label: "Name",
        value: selectedUser.firstName,
      },
      {
        label: "Last name",
        value: selectedUser.lastName,
      },
      {
        label: "Role",
        value: selectedUser.role,
      },
      {
        label: "Enabled",
        value: selectedUser.enabled ? "Enabled" : "Blocked",
      },
      {
        label: "Last login",
        value: dayjs(selectedUser.lastLogin).format("HH:mm DD/MM/YY"),
      },
      {
        label: "Token unique session",
        value: dayjs(selectedUser.uSession).format("HH:mm DD/MM/YY"),
      },
      {
        label: "Created",
        value: dayjs(selectedUser.createdAt).format("HH:mm DD/MM/YY"),
      },
    ];
  }, [selectedUser]);

  return (
    <GenericCard className="table-card">
      <Descriptions
        title="User Details"
        column={{
          xxl: 4,
          xl: 3,
          lg: 3,
          md: 3,
          sm: 2,
          xs: 1,
        }}
      >
        {fields.map((field) => (
          <Descriptions.Item label={field.label}>
            {field.value}
          </Descriptions.Item>
        ))}
      </Descriptions>
      <Row gutter={8}>
        {process.env.NODE_ENV === "development" && (
          <Col>
            <Button
              type="primary"
              danger={selectedUser.enabled}
              onClick={onDeleteUser}
            >
              Delete user
            </Button>
          </Col>
        )}
        <Col>
          <Button
            type="primary"
            danger={selectedUser.enabled}
            onClick={onToggleBanUser}
          >
            {selectedUser.enabled ? "Ban user" : "Enable user"}
          </Button>
        </Col>
        <Col>
          <Button onClick={onRevokeUserToken}>Revoke token</Button>
        </Col>
      </Row>
    </GenericCard>
  );
};

UserDetails.propTypes = {
  selectedUser: PropTypes.object,
  setSelectedUser: PropTypes.func,
};

export default UserDetails;
