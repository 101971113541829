import React from "react";
import PropTypes from "prop-types";
import GenericCard from "../UI/GenericCard/GenericCard";
import { formatNumber, toFixed } from "utils/helpers";
import DashedTooltip from "components/UI/DashedTooltip";

const BuyAndHoldReturn = (props) => {
  const { backtestResults } = props;

  return (
    <GenericCard title="Portfolio Net Profit">
      <div className="total-profit-wrapper">
        <h2 style={{ paddingInlineEnd: 10 }}>
          ${formatNumber(backtestResults.metrics.pnl)}
        </h2>{" "}
        <span>{formatNumber(backtestResults.metrics.pnlPercent)}%</span>
      </div>
      <div className="pt-5 flex">
        <div className="pe-6">
          <h5>
            <DashedTooltip text="The return achieved if all funds (Initial Capital) were used to buy the security when the first trade is entered, and the position was held for the duration of the test period.">
              Buy & Hold Return
            </DashedTooltip>
          </h5>
          <h2>{formatNumber(backtestResults.metrics.buyHoldReturn || 0)}%</h2>
        </div>
        <div>
          <h5>
            <DashedTooltip text="The return achieved if all funds (Initial Capital) were used to buy the security when the first trade is entered, and the position was held for the duration of the test period.">
              Profit / B&H Ratio
            </DashedTooltip>
          </h5>
          <h2>
            {toFixed(
              Math.abs(
                backtestResults.metrics.pnlPercent /
                  (backtestResults.metrics.buyHoldReturn || 1)
              ),
              1
            )}
          </h2>
        </div>
      </div>
    </GenericCard>
  );
};

BuyAndHoldReturn.propTypes = {
  backtestResults: PropTypes.object,
};

export default BuyAndHoldReturn;
