import React, { useMemo } from "react";
import PropTypes from "prop-types";
import GenericCard from "components/UI/GenericCard/GenericCard";

const PortfolioBalance = (props) => {
  const { openPositions } = props;

  const currentlyInvested = useMemo(
    () =>
      openPositions.reduce(
        (acc, item) => {
          acc.amount += item.openValue;
          acc.tradesCount++;
          return acc;
        },
        { amount: 0, tradesCount: 0 }
      ),
    [openPositions]
  );

  return (
    <GenericCard
      title="Currently Invested"
      tooltipText="Total amount currently invested in trades"
    >
      <h2>${currentlyInvested.amount.toFixed(2)}</h2>
      <h4>{currentlyInvested.tradesCount} Positions</h4>
    </GenericCard>
  );
};

PortfolioBalance.propTypes = {
  openPositions: PropTypes.array,
};

export default PortfolioBalance;
