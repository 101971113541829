import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
} from "antd";
import GenericCard from "components/UI/GenericCard/GenericCard";
import { modelSearchRangePresets } from "utils/constants";
import dayjs from "dayjs";

import IndicatorsSelector from "./IndicatorsSelector";
import { useSelector } from "react-redux";

const { RangePicker } = DatePicker;

const CreateModelForm = (props) => {
  const {
    form,
    isPending,
    isPredicting,
    symbolsGroupedInSectored,
    handleSectorsPieChart,
    handleCreateModel,
    evaluateTotalPolarity,
    evaluateBuySellPolarity,
  } = props;

  const [symbolsString, setSymbolsString] = useState("");

  const searchSymbol = (query, option) => {
    if (option.options) return false;
    return (option?.label ?? "")
      .toLowerCase()
      .includes((query ?? "").toLowerCase());
  };

  const symbolsList = useSelector((state) => state.main.symbolsList);

  const handleSelectAll = (type) => {
    let symbols = [];
    switch (type) {
      case "tech":
        symbols = symbolsList.reduce((acc, item) => {
          if (item.sector.code === "technology") acc.push(item.ticker);
          return acc;
        }, []);
        break;

      default:
        break;
    }

    form.setFieldValue("leads", symbols);
  };

  const handleAddSymbolsString = () => {
    let symbols = symbolsString.split(",").map((symbol) => symbol.trim());
    symbols = symbols.filter((symbol) =>
      symbolsList.find((s) => s.ticker === symbol)
    );
    form.setFieldValue("leads", symbols);
  };

  return (
    <GenericCard className="table-card" maxHeight={1000}>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          percToGain: 5,
          days: 14,
          rsi: 14,
          obv: 9,
          dates: [dayjs().add(-2, "y"), dayjs()],
          indicators: {},
        }}
        onFieldsChange={evaluateTotalPolarity}
        onFinish={handleCreateModel}
      >
        <div className="flex flex-col xl:gap-20 xl:flex-row">
          <div className="basis-[30%]">
            <Form.Item
              name="name"
              label="Model name"
              required={false}
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item name={"dates"} label="Dates">
              <RangePicker
                presets={modelSearchRangePresets}
                format="DD/MM/YY"
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>

            <div className="flex gap-4">
              <Form.Item
                name="days"
                label="Days target"
                required={false}
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <InputNumber />
              </Form.Item>
              <Form.Item
                name="percToGain"
                label="% wanted Profit"
                required={false}
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <InputNumber />
              </Form.Item>
            </div>

            <div className="pt-1 pb-2">
              <Button type="dashed" onClick={evaluateBuySellPolarity}>
                Calculate Buy/Total
              </Button>
            </div>

            <Form.Item
              name="leads"
              label="Symbols"
              required={false}
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <Select
                onChange={handleSectorsPieChart}
                mode="multiple"
                style={{
                  width: "100%",
                }}
                placeholder="Select symbols"
                options={symbolsGroupedInSectored}
                filterOption={searchSymbol}
                optionLabelProp="value"
                allowClear
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider
                      orientation="left"
                      style={{
                        margin: "8px 0",
                        fontSize: "14px",
                      }}
                    >
                      Select groups
                    </Divider>
                    <Space
                      style={{
                        padding: "0 8px 4px",
                      }}
                    >
                      <Button
                        type="text"
                        // icon={<PlusOutlined />}
                        onClick={() => handleSelectAll("tech")}
                      >
                        Tech
                      </Button>
                    </Space>
                    <Divider
                      orientation="left"
                      style={{
                        margin: "8px 0",
                        fontSize: "14px",
                      }}
                    >
                      Free text
                    </Divider>
                    <div className="flex px-4 pb-2">
                      <Input
                        placeholder="AAPL, TSLA, NVDA"
                        className="me-2"
                        onChange={(e) => setSymbolsString(e.target.value)}
                        value={symbolsString}
                      />
                      <Button
                        type="primary"
                        onClick={() => handleAddSymbolsString()}
                      >
                        OK
                      </Button>
                    </div>
                    <p className="px-4 pb-4 text-xs">
                      Insert a list of tickers separated by commas (e.g., AAPL,
                      TSLA, NVDA).
                    </p>
                  </>
                )}
              />
            </Form.Item>
          </div>

          <IndicatorsSelector />
        </div>
        <Form.Item shouldUpdate>
          {() => (
            <Button
              loading={isPending}
              disabled={isPending || isPredicting}
              type="primary"
              htmlType="submit"
            >
              Create new model
            </Button>
          )}
        </Form.Item>
      </Form>
    </GenericCard>
  );
};

CreateModelForm.propTypes = {
  form: PropTypes.object.isRequired,
  isPending: PropTypes.bool.isRequired,
  isPredicting: PropTypes.bool.isRequired,
  symbolsGroupedInSectored: PropTypes.array.isRequired,
  handleSectorsPieChart: PropTypes.func.isRequired,
  handleCreateModel: PropTypes.func.isRequired,
  evaluateTotalPolarity: PropTypes.func.isRequired,
  evaluateBuySellPolarity: PropTypes.func.isRequired,
};

export default CreateModelForm;
