import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Input, Modal } from "antd";

const ChooseModelsForStrategy = (props) => {
  const { strategyModel, modelsList, results, handleSelectModel } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const filteredModels = modelsList.filter(
    (model) =>
      model.name.toLowerCase().includes(searchInput.toLowerCase()) &&
      model._id !== strategyModel._id
  );

  return (
    <>
      <Button onClick={() => setIsOpen(true)}>Choose Models</Button>

      <Modal
        title="Choose models for Eclipse"
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={null}
        width={800}
        destroyOnClose={true}
      >
        <div className="flex gap-2 pb-6">
          <Input
            placeholder="Search model"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            style={{
              width: 150,
            }}
          />
          <Button type="primary">Search</Button>
        </div>

        <div className="flex flex-wrap gap-2">
          {filteredModels.map((model) => (
            <Button
              key={model._id}
              onClick={() => handleSelectModel(results, model._id)}
              type={
                results.selectedModels.includes(model._id)
                  ? "primary"
                  : "default"
              }
            >
              {model.name}
            </Button>
          ))}
        </div>
      </Modal>
    </>
  );
};

ChooseModelsForStrategy.propTypes = {
  strategyModel: PropTypes.object,
  modelsList: PropTypes.array,
  selectedModels: PropTypes.array,
  handleSelectModel: PropTypes.func,
};

export default ChooseModelsForStrategy;
