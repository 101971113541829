import React, { useCallback, useEffect, useState } from "react";
import useTimer from "hooks/useTimer";
import { v4 as uuidv4 } from "uuid";
import socket from "utils/socket";
import { useSelector } from "react-redux";

import GenericCard from "components/UI/GenericCard/GenericCard";
import {
  Divider,
  Row,
  Form,
  Col,
  InputNumber,
  Select,
  Button,
  Progress,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import AntTable from "components/UI/AntTable";
import { apiSearchBacktestStrategies } from "api/apiPredictions";
import dayjs from "dayjs";
import { formatMomentDurationFull, returnUpDownClassname } from "utils/helpers";
import ModelDetails from "components/AiMagician/ModelDetails";

const StrategiesExplorer = (props) => {
  const {
    shownPredictionResults,
    onLoadAiResult,
    selectedIds,
    onRemoveAiResult,
  } = props;
  const [isPending, setIsPending] = useState(false);
  const [results, setResults] = useState([]);
  const { startTimer, stopTimer, time } = useTimer();
  const [requestProgress, setRequestProgress] = useState(null);
  const [isError, setIsError] = useState(false);
  const [estimatedTime, setEstimatedTime] = useState(null);

  const [form] = Form.useForm();

  const userPremiumFee = useSelector(
    (state) => state.main.user.preferences.premiumFee
  );

  const onLoadResult = (result) => {
    const backtestParams = form.getFieldsValue();
    onLoadAiResult(result, backtestParams);
  };

  // const handleSearchStrategiesSync = async (values) => {
  //   setIsPending(true);
  //   startTimer();
  //   try {
  //     const response = await apiSearchBacktestStrategies({
  //       queries: shownPredictionResults || [],
  //       params: values,
  //     });

  //     setResults(response);
  //   } catch (err) {}
  //   setIsPending(false);
  //   stopTimer();
  // };

  const handleSearchStrategiesAsync = async (values) => {
    setResults([]);
    setIsError(false);
    setIsPending(true);
    setRequestProgress(0);
    startTimer();
    try {
      const uuid = uuidv4();
      socket.on(uuid, (socketData) => {
        setResults((prevState) => [...socketData.results, ...prevState]);
        setRequestProgress(socketData.progress);
      });
      await apiSearchBacktestStrategies({
        uuid,
        queries: shownPredictionResults || [],
        params: values,
      });
    } catch (err) {
      setIsError(true);
    }
    setIsPending(false);
    stopTimer();
  };

  const updateEstimatedTime = () => {
    if (time === null || requestProgress === null) return;
    const estimated = (100 / requestProgress) * time - time;
    setEstimatedTime(estimated);
  };

  useEffect(() => {
    updateEstimatedTime();
  }, [requestProgress]);

  const sortByText = useCallback((a, b) => {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  }, []);

  const sortByNumber = useCallback((a, b) => {
    // handle null / N/A values
    if (a === b) return 0;
    if (a === null || a === "N/A") return -1;
    if (b === null || b === "N/A") return 1;
    return a > b ? 1 : -1;
  }, []);

  function stringToColor(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (let i = 0; i < 3; i++) {
      let value = (hash >> (i * 8)) & 0xff;
      color += ("00" + value.toString(16)).substr(-2);
    }
    return color;
  }

  const columns = [
    {
      title: "Load",
      key: "uid",
      dataIndex: "",
      render: (text) =>
        selectedIds[text._id] ? (
          <Button onClick={() => onRemoveAiResult(text._id)}>Remove</Button>
        ) : (
          <Button onClick={() => onLoadResult(text)}>Load</Button>
        ),
    },
    {
      title: "Model",
      dataIndex: "model",
      render: (text, obj) => (
        <div
          className="flex justify-center items-center w-full"
          style={{
            borderLeft: stringToColor(text._id),
            borderLeftWidth: 2,
            borderLeftStyle: "solid",
          }}
        >
          <ModelDetails model={text} />
        </div>
      ),
      sorter: {
        compare: (a, b) => sortByText(a.model.name, b.model.name),
        multiple: 1,
      },
    },
    {
      title: "Symbol",
      dataIndex: "symbol",
      sorter: {
        compare: (a, b) => sortByText(a.symbol, b.symbol),
        multiple: 2,
      },
    },
    {
      title: "Total Orders",
      dataIndex: "totalTransactions",
      sorter: {
        compare: (a, b) =>
          sortByNumber(a.totalTransactions, b.totalTransactions),
      },
    },
    {
      title: "Success Rate",
      dataIndex: "successRate",
      render: (text) => <span>{text?.toLocaleString()}%</span>,
      sorter: {
        compare: (a, b) => sortByNumber(a.successRate, b.successRate),
      },
    },
    {
      title: "Strategy Success Rate",
      dataIndex: "strategySuccessRate",
      render: (text) => <span>{text.toLocaleString()}%</span>,
      sorter: {
        compare: (a, b) =>
          sortByNumber(a.strategySuccessRate, b.strategySuccessRate),
      },
    },
    {
      title: "Total PNL",
      dataIndex: "pnl",
      render: (text) => (
        <span className={returnUpDownClassname(text)}>
          ${text.toLocaleString()}
        </span>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.pnl, b.pnl),
      },
    },
    {
      title: "PNL %",
      dataIndex: "pnlPercent",
      render: (text) => (
        <span className={returnUpDownClassname(text)}>
          %{text.toLocaleString()}
        </span>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.pnlPercent, b.pnlPercent),
        multiple: 2,
      },
    },
    {
      title: "Buy Hold Return",
      dataIndex: "buyHoldReturn",
      render: (text) => <span>{text.toLocaleString()}%</span>,
      sorter: {
        compare: (a, b) => sortByNumber(a.buyHoldReturn, b.buyHoldReturn),
      },
    },
    {
      title: "Highest Profit",
      dataIndex: "highestProfit",
      render: (text) => (
        <span className={returnUpDownClassname(text)}>
          %{text.toLocaleString()}
        </span>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.highestProfit, b.highestProfit),
      },
    },
    {
      title: "Highest Loss",
      dataIndex: "highestLoss",
      render: (text) => (
        <span className={returnUpDownClassname(text)}>
          %{text.toLocaleString()}
        </span>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.highestLoss, b.highestLoss),
      },
    },
    {
      title: "Avg Trade",
      dataIndex: "averageProfit",
      render: (text) => <span>{text.toLocaleString()}%</span>,
      sorter: {
        compare: (a, b) => sortByNumber(a.averageProfit, b.averageProfit),
      },
    },
    {
      title: "Avg Profit",
      dataIndex: "avgProfit",
      render: (text) => (
        <span className={returnUpDownClassname(text)}>
          %{text.toLocaleString()}
        </span>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.avgProfit, b.avgProfit),
      },
    },
    {
      title: "Avg Loss",
      dataIndex: "avgLoss",
      render: (text) => (
        <span className={returnUpDownClassname(text)}>
          %{text.toLocaleString()}
        </span>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.avgLoss, b.avgLoss),
      },
    },
    {
      title: "Profit Factor",
      dataIndex: "profitFactor",
      sorter: {
        compare: (a, b) => sortByNumber(a.profitFactor, b.profitFactor),
      },
    },
    {
      title: "Win-Loss Ratio",
      dataIndex: "winLossRatio",
      sorter: {
        compare: (a, b) => sortByNumber(a.winLossRatio, b.winLossRatio),
      },
    },
    {
      title: "Monthly Success Rate",
      dataIndex: "monthlySuccessRate",
      render: (text) => <span>{text?.toLocaleString()}%</span>,
      sorter: {
        compare: (a, b) =>
          sortByNumber(a.monthlySuccessRate, b.monthlySuccessRate),
      },
    },
    {
      title: "Avg. Annual Return",
      dataIndex: ["yearlyPnl", "average"],
      render: (text) => (
        <span className={returnUpDownClassname(text)}>
          %{text?.toLocaleString()}
        </span>
      ),
      sorter: {
        compare: (a, b) =>
          sortByNumber(a.yearlyPnl.average, b.yearlyPnl.average),
      },
    },
    {
      title: "Avg. Monthly Return",
      dataIndex: ["monthlyPnl", "average"],
      render: (text) => (
        <span className={returnUpDownClassname(text)}>
          %{text?.toLocaleString()}
        </span>
      ),
      sorter: {
        compare: (a, b) =>
          sortByNumber(a.monthlyPnl.average, b.monthlyPnl.average),
      },
    },
    // {
    //   title: "Average Duration",
    //   dataIndex: "averageDuration",
    //   sorter: {
    //     compare: (a, b) => sortByNumber(a.averageDuration, b.averageDuration),
    //   },
    // },
    {
      title: "From",
      dataIndex: "startDate",
      render: (text) => <>{dayjs(text).format("DD/MM/YY")}</>,
      sorter: {
        compare: (a, b) => sortByNumber(a.startDate, b.startDate),
        multiple: 2,
      },
    },
    {
      title: "To",
      dataIndex: "endDate",
      render: (text) => <>{dayjs(text).format("DD/MM/YY")}</>,
      sorter: {
        compare: (a, b) => sortByNumber(a.endDate, b.endDate),
      },
    },
    {
      title: "Corrupted",
      dataIndex: "isCorrupted",
      render: (text) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {text ? (
            <span style={{ fontSize: 22 }}>👮</span>
          ) : (
            <CloseOutlined style={{ fontSize: 22, color: "#ff0000" }} />
          )}
        </div>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.isCorrupted, b.isCorrupted),
      },
      filterMultiple: false,
      filters: [
        {
          text: "Yes",
          value: true,
        },
        {
          text: "No",
          value: false,
        },
      ],
      onFilter: (value, record) => record.isCorrupted === value,
    },
  ];

  const suffixSelector = (
    <Form.Item
      noStyle
      name="orderSizeType"
      required={false}
      rules={[
        {
          required: true,
          message: "",
        },
      ]}
    >
      <Select
        style={{
          width: 115,
        }}
      >
        <Select.Option value="USD">USD</Select.Option>
        <Select.Option value="percent">% of equity</Select.Option>
      </Select>
    </Form.Item>
  );

  useEffect(() => {
    document.title = "Strategies Explorer | Dindicator Dashboard";
  }, []);

  return (
    <>
      <h2>Industrial Backtest Strategies Explorer</h2>
      <br />
      <Form
        form={form}
        layout="vertical"
        name="overview"
        initialValues={{
          userCommission: userPremiumFee || 2.5,
          takeProfitPercent: 5,
          stopLossPercent: 0,
          enterPositionBuyCount: 1,
          exitPositionNoBuyCount: 0,
          daysOpenExitCount: 14,
          initialBalance: 1000,
          orderSize: 100,
          orderSizeType: "percent",
        }}
        onFinish={handleSearchStrategiesAsync}
      >
        <div className="cards-wrapper">
          <GenericCard title="Backtest Parameters">
            <Row gutter={8} align="bottom">
              <Col xs={24} sm={4}>
                <Form.Item
                  label="Inital balance"
                  name="initialBalance"
                  required={false}
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <InputNumber min={0} prefix="$" style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={6}>
                <Form.Item
                  label="Order size"
                  name="orderSize"
                  required={false}
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    addonAfter={suffixSelector}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={4}>
                <Form.Item
                  label="Commission"
                  name="userCommission"
                  required={false}
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <InputNumber min={0} prefix="$" style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>
            <Divider orientation="left">Deal Enter rules</Divider>
            <Row gutter={8} align="bottom">
              <Col xs={24} sm={4}>
                <Form.Item
                  label="Buy predict count"
                  name="enterPositionBuyCount"
                  required={false}
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <InputNumber min={1} style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>

            <Divider orientation="left">Deal close rules</Divider>
            <Row gutter={8} align="bottom">
              <Col xs={24} sm={4}>
                <Form.Item
                  label="Take Profit"
                  name="takeProfitPercent"
                  required={false}
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <InputNumber min={0} prefix="%" style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={4}>
                <Form.Item
                  label="Stop Loss"
                  name="stopLossPercent"
                  required={false}
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <InputNumber max={0} prefix="%" style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={4}>
                <Form.Item
                  label="No buy count"
                  name="exitPositionNoBuyCount"
                  required={false}
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <InputNumber min={0} style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={4}>
                <Form.Item
                  label="Days Open Count                  "
                  name="daysOpenExitCount"
                  required={false}
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <InputNumber min={0} style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>
          </GenericCard>

          <GenericCard title="Find results with...">
            <Row gutter={8} align="bottom">
              <Col xs={24} sm={4}>
                <Form.Item label="Total Orders <" name="totalTransactions">
                  <InputNumber
                    min={0}
                    style={{ width: "100%" }}
                    placeholder="From"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={4}>
                <Form.Item label="Success Rate <" name="successRate">
                  <InputNumber
                    min={0}
                    max={100}
                    prefix="%"
                    style={{ width: "100%" }}
                    placeholder="From"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={4}>
                <Form.Item label="Total PNL % <" name="pnlPercent">
                  <InputNumber
                    prefix="%"
                    style={{ width: "100%" }}
                    placeholder="From"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={4}>
                <Form.Item label="Highest Profit" name="highestProfit">
                  <InputNumber
                    min={0}
                    prefix="%"
                    style={{ width: "100%" }}
                    placeholder="Less than"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={4}>
                <Form.Item label="Highest Loss" name="highestLoss">
                  <InputNumber
                    min={0}
                    prefix="%"
                    style={{ width: "100%" }}
                    placeholder="Less than"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={4}>
                <Form.Item label="Buy Hold Return" name="buyHoldReturn">
                  <InputNumber
                    // min={0}
                    prefix="%"
                    style={{ width: "100%" }}
                    placeholder="Less than"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item shouldUpdate>
              {() => (
                <Button type="primary" htmlType="submit" loading={isPending}>
                  Search
                </Button>
              )}
            </Form.Item>
            {time !== null && <p>Elapsed time: {time} seconds</p>}
            {requestProgress !== null ? (
              <>
                <div
                  style={{
                    width: 170,
                  }}
                >
                  <Progress
                    percent={requestProgress}
                    status={`${isPending ? "active" : ""}${
                      isError ? "exception" : ""
                    }`}
                  />
                </div>
                {!isNaN(estimatedTime) && estimatedTime !== 0 ? (
                  <span>
                    Estimated remaining time:{" "}
                    {formatMomentDurationFull(estimatedTime * 1000)}
                  </span>
                ) : (
                  ""
                )}
              </>
            ) : (
              <></>
            )}
          </GenericCard>

          <GenericCard
            title={`Results (${results.length})`}
            className="table-card min-h-[600px]"
            maxHeight={1000}
          >
            <AntTable
              rowKey="_id"
              dataSource={results}
              columns={columns}
              rowClassName={(obj) =>
                selectedIds[obj._id] ? "table-row-selected" : ""
              }
            />
          </GenericCard>
        </div>
      </Form>
    </>
  );
};

export default StrategiesExplorer;
