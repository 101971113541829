import React from "react";
import PropTypes from "prop-types";
import GenericCard from "../UI/GenericCard/GenericCard";
import DashedTooltip from "components/UI/DashedTooltip";

const ProfitFactor = (props) => {
  const { backtestResults } = props;

  return (
    <GenericCard
      title="Profit factor"
      tooltipText="The amount of money a trading strategy made for every unit of money it lost. This value is calculated by dividing gross profits by gross losses."
    >
      <h2>{backtestResults.metrics.profitFactor}</h2>
      <div className="flex">
        <div className="pe-[30px]">
          <h5>
            <DashedTooltip text="The total profit for all profitable trades generated by a strategy.">
              Gross profit
            </DashedTooltip>
          </h5>
          <h3>${backtestResults.metrics.grossProfit.toLocaleString()}</h3>
        </div>
        <div>
          <h5>
            <DashedTooltip text="The total losses for all losing trades generated by a strategy.">
              Gross Loss
            </DashedTooltip>
          </h5>
          <h3>${backtestResults.metrics.grossLoss.toLocaleString()}</h3>
        </div>
      </div>

      {/* <div className="flex">
        <div className="pe-[45px]">
          <h5>
            <DashedTooltip
              text={`The Sharpe Ratio is widely used by portfolio managers and individual traders to show how much risk was taken to achieve specific returns. The formula for the Sharpe ratio is SR = (MR - RFR) / SD, where MR is the average return for a period (monthly for a trading period of 3 or more months or daily for a trading period of 3 or more days), and RFR is the risk-free rate of return (by default, 2% annually. Can be changed with the "risk_free_rate" parameter of the "strategy()" function). SD is the standard deviation of returns. Thus, this formula yields a value that could be loosely defined as return per unit risked if we accept the premise that variability is risk. The higher Sharpe ratio, the smoother the equity curve. Having a smooth equity curve is an important objective for many traders.`}
            >
              Sharpe Ratio
            </DashedTooltip>
          </h5>
          <h3>
            {backtestResults.metrics?.sharpeRatio
              ? backtestResults.metrics.sharpeRatio.toLocaleString()
              : 0}
          </h3>
        </div>
        <div>
          <h5>Sortino Ratio</h5>
          <h3>
            {backtestResults.metrics?.sortinoRatio
              ? backtestResults.metrics.sortinoRatio.toLocaleString()
              : 0}
          </h3>
        </div>
      </div> */}
    </GenericCard>
  );
};
ProfitFactor.propTypes = {
  backtestResults: PropTypes.object,
};

export default ProfitFactor;
