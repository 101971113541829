import React from "react";
import PropTypes from "prop-types";
import GenericCard from "../UI/GenericCard/GenericCard";

const TotalPositionsClosedWithProfit = (props) => {
  const { backtestResults } = props;

  return (
    <GenericCard title="Closed with any profit">
      <h2>{backtestResults.metrics.closedWithProfit}</h2>
      <h5>out of {backtestResults.metrics.totalTransactions} transactions</h5>
      <div className="flex">
        <div className="pe-[30px]">
          <h5>Success rate</h5>
          <h2>{backtestResults.metrics.successRate}%</h2>
        </div>
      </div>
    </GenericCard>
  );
};
TotalPositionsClosedWithProfit.propTypes = {
  backtestResults: PropTypes.object,
};

export default TotalPositionsClosedWithProfit;
