import React, { useEffect, useState } from "react";

import { Divider } from "antd";
import OnlineUsers from "components/Home/OnlineUsers";
import InviteNewUser from "./InviteNewUser";
import UserCard from "./UserCard";
import UsersTable from "./UsersTable";

const UsersManagement = () => {
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [searchResults, setSearchResults] = useState([]);

  const handleSelectUser = (userId) => {
    setSelectedUserId(userId);
  };

  useEffect(() => {
    document.title = "Users | Dindicator Dashboard";
  }, []);

  return (
    <>
      <InviteNewUser />
      <div className="pt-4">
        <OnlineUsers title="Online users" handleSelectUser={handleSelectUser} />
      </div>
      {selectedUserId ? (
        <div className="pt-4">
          <UserCard
            userId={selectedUserId}
            onBackButton={() => {
              handleSelectUser(null);
            }}
          />
        </div>
      ) : (
        <>
          <Divider orientation="left">Users</Divider>
          <div className="cards-wrapper min-h-[700px]">
            <UsersTable
              handleSelectUser={handleSelectUser}
              searchResults={searchResults}
              setSearchResults={setSearchResults}
            />
          </div>
        </>
      )}
    </>
  );
};

export default UsersManagement;
