import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import GenericCard from "components/UI/GenericCard/GenericCard";
import { AutoComplete, Button, Form } from "antd";
import { useSelector } from "react-redux";

import dayjs from "dayjs";
import { apiGetCandles } from "api/apiUtils";
import { DualAxes } from "@ant-design/plots";

const PredictionsChart = (props) => {
  const { predictions } = props;
  const [chartsData, setChartsData] = useState(null);
  const [compareSymbol, setCompareSymbol] = useState(predictions[0]?.symbol);
  const symbolsGroupedInSectored = useSelector(
    (state) => state.main.symbolsGroupedInSectored
  );
  const dates = useRef();

  const getCompareSymbolData = async (symbol) => {
    if (!symbol) return null;
    const options = {
      symbol,
      startDate: dates.current.startDate,
      endDate: dates.current.endDate,
    };
    let data = await apiGetCandles(options);
    data = data.map((item) => {
      return { date: dayjs(item.date).format("DD/MM/YY"), price: +item.close };
    });
    return data;
  };

  const onUpdateCharts = async () => {
    const sum = predictions.reduce((acc, result) => {
      const date = result.date;

      if (!acc[date]) {
        acc[date] = { buy: 0, noBuy: 0, failedBuy: 0, failedNoBuy: 0 };
      }

      if (result.buy_sell !== "not enough data") {
        if (result.prediction === result.buy_sell) {
          if (result.prediction === "buy") {
            acc[date].buy++;
          } else if (result.prediction === "no buy") {
            acc[date].noBuy++;
          }
        } else {
          if (result.prediction === "buy") {
            acc[date].failedBuy++;
          } else if (result.prediction === "no buy") {
            acc[date].failedNoBuy++;
          }
        }
      }

      return acc;
    }, {});
    const daysList = Object.entries(sum);

    const buyOrdered = [];
    daysList?.forEach((item) => {
      if (item[1].buy || item[1].buy === 0) {
        buyOrdered.push({
          date: dayjs(item[0]).format("DD/MM/YY"),
          count: item[1].buy,
          type: "Buy",
          dateValue: new Date(item[0]),
        });
      }
      if (item[1].noBuy || item[1].noBuy === 0) {
        buyOrdered.push({
          date: dayjs(item[0]).format("DD/MM/YY"),
          count: item[1].noBuy,
          type: "No Buy",
          dateValue: new Date(item[0]),
        });
      }
      if (item[1].failedBuy || item[1].failedBuy === 0) {
        buyOrdered.push({
          date: dayjs(item[0]).format("DD/MM/YY"),
          count: item[1].failedBuy,
          type: "Failed Buy",
          dateValue: new Date(item[0]),
        });
      }
      if (item[1].failedNoBuy || item[1].failedNoBuy === 0) {
        buyOrdered.push({
          date: dayjs(item[0]).format("DD/MM/YY"),
          count: item[1].failedNoBuy,
          type: "Failed No Buy",
          dateValue: new Date(item[0]),
        });
      }
    });
    buyOrdered.sort((a, b) => a.dateValue - b.dateValue);

    dates.current = {
      startDate: dayjs(daysList[0][0]).format("YYYY-MM-DD"),
      endDate: dayjs(daysList[daysList.length - 1][0]).format("YYYY-MM-DD"),
    };

    const compareSymbolData = await getCompareSymbolData(compareSymbol);

    const dataMerged = { data: buyOrdered, compareSymbolData };
    setChartsData(dataMerged);
  };

  const updateCompareSymbolData = async () => {
    const data = await getCompareSymbolData(compareSymbol);

    setChartsData((prevState) => {
      return { ...prevState, compareSymbolData: data };
    });
  };

  useEffect(() => {
    if (chartsData) updateCompareSymbolData();
  }, [compareSymbol]);

  useEffect(() => {
    onUpdateCharts();
  }, [predictions]);

  const config = {
    data: [chartsData?.data, chartsData?.compareSymbolData],
    xField: "date",
    yField: ["count", "price"],
    limitInPlot: false,

    padding: "auto",

    xAxis: {
      label: {
        autoRotate: false,
      },
    },
    slider: {},
    geometryOptions: [
      {
        geometry: "column",
        isStack: true,
        seriesField: "type",
        color: ["#5bc300", "#d62728", "#005a01", "#700000"],
        legend: {
          selected: {
            "No Buy": false,
            "Failed Buy": false,
            "Failed No Buy": false,
          },
        },
      },
      {
        geometry: "line",
        color: "#ffffff",
      },
    ],
  };
  return (
    <GenericCard
      className="table-card"
      title="Performance-Based Buy/No Buy Signal Comparison"
      maxHeight={800}
      isCardOpenByDefault={false}
    >
      <div className="results-table-buttons">
        <Button type="primary" onClick={onUpdateCharts}>
          Update charts
        </Button>
      </div>
      <div>
        {chartsData && (
          <>
            <Form.Item label={`Symbol to compare [${compareSymbol}]`}>
              <AutoComplete
                placeholder="Enter symbol name"
                dropdownMatchSelectWidth={252}
                options={symbolsGroupedInSectored}
                filterOption={(inputValue, option) =>
                  option.label
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
                style={{ width: 200 }}
                allowClear
                onSelect={(value) => setCompareSymbol(value)}
              />
            </Form.Item>
            <DualAxes {...config} height={550} autoFit={false} />
          </>
        )}
      </div>
    </GenericCard>
  );
};

PredictionsChart.propTypes = { predictions: PropTypes.array };

export default PredictionsChart;
