import React from "react";
import PropTypes from "prop-types";

import {
  Row,
  Form,
  Col,
  DatePicker,
  Select,
  Button,
  Space,
  Divider,
  InputNumber,
} from "antd";
import dayjs from "dayjs";

import GenericCard from "components/UI/GenericCard/GenericCard";
import { modelSearchRangePresets } from "utils/constants";

import { useSelector } from "react-redux";

const { RangePicker } = DatePicker;
const dateFormat = "DD/MM/YY";

const SearchForm = (props) => {
  const { form, onFetchData } = props;
  const symbolsList = useSelector((state) => state.main.symbolsList);

  const symbolsGroupedInSectored = useSelector(
    (state) => state.main.symbolsGroupedInSectored
  );

  const handleSelectAll = (type) => {
    const symbols = symbolsList.map((symbol) => symbol.ticker);
    form.setFieldValue("symbols", symbols);
  };

  return (
    <GenericCard title="Search">
      <Form
        form={form}
        layout="vertical"
        name="ai"
        initialValues={{
          dates: [dayjs().add(-2, "y"), dayjs()],
          diffTreshold: 0,
        }}
        style={{
          maxWidth: 400,
        }}
        onFinish={onFetchData}
      >
        <Row gutter={5} align="bottom">
          <Col xs={24} sm={24}>
            <Form.Item name="dates">
              <RangePicker
                presets={modelSearchRangePresets}
                format={dateFormat}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24}>
            <Form.Item
              name="symbols"
              label="Symbol"
              // normalize={(value) => (value || "").toUpperCase()}
              required={false}
              rules={[
                {
                  required: true,
                  message: "Select a symbol",
                },
              ]}
            >
              <Select
                // showSearch
                mode="multiple"
                placeholder="Select symbols"
                options={symbolsGroupedInSectored}
                style={{
                  width: "100%",
                }}
                filterOption={(inputValue, option) =>
                  option.label
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
                optionLabelProp="value"
                maxTagCount="responsive"
                allowClear
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider
                      orientation="left"
                      style={{
                        margin: "8px 0",
                        fontSize: "14px",
                      }}
                    >
                      Select groups
                    </Divider>
                    <Space
                      style={{
                        padding: "0 8px 4px",
                      }}
                    >
                      <Button
                        type="text"
                        // icon={<PlusOutlined />}
                        onClick={() => handleSelectAll()}
                      >
                        All
                      </Button>
                    </Space>
                  </>
                )}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name="diffTreshold"
              label="Diff Treashold"
              required={false}
              rules={[
                {
                  required: true,
                  message: "Select a diff treashold",
                },
              ]}
            >
              <InputNumber
                style={{
                  width: "100%",
                }}
                placeholder="Diff Treashold"
                prefix="%"
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item shouldUpdate>
              {() => (
                <Button type="primary" htmlType="submit">
                  Search
                </Button>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </GenericCard>
  );
};

SearchForm.propTypes = {
  form: PropTypes.any,
};

export default SearchForm;
