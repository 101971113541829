const usersRoutes = {
  getUserDetails: "/api/users/me",
  login: "/api/users/login",
  logout: "/api/users/logout",
  online: "/api/users/online",
  changeDefaultPremiumFee: "/api/users/change-premium-fee",
  changePassword: "/api/users/change-password",
  getOrderTrackerToken: "/api/users/order-tracker-token",
  unlinkTelegramAccount: "/api/users/telegram-account-unlink",
  getUserInvitationByToken: "/api/users/invitation-details",
  completeUserRegistration: "/api/users/complete-registration",
};

const twsRoutes = {
  getTwsStatus: "/api/tws/tws_status",
  startTws: "/api/tws/start_tws",
  stopTws: "/api/tws/stop_tws",
  getAutomationStatus: "/api/tws/automation-status",
  startAutomation: "/api/tws/start-automation",
  stopAutomation: "/api/tws/stop-automation",
  getAutomationLogs: "/api/tws/get-logs",
  updateLoginDetails: "/api/tws/update-login-details",
  checkIfIBCConfigExists: "/api/tws/check-ibc-config",
};

const utilsRoutes = {
  getConnectionToServer: "/api/utils/get_connections",
  getCandles: "/api/utils/get_candles",
  refreshPlatformForAllUsers: "/api/utils/refresh-platform",
  triggerFetchDailySymbols: "/api/utils/trigger_fetch_daily_candles",
  checkYFinanceDifference: "/api/utils/check-yfinance-difference",
};

const symbolsRoutes = {
  getAllSymbols: "/api/symbols",
  addNewSymbol: "/api/symbols",
  updateSymbolById: "/api/symbols",
  deleteSymbolById: "/api/symbols",
  enableSymbolById: "/api/symbols/enable-symbol",
  disableSymbolById: "/api/symbols/disable-symbol",
  pullDayCandlesFullHistory: "/api/symbols/pull-day_candles_history",
};

const strategyTabsRoutes = {
  getAllStrategyTabs: "/api/strategy-tabs",
  createNewStrategyTab: "/api/strategy-tabs",
  updateStrategyTabById: "/api/strategy-tabs",
  deleteStrategyTabById: "/api/strategy-tabs",
};

const sectorsRoutes = {
  getAllSectors: "/api/sectors",
};

const strategiesRoutes = {
  createNewStrategy: "/api/strategy/create",
  toggleStrategyActive: "/api/strategy/toggle-active",
  toggleStrategyAccountType: "/api/strategy/account-type",
  checkSimilar: "/api/strategy/check-similar",
  getUserStrategies: "/api/strategy/all",
  recountStrategyTrend: "/api/strategy/recount-trend",
  confirmStrategyTrade: "/api/strategy/confirm-strategy-trade",
  confirmStrategyTradeSell: "/api/strategy/confirm-strategy-trade-sell",
  deleteStrategy: "/api/strategy/delete-strategy",
  updateStrategy: "/api/strategy/update-strategy",
  createNewStrategyNote: "/api/strategy/create-note",
  updateStrategyNote: "/api/strategy/update-note",
  deleteStrategyNote: "/api/strategy/delete-note",
  getStrategyById: "/api/strategy/details",
};

const tradesRoutes = {
  getTradesHistory: "/api/trades/history",
  triggerCloseTrade: "/api/trades/trigger-close-trade",
};

const brokerOrderRoutes = {
  getBrokerOrders: "/api/broker-orders/executions",
  retryToOpenTrade: "/api/broker-orders/retry-open-failed-trade",
};

const aiModelRoutes = {
  createNewBacktest: "/api/ai-models",
  getModelsList: "/api/ai-models",
  deleteModelById: "/api/ai-models",
  getModelById: "/api/ai-models/details",
};
const aiResultsRoutes = {
  checkAiSymbol: "/api/ai-results/predict-job",
};

const predictionsRoutes = {
  checkAiSymbolNew: "/api/predictions/predict-new",
  getPredictions: "/api/predictions/get-predictions",
  getPredictionsMultipleModels: "/api/predictions/get-predictions-multi-models",
  searchBacktestStrategies: "/api/predictions/search-strategies-backtest",
  getSymbolRecommendations: "/api/predictions/symbol-recommendations",
  evaluateBuySellPolarity: "/api/predictions/evaluate-buy-sell-polarity",
};

const adminRoutes = {
  getLogsByUser: "/api/admin/user-logs",
  searchTrades: "/api/admin/search-trades",
  editTrade: "/api/admin/edit-trade",
  deleteTrade: "/api/admin/delete-trade",
  inviteUser: "/api/admin/invite-user",
  deleteUser: "/api/admin/delete-user",
  toggleBanUser: "/api/admin/ban-user",
  revokeToken: "/api/admin/revoke-user-token",
  getOnlineUserActvity: "/api/admin/online-user-activity",
  searchUser: "/api/admin/search-user",
  getUserDetailsById: "/api/admin/user-details",
};

const strategyActivityRoutes = {
  getStrategyActivity: "/api/strategy-activity",
};

export const routes = {
  ...usersRoutes,
  ...twsRoutes,
  ...utilsRoutes,
  ...symbolsRoutes,
  ...sectorsRoutes,
  ...aiModelRoutes,
  ...aiResultsRoutes,
  ...adminRoutes,
  ...strategiesRoutes,
  ...tradesRoutes,
  ...strategyTabsRoutes,
  ...predictionsRoutes,
  ...strategyActivityRoutes,
  ...brokerOrderRoutes,
};
