import React, { useEffect, useState } from "react";
import GenericCard from "components/UI/GenericCard/GenericCard";
import { Button, Checkbox, Form, Pagination, Spin } from "antd";
import dayjs from "dayjs";
import { apiGetLogsByUser } from "api/apiAdmin";
import { formatMomentDurationFull } from "utils/helpers";

const pageSize = 20;

const filterOptions = [
  { label: "Login", value: "user_login" },
  { label: "Logout", value: "user_logout" },
  { label: "Session start", value: "session_start" },
  { label: "Session end", value: "session_end" },
];

const filterValues = filterOptions.map((item) => item.value);

const LogsReport = (props) => {
  const { selectedUser } = props;
  const [form] = Form.useForm();
  const [logsResults, setLogsResults] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const getLogsResults = async (page = 1) => {
    const { filters } = form.getFieldValue();
    const results = await apiGetLogsByUser({
      user: selectedUser._id,
      page,
      pageSize,
      filters,
    });
    setLogsResults(results);
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleChangeFilters = async (values) => {
    getLogsResults(1);
  };

  useEffect(() => {
    if (selectedUser) getLogsResults(currentPage);
  }, [currentPage, selectedUser]);

  useEffect(() => {
    if (selectedUser) setCurrentPage(1);
  }, [selectedUser]);

  return (
    <GenericCard title="User activity report" className="table-card">
      <Form
        form={form}
        layout="vertical"
        name="serach-user"
        style={{
          maxWidth: 400,
        }}
        initialValues={{
          filters: filterValues,
        }}
        onFinish={handleChangeFilters}
      >
        <Form.Item name="filters" label="Select filters">
          <Checkbox.Group options={filterOptions} />
        </Form.Item>
        <Form.Item shouldUpdate>
          {() => (
            <Button type="primary" htmlType="submit">
              Search
            </Button>
          )}
        </Form.Item>
      </Form>
      {logsResults?.results?.map((log) => (
        <p>
          <span>{dayjs(log.date).format("DD/MM/YY HH:mm:ss")}</span>
          {" - "}
          <span>{log.eventName}</span>
          {log.event === "session_end" && (
            <span>
              <span>
                {" - "}
                Start:{" "}
                {dayjs(log.params.sessionStart).format("HH:mm:ss DD/MM/YY")}
              </span>
              <span>
                {" - "}
                End: {dayjs(log.params.sessionEnd).format("HH:mm:ss DD/MM/YY")}
              </span>
              <span>
                {" - "}
                Duration: {formatMomentDurationFull(log.params.sessionDuration)}
              </span>
              <span>
                {" - "}Activity counter: {log.params.activityCounter}
              </span>
              <span>
                {" - "}IP Address: {log.params.ipAddress}
              </span>
            </span>
          )}
        </p>
      ))}
      <Pagination
        current={currentPage}
        onChange={handleChangePage}
        showSizeChanger={false}
        pageSize={pageSize}
        hideOnSinglePage={true}
        total={logsResults?.total}
      />
      {logsResults?.total && <p>Total: {logsResults.total}</p>}
      {!logsResults && <Spin />}
    </GenericCard>
  );
};

LogsReport.propTypes = {};

export default LogsReport;
