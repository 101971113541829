import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "antd";
import dayjs from "dayjs";

const SimilarStrategies = (props) => {
  const { similarStrategies } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <span
        style={{ paddingInlineStart: 15 }}
        className="link"
        onClick={() => setIsOpen(true)}
      >
        Similar Strategy Exists
      </span>

      <Modal
        title="Similar Strategies"
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={null}
        width={800}
        destroyOnClose={true}
      >
        <p>Strategies with the following params already exists:</p>
        {similarStrategies?.map((similarStrategy, index) => (
          <div key={similarStrategy._id} style={{ paddingBlock: 15 }}>
            <div>
              {index + 1}: {similarStrategy.name}
            </div>
            <p>Account: {similarStrategy.isDemo ? "Demo" : "Real"}</p>
            <p>
              Buy predict count: {similarStrategy.params.enterPositionBuyCount}
            </p>
            <p>No Buy count: {similarStrategy.params.exitPositionNoBuyCount}</p>
            <p>Take profit: {similarStrategy.params.takeProfitPercent}</p>
            <p>Stop loss: {similarStrategy.params.stopLossPercent}</p>
            <p>
              Exit after days open: {similarStrategy.params.daysOpenExitCount}
            </p>
            <p>Strategy ID: {similarStrategy._id}</p>
          </div>
        ))}
      </Modal>
    </>
  );
};

SimilarStrategies.propTypes = {
  similarStrategies: PropTypes.array,
};

export default SimilarStrategies;
