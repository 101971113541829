import React from "react";
import PropTypes from "prop-types";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

import "./HoverableTooltip.scss";

const HoverableTooltip = (props) => {
  const { text, onClick } = props;
  return (
    <span className="tooltip-wrapper" onClick={onClick}>
      <Tooltip title={text}>
        <QuestionCircleOutlined />
      </Tooltip>
    </span>
  );
};

HoverableTooltip.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
};

export default HoverableTooltip;
