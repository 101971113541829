import React from "react";
import PropTypes from "prop-types";
import GenericCard from "../UI/GenericCard/GenericCard";
import dayjs from "dayjs";

const AverageHold = (props) => {
  const { backtestResults } = props;

  return (
    <GenericCard title="Average hold duration">
      <h3>{backtestResults.metrics.averageDuration} days</h3>
      <div className="flex">
        <div className="pe-[30px]">
          <h5>On success</h5>
          <h3>{backtestResults.metrics.averageWinDuration || 0} days</h3>
        </div>
        <div>
          <h5>On failure</h5>
          <h3>{backtestResults.metrics.averageLossDuration || 0} days</h3>
        </div>
      </div>

      <h5>Trading range</h5>
      {backtestResults.transactions.length > 0 ? (
        <h3>
          {dayjs(
            backtestResults.transactions[
              backtestResults.transactions.length - 1
            ].openDate
          ).format("DD/MM/YY")}{" "}
          -{" "}
          {dayjs(backtestResults.transactions[0].closeDate).format("DD/MM/YY")}{" "}
        </h3>
      ) : (
        <h3>No trades</h3>
      )}
    </GenericCard>
  );
};

AverageHold.propTypes = { backtestResults: PropTypes.object };

export default AverageHold;
