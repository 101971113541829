import dayjs from "dayjs";
import moment from "moment";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

dayjs.extend(isSameOrBefore);

export const calcProfitPerc = (buyPrice, sellPrice) => {
  const change = +(((sellPrice - buyPrice) / Math.abs(buyPrice)) * 100).toFixed(
    2
  );
  return isNaN(change) ? 0 : change;
};

export const toFixed = (number, precision = 3) => {
  return +number.toFixed(precision);
};

export const addNumberToAverage = (
  avg,
  originalLength,
  newNumber,
  precision
) => {
  return toFixed(
    (avg * originalLength + newNumber) / (originalLength + 1),
    precision
  );
};

export const returnUpDownClassname = (number) => {
  if (number > 0) {
    return "green";
  } else if (number < 0) {
    return "red";
  } else {
    return "";
  }
};

export function createURLWithQueryParams(baseURL, queryParams) {
  const url = new URL(baseURL);
  const searchParams = new URLSearchParams();

  for (const key in queryParams) {
    if (queryParams.hasOwnProperty(key)) {
      searchParams.append(key, queryParams[key]);
    }
  }

  url.search = searchParams.toString();
  return url.toString();
}

export const formatMomentDuration = (durationInMs) => {
  const duration = moment.duration(durationInMs);
  return `${duration.months() ? duration.months() + " months," : ""}
    ${duration.days()}
     days${duration.hours() ? ", " + duration.hours() + " hours" : ""}`;
};

export const formatMomentDurationFull = (durationInMs) => {
  const duration = moment.duration(durationInMs);
  return `${duration.months() ? duration.months() + " months," : ""}
  ${duration.days() ? "" + duration.days() + " days," : ""} ${
    duration.hours() ? "" + duration.hours() + " hours," : ""
  } ${duration.minutes() ? "" + duration.minutes() + " minutes," : ""} ${
    duration.seconds() ? "" + duration.seconds() + " seconds" : ""
  }`;
};

export const formatNumber = (number) => {
  let options = {};

  if (number < 1000) {
    options = {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };
  } else {
    options = {
      style: "decimal",
      maximumFractionDigits: 0,
    };
  }

  return number.toLocaleString("en-US", options);
};

export const checkDateRangeOverlap = (
  range1Start,
  range1End,
  range2Start,
  range2End
) => {
  const start1 = dayjs(range1Start);
  const end1 = dayjs(range1End);
  const start2 = dayjs(range2Start);
  const end2 = dayjs(range2End);

  return start1.isSameOrBefore(end2) && start2.isSameOrBefore(end1);
};

export const copyToClipboard = async (text) => {
  try {
    await navigator.clipboard.writeText(text);
  } catch (error) {
    console.error("Failed to copy text:", error);
  }
};
