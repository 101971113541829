import useMediaQuery from "hooks/useMediaQuery";
import React from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { toggleDrawer } from "store/slices/main";

const NavbarButton = (props) => {
  const { path, text, onClickFunc } = props;
  const isMobile = useMediaQuery("(max-width: 700px)");
  const dispatch = useDispatch();

  return (
    <NavLink
      to={path}
      className={({ isActive }) =>
        "drawer-button" + (isActive ? " drawer-button-active" : "")
      }
      onClick={() => {
        if (onClickFunc) onClickFunc();
        if (isMobile) dispatch(toggleDrawer(false));
      }}
    >
      {text}
    </NavLink>
  );
};

export default NavbarButton;
