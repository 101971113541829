import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import GenericCard from "components/UI/GenericCard/GenericCard";
import { Button, List, Tooltip } from "antd";
import dayjs from "dayjs";

import relativeTime from "dayjs/plugin/relativeTime";
import { apiRefreshPlatformForAllUsers } from "api/apiUtils";
import { apiGetOnlineUserActivity } from "api/apiAdmin";

// Enable the relativeTime plugin
dayjs.extend(relativeTime);

const OnlineUserActivity = (props) => {
  const { selectedUser } = props;
  const [activity, setActivity] = useState({});

  const onRefreshPlatformForAllUsers = async () => {
    try {
      await apiRefreshPlatformForAllUsers(selectedUser._id);
    } catch (err) {}
  };

  const getOnlineUserActivity = async () => {
    const response = await apiGetOnlineUserActivity(selectedUser._id);
    setActivity(response || {});
  };

  useEffect(() => {
    const interval = setInterval(() => {
      getOnlineUserActivity();
    }, 3000);
    getOnlineUserActivity();

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <GenericCard
      title={`Open tabs (${activity.sessions ? activity.sessions.length : 0})`}
    >
      <Button onClick={onRefreshPlatformForAllUsers}>Refresh user tabs</Button>
      <br />
      <br />

      <List
        bordered
        footer={
          activity.onlineSince && (
            <Tooltip
              title={dayjs(activity.onlineSince).format("HH:mm:ss DD/MM/YY ")}
            >
              Logged in: {dayjs(activity.onlineSince).fromNow()}
            </Tooltip>
          )
        }
        dataSource={activity.sessions}
        renderItem={(item, idx) => (
          <List.Item>
            <List.Item.Meta
              title={`[${idx + 1}] ${item?.location || "Unknown"}`}
              description={
                <div>
                  <p>
                    Last activity:{" "}
                    {dayjs(item?.lastActivity).format("HH:mm:ss")} {" - "} IP
                    Address: {item?.ipAddress} {/* Activity counter: */}
                  </p>
                  <Tooltip
                    title={dayjs(item.timestamp).format("HH:mm:ss DD/MM/YY")}
                  >
                    Started: {dayjs(item.timestamp).fromNow()}
                  </Tooltip>
                </div>
              }
            />
          </List.Item>
        )}
      />
    </GenericCard>
  );
};

OnlineUserActivity.propTypes = { selectedUser: PropTypes.object };

export default OnlineUserActivity;
