import React from "react";
import PropTypes from "prop-types";
import GenericCard from "components/UI/GenericCard/GenericCard";

const BuyTotalStocksCard = (props) => {
  const { buyTotalCount, totalCount, evaluation, form } = props;

  const { percToGain: wantedPercGain, days: wantedDays } =
    form.getFieldsValue();

  return (
    <GenericCard
      title="% Buy/Total"
      tooltipText={`Number of times there actually was a ${wantedPercGain}% gain in ${wantedDays} days`}
    >
      <h2>{evaluation ? evaluation?.toFixed(2) : 0}%</h2>
      {buyTotalCount ? (
        <h2>
          {buyTotalCount} / {totalCount}
        </h2>
      ) : (
        ""
      )}
    </GenericCard>
  );
};

BuyTotalStocksCard.propTypes = {
  buyTotalCount: PropTypes.number,
  totalCount: PropTypes.number,
  evaluation: PropTypes.number,
  form: PropTypes.object,
};

export default BuyTotalStocksCard;
