import React from "react";
import PropTypes from "prop-types";
import { Col, Row, Segmented, Tabs } from "antd";
import EditStrategyTab from "./Modals/EditStrategyTab";
import CreateStrategyTab from "./Modals/CreateStrategyTab";

const StrategyTabsBar = (props) => {
  const {
    strategyTabs,
    getUserStrategyTabs,
    selectedStrategyTab,
    setSelectedStrategyTab,
    showEnabledStrategies,
    setShowEnabledStrategies,
  } = props;
  return (
    <>
      <Tabs
        tabBarExtraContent={
          <Row gutter={7}>
            {selectedStrategyTab !== "all" && (
              <Col>
                <EditStrategyTab
                  key={selectedStrategyTab}
                  getUserStrategyTabs={getUserStrategyTabs}
                  selectedStrategyTab={strategyTabs.find(
                    (tab) => tab.key === selectedStrategyTab
                  )}
                />
              </Col>
            )}
            <Col>
              <CreateStrategyTab getUserStrategyTabs={getUserStrategyTabs} />
            </Col>
          </Row>
        }
        items={strategyTabs}
        activeKey={selectedStrategyTab}
        onChange={setSelectedStrategyTab}
      />
      <div style={{ marginBlockEnd: 10 }}>
        <Segmented
          options={[
            { label: "Enabled Strategies", value: true },
            { label: "Disabled Strategies", value: false },
          ]}
          value={showEnabledStrategies}
          onChange={setShowEnabledStrategies}
        />
      </div>
    </>
  );
};

StrategyTabsBar.propTypes = {
  strategyTabs: PropTypes.array,
  getUserStrategyTabs: PropTypes.func,
  selectedStrategyTab: PropTypes.any,
  setSelectedStrategyTab: PropTypes.func,
  showEnabledStrategies: PropTypes.bool,
  setShowEnabledStrategies: PropTypes.func,
};

export default StrategyTabsBar;
