import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Input, Popover, Radio, Tag } from "antd";
import AntTable from "components/UI/AntTable";
import dayjs from "dayjs";
import { formatMomentDuration, returnUpDownClassname } from "utils/helpers";
import { apiSearchTrades } from "api/apiAdmin";
import { QuestionCircleOutlined } from "@ant-design/icons";
import EditTradeModal from "./EditTradeModal";

const { Search } = Input;

const orderStatusColor = {
  active: "green",
  closed: "red",
  expired: "yellow",
  pending: "lime",
  "pending-close": "orange",
};

const SearchTradesTable = (props) => {
  const { userId } = props;
  const [searchResults, setSearchResults] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [loading, setLoading] = useState(false);

  const [accountType, setAccountType] = useState("real");
  const [dealStatus, setDealStatus] = useState("closed");

  const handleSearchTrades = async (searchInput) => {
    setLoading(true);
    setSearchResults([]);
    try {
      const response = await apiSearchTrades({
        tradeId: searchInput,
        userId,
        dealStatus,
        accountType,
      });
      setSearchResults(response.results);
    } catch (err) {}
    setLoading(false);
  };

  useEffect(() => {
    handleSearchTrades(searchInput);
  }, [accountType, userId, dealStatus]);

  const sortByNumber = useCallback((a, b) => {
    // handle null / N/A values
    if (a === b) return 0;
    if (a === null || a === "N/A") return -1;
    if (b === null || b === "N/A") return 1;
    return a > b ? 1 : -1;
  }, []);

  const sortByText = useCallback((a, b) => {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  }, []);

  const closedTradesColumns = [
    {
      title: "Details",
      dataIndex: "_id",
      render: (text, properties) => (
        <Popover
          content={
            <div>
              <div className="pb-2">
                <p className="font-semibold">Strategy Name:</p>
                <p>
                  {properties.strategy
                    ? properties.strategy.name
                    : "Deleted strategy"}
                </p>
              </div>
              <div>
                <p className="font-semibold">Trade ID:</p>
                <p>{text}</p>
              </div>
            </div>
          }
          trigger="click"
        >
          <Button
            type="text"
            shape="circle"
            icon={<QuestionCircleOutlined />}
          />
        </Popover>
      ),
    },
    {
      title: "Symbol",
      dataIndex: "symbol",
      sorter: {
        compare: (a, b) => sortByText(a.symbol, b.symbol),
      },
    },
    {
      title: "Status",
      dataIndex: "orderStatus",
      render: (text) => (
        <Tag color={orderStatusColor[text] || "default"}>
          {text.toUpperCase()}
        </Tag>
      ),
      sorter: {
        compare: (a, b) => sortByText(a.orderStatus, b.orderStatus),
      },
    },
    {
      title: "Buy Price",
      dataIndex: "openRate",
      render: (text, properties) => (
        <div>
          <div>{text}</div>
          <div>
            <span style={{ fontSize: 12 }}>
              ${properties.openValue.toLocaleString()}
            </span>
          </div>
        </div>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.openRate, b.openRate),
      },
    },
    {
      title: "Sell Price",
      dataIndex: "closeRate",
      render: (text, properties) => (
        <div>
          <div>{text}</div>
          <div>
            <span style={{ fontSize: 12 }}>
              ${properties?.closeValue?.toLocaleString()}
            </span>
          </div>
        </div>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.closeRate, b.closeRate),
      },
    },
    {
      title: "Contracts",
      dataIndex: "amount",
      sorter: {
        compare: (a, b) => sortByNumber(a.amount, b.amount),
      },
    },
    {
      title: "Signal",
      dataIndex: "closeReason",
      sorter: {
        compare: (a, b) => sortByText(a.closeReason, b.closeReason),
      },
    },
    {
      title: "Net Profit",
      dataIndex: "profit.value",
      render: (text, properties) => (
        <span className={returnUpDownClassname(properties.netProfit.value)}>
          ${properties.netProfit.value.toLocaleString()}
        </span>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.netProfit.value, b.netProfit.value),
      },
    },
    {
      title: "Change",
      dataIndex: "profit.perc",
      render: (text, properties) => (
        <span className={returnUpDownClassname(properties.netProfit.perc)}>
          {properties.netProfit.perc}%
        </span>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.netProfit.perc, b.netProfit.perc),
      },
    },
    {
      title: "Run-up",
      dataIndex: "runup.perc",
      render: (text, properties) => (
        <div
          className={returnUpDownClassname(properties.runup.value)}
          title={`Rate: ${properties.runup.rate}`}
        >
          <div>${properties.runup.value.toLocaleString()}</div>
          <span style={{ fontSize: 12 }}>%{properties.runup.perc}</span>
        </div>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.runup.value, b.runup.value),
      },
    },
    {
      title: "Drawdown",
      dataIndex: "drawdown.perc",
      render: (text, properties) => (
        <div
          className={returnUpDownClassname(properties.drawdown.value)}
          title={`Rate: ${properties.drawdown.rate}`}
        >
          <div>${properties.drawdown.value.toLocaleString()}</div>
          <span style={{ fontSize: 12 }}>%{properties.drawdown.perc}</span>
        </div>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.drawdown.value, b.drawdown.value),
      },
    },
    {
      title: "Days",
      dataIndex: "dealDaysOpen",
      sorter: {
        compare: (a, b) => sortByNumber(a.dealDaysOpen, b.dealDaysOpen),
      },
    },
    {
      title: "Duration",
      dataIndex: "openDate",
      render: (text, { closeDate }) => (
        <span>
          {formatMomentDuration(+new Date(closeDate) - +new Date(text))}
        </span>
      ),
      sorter: {
        compare: (a, b) => sortByText(a.openDate, b.openDate),
      },
    },
    {
      title: "Start",
      dataIndex: "openDate",
      render: (text) => <>{dayjs(text).format("HH:mm:ss - DD/MM/YY")}</>,
      sorter: {
        compare: (a, b) => sortByNumber(a.openDate, b.openDate),
      },
    },
    {
      title: "End",
      dataIndex: "closeDate",
      render: (text) => <>{dayjs(text).format("HH:mm:ss - DD/MM/YY")}</>,
      sorter: {
        compare: (a, b) => sortByNumber(a.closeDate, b.closeDate),
      },
    },
    {
      title: "Account Type",
      dataIndex: "accountType",
      render: (text) => (
        <Tag color={text === "demo" ? "red" : "green"}>
          {text.toUpperCase()}
        </Tag>
      ),
      sorter: {
        compare: (a, b) => sortByText(a.accountType, b.accountType),
      },
    },
    {
      title: "Edit",
      dataIndex: "_id",
      render: (text, properties) => (
        <EditTradeModal deal={properties} reloadTrades={handleSearchTrades} />
      ),
    },
  ];

  const openTradesColumns = [
    {
      title: "Details",
      dataIndex: "_id",
      render: (text, properties) => (
        <Popover
          content={
            <div>
              <div className="pb-2">
                <p className="font-semibold">Strategy Name:</p>
                <p>
                  {properties.strategy
                    ? properties.strategy.name
                    : "Deleted strategy"}
                </p>
              </div>
              <div>
                <p className="font-semibold">Trade ID:</p>
                <p>{text}</p>
              </div>
            </div>
          }
          trigger="click"
        >
          <Button
            type="text"
            shape="circle"
            icon={<QuestionCircleOutlined />}
          />
        </Popover>
      ),
    },
    {
      title: "Symbol",
      dataIndex: "symbol",
      sorter: {
        compare: (a, b) => sortByText(a.symbol, b.symbol),
      },
    },
    {
      title: "Status",
      dataIndex: "orderStatus",
      render: (text) => (
        <Tag color={orderStatusColor[text] || "default"}>
          {text.toUpperCase()}
        </Tag>
      ),
      sorter: {
        compare: (a, b) => sortByText(a.orderStatus, b.orderStatus),
      },
    },
    {
      title: "Contracts",
      dataIndex: "amount",
      sorter: {
        compare: (a, b) => sortByNumber(a.amount, b.amount),
      },
    },
    {
      title: "Buy Price",
      dataIndex: "openRate",
      render: (text, properties) => (
        <div>
          <div>{text}</div>
          <div>
            <span style={{ fontSize: 12 }}>
              ${properties.openValue.toLocaleString()}
            </span>
          </div>
        </div>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.openRate, b.openRate),
      },
    },
    {
      title: "Current",
      dataIndex: "currentRate",
      render: (text, properties) => (
        <div>
          <div>{text}</div>
          <div>
            <span style={{ fontSize: 12 }}>
              ${(text * properties.amount).toLocaleString()}
            </span>
          </div>
        </div>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.currentRate, b.currentRate),
      },
    },
    {
      title: "Profit",
      dataIndex: "profit.value",
      render: (text, properties) => (
        <span className={returnUpDownClassname(properties.profit.value)}>
          ${properties.profit.value.toLocaleString()}
        </span>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.profit.value, b.profit.value),
      },
    },
    {
      title: "Change",
      dataIndex: "profit.perc",
      render: (text, properties) => (
        <span className={returnUpDownClassname(properties.profit.perc)}>
          {properties.profit.perc}%
        </span>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.profit.perc, b.profit.perc),
      },
    },
    {
      title: "Run-up",
      dataIndex: "runup.perc",
      render: (text, properties) => (
        <div
          className={returnUpDownClassname(properties.runup.value)}
          title={`Rate: ${properties.runup.rate}`}
        >
          <div>${properties.runup.value.toLocaleString()}</div>
          <span style={{ fontSize: 12 }}>%{properties.runup.perc}</span>
        </div>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.runup.value, b.runup.value),
      },
    },
    {
      title: "Drawdown",
      dataIndex: "drawdown.perc",
      render: (text, properties) => (
        <div
          className={returnUpDownClassname(properties.drawdown.value)}
          title={`Rate: ${properties.drawdown.rate}`}
        >
          <div>${properties.drawdown.value.toLocaleString()}</div>
          <span style={{ fontSize: 12 }}>%{properties.drawdown.perc}</span>
        </div>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.drawdown.value, b.drawdown.value),
      },
    },
    {
      title: "Days",
      dataIndex: "dealDaysOpen",
      sorter: {
        compare: (a, b) => sortByNumber(a.dealDaysOpen, b.dealDaysOpen),
      },
    },
    {
      title: "Duration",
      dataIndex: "openDate",
      render: (text) => (
        <span>{formatMomentDuration(+new Date() - +new Date(text))}</span>
      ),
      sorter: {
        compare: (a, b) => sortByText(a.openDate, b.openDate),
      },
    },
    {
      title: "Open Date",
      dataIndex: "openDate",
      render: (text) => <>{dayjs(text).format("HH:mm:ss - DD/MM/YY")}</>,
      sorter: {
        compare: (a, b) => sortByNumber(a.openDate, b.openDate),
      },
    },
    {
      title: "Edit",
      dataIndex: "_id",
      render: (text, properties) => (
        <EditTradeModal deal={properties} reloadTrades={handleSearchTrades} />
      ),
    },
  ];

  return (
    <div className="flex flex-col">
      <div className="pb-4">
        <div className="pb-2">Account Type:</div>
        <Radio.Group
          defaultValue="real"
          buttonStyle="solid"
          value={accountType}
          onChange={(e) => setAccountType(e.target.value)}
        >
          <Radio.Button value="real">Live</Radio.Button>
          <Radio.Button value="demo">Demo</Radio.Button>
        </Radio.Group>
      </div>

      <div className="pb-4">
        <div className="pb-2">Deal Status:</div>
        <Radio.Group
          defaultValue="closed"
          buttonStyle="solid"
          value={dealStatus}
          onChange={(e) => setDealStatus(e.target.value)}
        >
          <Radio.Button value="closed">Closed</Radio.Button>
          <Radio.Button value="active">Open</Radio.Button>
        </Radio.Group>
      </div>

      <Search
        onChange={(e) => setSearchInput(e.target.value)}
        className="max-w-[300px]"
        placeholder="Enter Trade ID"
        enterButton
        onSearch={() => handleSearchTrades(searchInput)}
      />
      <div className="pt-4">
        <AntTable
          rootClassName="users-table"
          rowKey="_id"
          dataSource={searchResults}
          columns={
            dealStatus === "closed" ? closedTradesColumns : openTradesColumns
          }
          tableLayout="fixed"
          loading={loading}
        />
      </div>
    </div>
  );
};

SearchTradesTable.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default SearchTradesTable;
