import React from "react";
import PropTypes from "prop-types";
import GenericCard from "../UI/GenericCard/GenericCard";
import HoverableTooltip from "components/UI/Tooltip/HoverableTooltip";

const VarianceCard = (props) => {
  const { backtestResults } = props;

  return (
    <GenericCard title="Average Annual PNL">
      <h2>{backtestResults.metrics.yearlyPnl.average.toLocaleString()}%</h2>
      <h5 className="pt-3">
        Average Monthly PNL{" "}
        <HoverableTooltip text="Includes only months with closed trades" />
      </h5>
      <h2>{backtestResults.metrics.monthlyPnl.average.toLocaleString()}%</h2>
      <div className="flex gap-2 pt-2">
        <div className="">
          <h5>Standard Deviation</h5>
          <h3>{backtestResults.metrics.standardDeviation}%</h3>
        </div>
        <div className="">
          <h5>Upside SD</h5>
          <h3>{backtestResults.metrics.upsideDeviation.toLocaleString()}%</h3>
        </div>
        <div>
          <h5>Downside SD</h5>
          <h3>{backtestResults.metrics.downsideDeviation.toLocaleString()}%</h3>
        </div>
      </div>
    </GenericCard>
  );
};
VarianceCard.propTypes = {
  backtestResults: PropTypes.object,
};

export default VarianceCard;
