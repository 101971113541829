import axios from "utils/axios";
import { routes } from "api/routes";

/**
 * Get IB TWS status
 */
export const apiGetTwsStatus = async () => {
  const { data } = await axios.get(routes.getTwsStatus);
  return data;
};

/**
 * Start TWS process
 */
export const apiStartTws = async () => {
  const { data } = await axios.get(routes.startTws);
  return data;
};

/**
 * Stop TWS process
 */
export const apiStopTws = async () => {
  const { data } = await axios.get(routes.stopTws);
  return data;
};

/**
 * Get IB Automation running status
 */
export const apiGetAutomationStatus = async () => {
  const { data } = await axios.get(routes.getAutomationStatus);
  return data;
};

/**
 * Start IB automation process
 */
export const apiStartAutomation = async () => {
  const { data } = await axios.get(routes.startAutomation);
  return data;
};

/**
 * Stop IB automation process
 */
export const apiStopAutomation = async () => {
  const { data } = await axios.get(routes.stopAutomation);
  return data;
};

/**
 * Get automation logs
 */
export const apiGetAutomationLogs = async () => {
  const { data } = await axios.get(routes.getAutomationLogs);
  return data;
};

/**
 * Check if IBC config exists for user
 */
export const apiCheckIfIBCConfigExists = async () => {
  const { data } = await axios.get(routes.checkIfIBCConfigExists);
  return data;
};

/**
 * Update Login Details
 */
export const apiUpdateLoginDetails = async (formData) => {
  const { data } = await axios.post(routes.updateLoginDetails, formData);
  return data;
};
