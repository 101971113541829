import React from "react";
import PropTypes from "prop-types";
import { paths } from "utils/constants";
import { useNavigate } from "react-router-dom";
import { Alert, Button } from "antd";
import CardPlaceholder from "components/UI/GenericCard/CardPlaceholder";

const ModelCreationStatus = (props) => {
  const navigate = useNavigate();

  const onUseCreatedModel = () => {
    navigate(paths.ai);
  };

  const { requestStatus, isPredicting } = props;
  return (
    <CardPlaceholder>
      {requestStatus && requestStatus === "success" && (
        <Alert
          type="success"
          message="Model created successfully!"
          description="You can view you new model in Ai Magician page."
          showIcon
          action={
            <Button size="small" type="text" onClick={onUseCreatedModel}>
              Try it now!
            </Button>
          }
          closable
        />
      )}

      {isPredicting && (
        <Alert
          type="info"
          message="Model is predicitng..."
          description={
            <div>
              <p>Wait a few moments until the model finishes predicting.</p>
              <p>You can now leave this page.</p>
            </div>
          }
          showIcon
          closable
        />
      )}

      {requestStatus === "error" && (
        <Alert
          type="error"
          message="Failed to create model!"
          showIcon
          description="Try selecting different dates."
        />
      )}
    </CardPlaceholder>
  );
};

ModelCreationStatus.propTypes = {
  requestStatus: PropTypes.string.isRequired,
  isPredicting: PropTypes.bool.isRequired,
};

export default ModelCreationStatus;
