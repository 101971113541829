import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Form, Input, InputNumber, Modal } from "antd";
import { apiTriggerCloseTrade } from "api/apiTrades";
import { calcProfitPerc, returnUpDownClassname } from "utils/helpers";

const TriggerSellPosition = (props) => {
  const { activePosition, getUserStrategies } = props;
  const [form] = Form.useForm();
  const sellPrice = Form.useWatch("closeRate", form);

  const [isOpen, setIsOpen] = useState(false);

  const percChange = calcProfitPerc(activePosition.openRate, sellPrice) || 0;

  const handleTriggerSellPosition = async (values) => {
    try {
      const data = {
        dealId: activePosition._id,
        rate: values.rate,
        closeReason: values.closeReason,
      };
      console.log(data);

      await apiTriggerCloseTrade(data);
      await getUserStrategies();
      setIsOpen(false);
    } catch (err) {}
  };

  const updatePositionValue = (values, allValues) => {
    const value = allValues.closeRate * allValues.amount;
    form.setFieldValue("closeValue", +value?.toFixed(2));
  };

  return (
    <>
      <Button type="primary" onClick={() => setIsOpen(true)}>
        Trigger
      </Button>
      <Modal
        title="Trigger Close Trade"
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={null}
        width={800}
        destroyOnClose={true}
      >
        <div className="text-lg pb-4">
          Change the trade status to pending sell
        </div>
        <Form
          form={form}
          layout="vertical"
          name="confirm_trigger_close_position"
          onFinish={handleTriggerSellPosition}
          onValuesChange={updatePositionValue}
          initialValues={{
            rate: activePosition.liveRate || activePosition.currentRate,
            closeReason: activePosition.closeReason,
          }}
          preserve={false}
        >
          <Form.Item
            name="rate"
            label="Rate"
            rules={[
              {
                required: true,
                message: "Rate is required!",
              },
            ]}
            required={false}
          >
            <InputNumber style={{ width: "100%" }} step={0.01} />
          </Form.Item>
          <Form.Item
            name="closeReason"
            label="Close Reason"
            rules={[
              {
                required: true,
                message: "Close reason is required!",
              },
            ]}
            required={false}
          >
            <Input disabled={activePosition.closeReason} />
          </Form.Item>
          <div>Buy Price: ${activePosition.openRate}</div>
          <div>
            Profit:{" "}
            <span className={returnUpDownClassname(percChange)}>
              {percChange}%
            </span>
          </div>
          <Form.Item shouldUpdate style={{ paddingBlockStart: 10 }}>
            {() => (
              <Button type="primary" htmlType="submit">
                Trigger
              </Button>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

TriggerSellPosition.propTypes = {
  activePosition: PropTypes.object,
  getUserStrategies: PropTypes.func,
};

export default TriggerSellPosition;
