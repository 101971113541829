import React from "react";
import { Button, InputNumber, Space } from "antd";

const getRangeFilterProps = (dataIndex) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    close,
  }) => (
    <div
      style={{
        padding: 8,
      }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <InputNumber
          placeholder="From"
          value={selectedKeys[0]?.from}
          onChange={(e) => setSelectedKeys([{ ...selectedKeys[0], from: e }])}
          onPressEnter={() => {
            confirm({
              closeDropdown: true,
            });
          }}
          style={{
            marginBottom: 8,
            display: "block",
            width: "130px",
          }}
        />
        <InputNumber
          placeholder={`To`}
          value={selectedKeys[0]?.to}
          onChange={(e) => setSelectedKeys([{ ...selectedKeys[0], to: e }])}
          onPressEnter={() => {
            confirm({
              closeDropdown: true,
            });
          }}
          style={{ marginBottom: 8, display: "block", width: "130px" }}
        />
      </div>
      <Space>
        <Button
          type="primary"
          onClick={() => {
            confirm({
              closeDropdown: true,
            });
          }}
          size="small"
          style={{
            width: 90,
          }}
        >
          Search
        </Button>
        <Button
          onClick={clearFilters}
          size="small"
          style={{
            width: 90,
          }}
        >
          Reset
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            confirm({
              closeDropdown: false,
            });
          }}
        >
          Filter
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            close();
          }}
        >
          close
        </Button>
      </Space>
    </div>
  ),
  onFilter: (value, record) => {
    const { from, to } = value;
    const recordValue = parseFloat(record[dataIndex]);

    if (!isNaN(from) && !isNaN(to)) {
      return recordValue >= from && recordValue <= to;
    } else if (!isNaN(from)) {
      return recordValue >= from;
    } else if (!isNaN(to)) {
      return recordValue <= to;
    }

    return true;
  },
});

export default getRangeFilterProps;
